import {useQuery} from 'react-query'
import {queryKeys} from '~/utils/queryKeys'
import api from '~/config/apiSauce'
import {BreakageExpire, BreakagePerMonth, BreakageSummary} from '~/types'

interface BreakageProps {
  expire: BreakageExpire
  isLoadingExpire: boolean
  perMonth: BreakagePerMonth
  isLoadingPerMonth: boolean
  summary: BreakageSummary
  isLoadingSummary: boolean
}

export const useBreakage = (): BreakageProps => {
  const {breakageExpire, breakagePerMonth, breakageSum} = queryKeys

  const fetchData = async (url: string) => {
    const response = await api.get(url)
    const {data} = response
    return data as BreakageExpire & BreakagePerMonth & BreakageSummary
  }

  const {data: expire, isLoading: isLoadingExpire} = useQuery(
    breakageExpire,
    () => fetchData(`/chart/to-expire`)
  )

  const {data: perMonth, isLoading: isLoadingPerMonth} = useQuery(
    breakagePerMonth,
    () => fetchData(`/chart/per-month`)
  )

  const {data: summary, isLoading: isLoadingSummary} = useQuery(
    breakageSum,
    () => fetchData(`/chart/summary`)
  )

  return {
    expire,
    isLoadingExpire,
    perMonth,
    isLoadingPerMonth,
    summary,
    isLoadingSummary,
  }
}
