import {ReactElement} from 'react'
import {Route, Switch} from 'react-router-dom'
import Auth from '~/pages/AuthLogin'

export default function AuthRoutes(): ReactElement {
  return (
    <Switch>
      <Route path="/" exact component={Auth} />
    </Switch>
  )
}
