import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`

export const WrapContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 25px;
  background-color: ${({theme}) => theme.colors.background.main};
  width: 100%;
  height: 100%;
`

export const ContentButtonCredits = styled.div`
  display: flex;
  padding: 0 15px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  height: 100%;
  width: 100%;
  margin-top: 50px;
  background-color: ${({theme}) => theme.colors.border.input.light};
`
