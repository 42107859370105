import {ReactElement, useState} from 'react'
import {Container, Text, DivTag} from './styles'
import Badge from '~/components/Badge'
import {Redirect} from 'react-router-dom'

interface ButtonRulesBonusProps {
  id: string
  name: string
  version: string
  type: string
  seller: any
  dateStart: string
  dateEnd: string
  validity: string
  status: string
}

export default function ButtonBonusRules({
  id,
  name,
  version,
  type,
  seller,
  dateStart,
  dateEnd,
  validity,
  status,
}: ButtonRulesBonusProps): ReactElement {
  const [redirect, setRedirect] = useState(false)

  const handleRedirect = () => setRedirect(true)

  return (
    <Container onClick={handleRedirect}>
      <Text>{name}</Text>
      <Text>{version}</Text>
      <Text>{type}</Text>
      <Text>{seller}</Text>
      <Text>{dateStart}</Text>
      <Text>{dateEnd}</Text>
      <DivTag>
        <Badge type={validity === 'Vigente' ? 'success' : 'disabled'}>
          {validity}
        </Badge>
      </DivTag>
      <DivTag>
        <Badge type={status === 'Ativa' ? 'success' : 'disabled'}>
          {status}
        </Badge>
      </DivTag>
      {redirect && (
        <Redirect
          to={{
            pathname: `/regras-bonificacao/${id}`,
          }}
        />
      )}
    </Container>
  )
}
