import {ReactElement} from 'react'
import {Container, Spinner} from './styles'

export function Loading(): ReactElement {
  return (
    <Container>
      <Spinner />
    </Container>
  )
}
