import {ReactElement, ReactNode} from 'react'
import {Container, Content, Title} from './styles'

export function PageHeader({
  title,
  children,
}: {
  title: string
  children?: ReactNode
}): ReactElement {
  return (
    <Container>
      <Title>{title}</Title>
      <Content>{children}</Content>
    </Container>
  )
}
