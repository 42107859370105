import {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useState,
} from 'react'
import api from '~/config/apiSauce'
import {keyStorageTokenLogin} from '~/utils/keystorage'

interface AuthProps {
  token: string
  setTokenCurrent: (token: string) => void
}

export const AuthContext = createContext<AuthProps>({} as AuthProps)

type AuthProviderProps = {children: ReactNode}

const AuthProvider = ({children}: AuthProviderProps): ReactElement => {
  const [token, setToken] = useState('')

  const setTokenCurrent = (token: string) => {
    sessionStorage.setItem(keyStorageTokenLogin, JSON.stringify(token))
    api.setHeaders({'x-token-api': token})
    setToken(token)
  }

  return (
    <AuthContext.Provider
      value={{
        token,
        setTokenCurrent,
      }}>
      {children}
    </AuthContext.Provider>
  )
}

const useAuthProvider = (): AuthProps => {
  const authContext = useContext(AuthContext)
  return authContext
}

export {useAuthProvider, AuthProvider}
