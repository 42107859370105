import React, {FC, ReactElement, useState} from 'react'
import {
  ButtonContainer,
  FilterContainer,
  FilterItem,
  FilterTitle,
} from '~/pages/CustomerAnalysis/ListCustomer/style'
import {CheckBox} from '~/components/CheckBox'
import {ContainerHeader} from './style'
import Button from '~/components/Button'

type ListCustomerFiltersProps = {
  onSubmit: (filters: string[]) => void
}

export const ListCustomerFilters: FC<ListCustomerFiltersProps> = ({
  onSubmit,
}): ReactElement => {
  const [clusters, setClusters] = useState({
    colab: false,
    brass: false,
    bronze: false,
    silver: false,
    gold: false,
  })
  const submitFilters = () => {
    const clusterFilter = Object.entries(clusters).reduce(
      (prev, [key, value]): string[] => {
        if (value) {
          return [...prev, key]
        }
        return prev
      },
      []
    )
    onSubmit(clusterFilter)
  }
  return (
    <ContainerHeader>
      <p>
        Encontre lista de clientes de acordo com o <b>filtros</b> listados
        abaixo:
      </p>
      <FilterTitle>Nível</FilterTitle>
      <FilterContainer>
        <FilterItem>
          <CheckBox
            id="colab-filter"
            onChange={(value) => setClusters({...clusters, colab: value})}
          />
          <label htmlFor="colab-filter">Colaborador</label>
        </FilterItem>
        <FilterItem>
          <CheckBox
            id="brass-filter"
            onChange={(value) => setClusters({...clusters, brass: value})}
          />
          <label htmlFor="brass-filter">Latão</label>
        </FilterItem>
        <FilterItem>
          <CheckBox
            id="silver-filter"
            onChange={(value) => setClusters({...clusters, silver: value})}
          />
          <label htmlFor="silver-filter">Prata</label>
        </FilterItem>
        <FilterItem>
          <CheckBox
            id="gold-filter"
            onChange={(value) => setClusters({...clusters, gold: value})}
          />
          <label htmlFor="gold-filter">Ouro</label>
        </FilterItem>
      </FilterContainer>
      <ButtonContainer>
        <Button text="Localizar clientes" onClick={submitFilters} />
      </ButtonContainer>
    </ContainerHeader>
  )
}
