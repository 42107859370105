import React, {ReactElement, ReactNode} from 'react'
import {Back, Container, SectionLeft, WrapBack} from './styles'
import {Link} from 'react-router-dom'
import {Location, LocationDescriptor, LocationState} from 'history'

interface WhiteHeaderParams {
  backTo?:
    | LocationDescriptor<LocationState>
    | ((location: Location<LocationState>) => LocationDescriptor<LocationState>)
  children?: ReactNode
}

export default function WhiteHeader({
  backTo,
  children,
}: WhiteHeaderParams): ReactElement {
  return (
    <Container>
      <SectionLeft>
        {backTo && (
          <WrapBack>
            <Link to={backTo}>
              <Back size={32} />
            </Link>
          </WrapBack>
        )}
      </SectionLeft>
      {children}
    </Container>
  )
}
