import styled from 'styled-components'

export const ContainerMain = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Tabs = styled.div`
  display: flex;
`
export const Container = styled.div`
  box-shadow: 0px 5px 20px -5px rgb(0 0 0 / 60%);
  border-radius: 5px;
  max-width: 1240px;
  width: 100%;
`
export const Title = styled.div`
  margin-top: 2rem;
  margin-bottom: 3rem;
  h1 {
    font-size: 18px;
    color: #32363b;
    font-weight: 400;
  }
`
export const FinancialCloseBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(247, 247, 247, 1);
  padding: 16px 32px;
  position: relative;
  justify-content: center;
`

export const FinancialCloseText = styled.div`
  display: block;
  text-align: center;
  font-size: 14px;
  white-space: nowrap;
  margin-top: 5px;
  p {
    font-size: 12px;
  }
  &.relatory {
    margin-top: 20px;
  }
`

export const ButtonRelatory = styled.button`
  background-color: ${({theme}) => theme.colors.font.purpleMain};
  &:hover {
    background-color: ${({theme}) => theme.colors.font.purpleDark};
  }
  display: block;
  text-align: center;
  color: white;
  font-size: 14px;
  white-space: nowrap;
  font-weight: 700;
  border-radius: 4px;
  width: 163px;
  min-height: 36px;
  margin: 0 auto;
`

export const FinancialCloseTextDark = styled.div`
  display: block;
  color: ${({theme}) => theme.colors.font.purpleMain};
  text-align: center;
  margin-top: 10px;
  font-weight: 500;
  font-size: 22px;
  p {
    font-size: 14px;
    font-weight: 300;
    color: ${({theme}) => theme.colors.font.purpleMain};
    margin-top: 0px;
  }
`

export const TableHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid ${({theme}) => theme.colors.border.main};
  display: flex;
  justify-content: space-between;
  padding: 1px 32px;
  h2 {
    font-size: 14px;
    font-weight: 300;
  }
`

export const TableBody = styled.div`
  border-bottom: 1px solid ${({theme}) => theme.colors.border.main};
  display: flex;
  justify-content: space-between;
`

export const TableItem = styled.div`
  display: flex;
`
