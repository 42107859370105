import {ReactNode, ReactElement} from 'react'
import {Container} from './styles'
import {CSSProperties} from 'styled-components'

interface RowParams {
  children?: ReactNode
  style?: CSSProperties
}

const Row = ({children, style}: RowParams): ReactElement => {
  return <Container {...{style}}>{children}</Container>
}

export default Row
