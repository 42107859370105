import styled from 'styled-components'
import {BsEyeFill, BsEyeSlashFill} from 'react-icons/bs'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 9px;
  width: 100%;
`

export const Label = styled.label`
  font-size: 14px;
  color: ${({theme}) => theme.colors.font.dark};
  font-weight: 700;
  line-height: 16px;
`

export const Input = styled.input`
  outline: 1px solid ${({theme}) => theme.colors.font.dark};
  padding: 6px 8px;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  width: 100%;
  border-radius: 2px;
  &:focus {
    outline: 1px solid ${({theme}) => theme.colors.secondary.main};
  }
`

export const WrapButtonIcon = styled.div`
  position: relative;
  width: 100%;
`

export const Button = styled.button`
  display: flex;
  border: none;
  position: absolute;
  background-color: transparent;
  margin-top: -25px;
  padding: 0px 10px;
  right: 0px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 0.7;
  }
`

export const EyeClose = styled(BsEyeSlashFill)`
  fill: ${({theme}) => theme.colors.secondary.main};
`

export const EyeOpen = styled(BsEyeFill)`
  fill: ${({theme}) => theme.colors.secondary.main};
`
