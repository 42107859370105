import {ChangeEvent, FormEvent, ReactElement, useState} from 'react'
import toast, {Toaster} from 'react-hot-toast'
import api from '~/config/apiSauce'
import {
  Container,
  Content,
  Underline,
  WrapImg,
  Title,
  Form,
  ButtonSubmit,
} from './styles'
import InputLogin from '~/components/InputLogin'
import {useAuthProvider} from '~/hooks/useAuthProvider'
import {Loading} from '~/components/Load'

async function loginUser(credentials) {
  return await api.post('/auth/login', JSON.stringify(credentials))
}

export default function Auth(): ReactElement {
  const {setTokenCurrent} = useAuthProvider()
  const [isLoading, setIsLoading] = useState(false)
  const [userName, setUserName] = useState('')
  const [userPassword, setUserPassword] = useState('')
  const HARD_PASSWORD = process.env.REACT_APP_HARD_PASSWORD
  const HARD_USER = process.env.REACT_APP_HARD_USER
  const HARD_TOKEN = process.env.REACT_APP_HARD_TOKEN

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault()
      setIsLoading(true)
      if (userName === HARD_USER && userPassword === HARD_PASSWORD) {
        setTokenCurrent(HARD_TOKEN)
      } else {
        toast.error('Usuário e/ou senha inválidos!')
      }
      // const token = await loginUser({
      //   user_id: userName,
      //   user_password: userPassword,
      // })
      // const getToken = token.data['body']['access_token']
      // if (getToken) {
      //   setTokenCurrent(getToken)
      // } else {
      //   toast.error('Something went wrong')
      // }
    } catch (err) {
      console.log(err)
      toast.error('Something went wrong')
    } finally {
      setIsLoading(false)
    }
  }

  const clickHandlerName = (e: ChangeEvent<HTMLInputElement>) =>
    setUserName(e.target.value)

  const clickHandlerPassword = (e: ChangeEvent<HTMLInputElement>) =>
    setUserPassword(e.target.value)

  return (
    <Container>
      <Content>
        <WrapImg>
          <img
            width={120}
            height={40}
            src="https://ok11static.oktacdn.com/fs/bco/1/fs02ojnxjd5OBRsaW4x6"
            alt="Logo Whirlpool"
          />
          <Underline />
        </WrapImg>
        <Form onSubmit={handleSubmit}>
          <Title>Sign In</Title>
          <InputLogin
            type="text"
            onChange={clickHandlerName}
            textLabeL="Username"
          />
          <InputLogin
            onChange={clickHandlerPassword}
            textLabeL="Password"
            isPassword={true}
          />
          {isLoading ? (
            <Loading />
          ) : (
            <ButtonSubmit type="submit">Submit</ButtonSubmit>
          )}
        </Form>
        <Toaster />
      </Content>
    </Container>
  )
}
