import React, {FC, ReactElement, useState} from 'react'
import PageContainer from '~/components/PageContainer'
import WhiteHeader from '~/components/WhiteHeader'
import {
  CashbackContent,
  ClusterBadge,
  ClusterContent,
  CustomerInfoContainer,
  InfoContent,
  ReferralContent,
  TableContainer,
} from '~/pages/CustomerProfile/style'
import {useTheme} from 'styled-components'
import {Link, useParams} from 'react-router-dom'
import {ContentTab} from '~/components/ContentTab'
import {CashbackTab} from '~/pages/CustomerProfile/CashbackTab'
import {ScoreTab} from 'pages/CustomerProfile/ScoreTab'
import {ReferralTab} from '~/pages/CustomerProfile/ReferralTab'
import {useQuery} from 'react-query'
import {getCustomer} from '~/services/apiManagerService'
import {Skeleton} from '~/components/Skeleton'
import {capitalize, moneyFormat, numberFormat} from '~/utils'
import {FaAward} from 'react-icons/fa'
import {CustomerNotFound} from '~/pages/CustomerProfile/CustomerNotFound'

export const CustomerProfile: FC = (): ReactElement => {
  const theme = useTheme()
  const [currentTab, setCurrentTab] = useState('cashback')
  const {email} = useParams<{email: string}>()
  const {data, isLoading, error} = useQuery(
    ['customerProfile', email],
    async () => getCustomer(email)
  )
  const getClusterInfo = (clusterName?: string) => {
    switch (clusterName) {
      case 'brass':
      default:
        return {
          name: 'Latão',
          color: theme.colors.clusters.brass,
        }
      case 'bronze':
        return {
          name: 'Bronze',
          color: theme.colors.clusters.bronze,
        }
      case 'silver':
        return {
          name: 'Prata',
          color: theme.colors.clusters.silver,
        }
      case 'gold':
        return {
          name: 'Ouro',
          color: theme.colors.clusters.gold,
        }
      case 'colab':
        return {
          name: 'Colaborador',
          color: theme.colors.clusters.colab,
        }
    }
  }
  const cluster = getClusterInfo(data?.cluster)
  return (
    <PageContainer>
      <WhiteHeader backTo={{pathname: '/cliente', state: {tab: 'list'}}} />
      {error !== null ? (
        <CustomerNotFound email={email} />
      ) : (
        <>
          <CustomerInfoContainer>
            <InfoContent>
              <p
                style={{
                  marginBottom: 20,
                }}>
                <Skeleton
                  width="30ch"
                  isLoading={isLoading}
                  content={data?.email}
                  fontSize={21}
                  color={theme.colors.font.purpleMain}
                />
              </p>
              <p
                style={{
                  marginBottom: 10,
                }}>
                <Skeleton
                  width="22ch"
                  isLoading={isLoading}
                  content={`Usuário desde ${capitalize(
                    data?.createdAt
                      .setLocale('pt-br')
                      .toFormat('MMM/y')
                      .replaceAll('.', '')
                  )}`}
                />
              </p>
              <p>
                <Skeleton
                  width="20ch"
                  isLoading={isLoading}
                  content={
                    data?.referredBy !== undefined ? (
                      <>
                        Indicado por&nbsp;
                        <Link to={`/cliente/${data?.referredBy}`}>
                          {data?.referredBy}
                        </Link>
                      </>
                    ) : (
                      'Usuário sem indicação'
                    )
                  }
                />
              </p>
            </InfoContent>
            <ClusterContent>
              <ClusterBadge color={cluster.color}>
                <Skeleton
                  width={60}
                  height={60}
                  color={theme.colors.clusters.brass}
                  isLoading={isLoading}
                  content={<FaAward />}
                />
              </ClusterBadge>
              <div>
                {cluster.name !== 'Colab' ? (
                  <>
                    <p
                      style={{
                        color: '#f78800',
                        fontWeight: 'bold',
                        fontSize: 24,
                        marginBottom: 10,
                      }}>
                      <Skeleton
                        width="11ch"
                        color={cluster.color}
                        isLoading={isLoading}
                        content={`Nível ${capitalize(
                          (cluster.name ?? '').toLowerCase()
                        )}`}
                      />
                    </p>
                    <p
                      style={{
                        color: theme.colors.font.purpleMain,
                        marginBottom: 10,
                      }}>
                      <Skeleton
                        width="20ch"
                        color={theme.colors.font.purpleMain}
                        isLoading={isLoading}
                        content={`Saldo de pontos: ${numberFormat(
                          data?.score,
                          0
                        )}`}
                      />
                    </p>
                    <p>
                      <Skeleton
                        width="34ch"
                        isLoading={isLoading}
                        content={`Pontos para o próximo nível: ${numberFormat(
                          data?.scoreToNextCluster,
                          0
                        )}`}
                      />
                    </p>
                  </>
                ) : (
                  <>
                    <p>Nível</p>
                    <p
                      style={{
                        color: '#f78800',
                        fontWeight: 'bold',
                        fontSize: 24,
                        marginBottom: 10,
                      }}>
                      Colaborador
                    </p>
                  </>
                )}
              </div>
            </ClusterContent>
            <CashbackContent>
              <p style={{textTransform: 'uppercase'}}>créditos</p>
              <p style={{color: theme.colors.font.purpleMain}}>
                <Skeleton
                  width="20ch"
                  color={theme.colors.font.purpleMain}
                  isLoading={isLoading}
                  content={`Saldo Atual ${moneyFormat(data?.cashback)}`}
                />
              </p>
            </CashbackContent>
            <ReferralContent>
              <p style={{textTransform: 'uppercase'}}>indicações</p>
              <p style={{color: theme.colors.font.purpleMain}}>
                <Skeleton
                  width="21ch"
                  isLoading={isLoading}
                  content={
                    data?.referrals === 1
                      ? `1 cliente indicado`
                      : `${numberFormat(data?.referrals)} clientes indicados`
                  }
                  color={theme.colors.font.purpleMain}
                />
              </p>
            </ReferralContent>
          </CustomerInfoContainer>
          <ContentTab
            onChange={(currentTab) => setCurrentTab(currentTab)}
            currentValue={currentTab}
            tabs={[
              {title: 'Créditos', value: 'cashback'},
              {title: 'Nível', value: 'score'},
              {title: 'Indicações', value: 'referral'},
            ]}
          />
          <TableContainer>
            {currentTab === 'cashback' && <CashbackTab email={email} />}
            {currentTab === 'score' && <ScoreTab email={email} />}
            {currentTab === 'referral' && <ReferralTab email={email} />}
          </TableContainer>
        </>
      )}
    </PageContainer>
  )
}
