import {FC, ReactElement} from 'react'
import {Bar, Container} from '~/components/ProgressBar/style'

type ProgressBarProps = {
  percentage: number
}

export const ProgressBar: FC<ProgressBarProps> = ({
  percentage,
}): ReactElement => {
  return (
    <Container>
      <Bar width={percentage} />
    </Container>
  )
}
