import React, {
  MutableRefObject,
  ReactElement,
  ReactNode,
  useLayoutEffect,
  useState,
} from 'react'
import {Body, Footer, Header, PopoverContainer, PopoverWrapper} from './styles'

interface PopoverProps {
  anchor?: MutableRefObject<HTMLElement>
  position: 'bottom right' | 'bottom left' | 'top right' | 'top left'
  header?: string | ReactNode
  footer?: string | ReactNode
  isOpen: boolean
  children?: ReactNode
}

const Popover = ({
  position,
  header,
  footer,
  children,
  isOpen,
  anchor,
}: PopoverProps): ReactElement => {
  const [anchorPos, setAnchorPos] = useState<{x: number; y: number}>()
  const updatePos = () => {
    const rect = anchor.current.getBoundingClientRect()
    const pos = position.split(' ')
    const anchorPos = {x: rect[pos[1]], y: rect[pos[0]]}
    setAnchorPos(anchorPos)
  }
  useLayoutEffect(() => {
    if (anchor?.current !== undefined) {
      updatePos()
      window.addEventListener('resize', updatePos)
    }
    return () => {
      window.removeEventListener('resize', updatePos)
    }
  }, [position])
  return (
    <>
      {isOpen && (
        <PopoverContainer className={position} anchor={anchorPos}>
          <PopoverWrapper>
            {header && <Header>{header}</Header>}
            <Body>{children}</Body>
            {footer && <Footer>{footer}</Footer>}
          </PopoverWrapper>
        </PopoverContainer>
      )}
    </>
  )
}

export default Popover
