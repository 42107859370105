import styled from 'styled-components'

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 250px;
  padding: 10px;
  border-top: 1px solid ${({theme}) => theme.colors.border.main};
  box-sizing: content-box;
`
