import React, {Fragment, ReactElement} from 'react'
import Chart from 'react-apexcharts'
import {Container, Block, Legend, BigNumber, Text} from './styles'
import {useIndicationMonth} from './useIndicationMonth'
import {Loading} from '~/components/Load'
import {useGoalIndication} from '~/components/ModalGoalIndication/useGoalIndication'

function ChartLineMonthly(): ReactElement {
  const {isLoading, series, options, isFetching} = useIndicationMonth()
  const {isFetch, isLoading: isLoadingGoal} = useGoalIndication()
  const loading = isFetching || isLoadingGoal || isFetch

  if (isLoading) return <Loading />

  return (
    <Container>
      <Block>
        <Chart
          options={options}
          series={series}
          type="line"
          width={1000}
          height={250}
        />
        <Legend>
          {series?.map((value) => (
            <Fragment key={`${value.name}ChartLineMonthly`}>
              <BigNumber color={value.color}>
                {loading ? <Loading /> : value.total}
              </BigNumber>
              <Text>{value.name}</Text>
            </Fragment>
          ))}
        </Legend>
      </Block>
    </Container>
  )
}
export default ChartLineMonthly
