import styled from 'styled-components'

interface ButtonProps {
  width?: string
  heigth: string
  align?: string
  gap?: string
  backgroundColor?: string
  textColor?: string
  borderColor?: string
}

export const ButtonContainer = styled.button<ButtonProps>`
  width: ${({width}) => width || 'auto'};
  height: ${({heigth}) => heigth};
  border: ${({borderColor}) =>
    borderColor !== undefined ? `1px solid ${borderColor}` : 'none'};
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: ${({align}) => align || 'center'};
  gap: ${({gap}) => gap};
  background: ${({backgroundColor, disabled, theme}) =>
    disabled
      ? theme.colors.background.darkGray
      : backgroundColor !== undefined
      ? backgroundColor
      : theme.colors.font.purpleDark};
  border-radius: 8px;
  &:disabled {
    cursor: auto;
  }
  span {
    color: ${({textColor}) => textColor};
    font-weight: 600;
    font-size: 0.875rem;
  }
`
