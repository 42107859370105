import styled from 'styled-components'
import {validateColors} from './validateColors'

interface TextProps {
  typeText: number
}

export const Container = styled.div`
  width: 400px;
  display: flex;
  padding: 5px 0px;
  justify-content: center;
  align-self: center;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Title = styled.h3`
  font-family: '72 Light', '72 Regular', '72', sans-serif;
  color: ${({theme}) => theme.colors.font.main};
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
`

export const Legend = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  text-transform: uppercase;
  & .paragraph {
    font-family: '72', sans-serif;
    margin-top: 5px;
    font-weight: 300;
    font-size: 11px;
    color: ${({theme}) => theme.colors.font.main};
  }
`

export const BigNumber = styled.p<TextProps>`
  font-family: '72', sans-serif;
  font-weight: 400;
  font-size: 24px;
  color: ${({typeText}) => validateColors(typeText)};
`

export const Text = styled.p<TextProps>`
  font-family: '72', sans-serif;
  font-size: 11px;
  font-weight: 300;
  color: ${({typeText}) => validateColors(typeText)};
`
