import styled from 'styled-components'

interface ContainerProps {
  titleColor: string
}

export const Container = styled.div<ContainerProps>`
  width: 100%;

  .title-container {
    margin: 0 0 4px 24px;

    span {
      font-size: 0.75rem;
      font-weight: 600;
      color: ${({titleColor}) => titleColor};
    }
  }
`
