import {ReactElement} from 'react'
import {Content, BigNumber, WrapBigNumber, WrapContent} from './styles'

interface ApexChartGaugeProps {
  monthCurrent: number
  monthLast: number
  lastThreeMonths: number
  lastTwelveMonths: number
}

export function ApexChartGauge({
  monthCurrent,
  monthLast,
  lastThreeMonths,
  lastTwelveMonths,
}: ApexChartGaugeProps): ReactElement {
  return (
    <Content>
      <WrapContent>
        <WrapBigNumber>
          <BigNumber>
            <span>{monthCurrent}%</span>
          </BigNumber>
          <p>Mês atual</p>
          <p>(D-1)</p>
        </WrapBigNumber>
        <WrapBigNumber>
          <BigNumber>
            <span>{lastThreeMonths}%</span>
          </BigNumber>
          <p>Últimos 3 meses </p>
          <p>(M-1)</p>
        </WrapBigNumber>
      </WrapContent>
      <WrapContent>
        <WrapBigNumber>
          <BigNumber>
            <span>{monthLast}%</span>
          </BigNumber>
          <p
            style={{
              marginBottom: 13,
            }}>
            Mês anterior
          </p>
        </WrapBigNumber>
        <WrapBigNumber>
          <BigNumber>
            <span>{lastTwelveMonths}%</span>
          </BigNumber>
          <p>Últimos 12 meses </p>
          <p>(M-1)</p>
        </WrapBigNumber>
      </WrapContent>
    </Content>
  )
}
