import {DataProps, OptionsProps, Series} from '~/types'

type PropsOptions = {
  options: Props
}

type Props = Pick<OptionsProps, 'chart' | 'xaxis' | 'stroke'> & {
  legend: {
    show: boolean
  }
}

type PropsSeries = {
  series: Series[]
}

type FetchDataProps = PropsOptions & PropsSeries

export function handleFetchData(
  indication = {} as DataProps,
  type: string
): FetchDataProps {
  if (typeof indication.data !== 'undefined') {
    const dataCategories =
      type === 'yearly'
        ? indication.axisX.map((date) => {
            const dateCurrent = new Date(
              +date.split('-')[0],
              +date.split('-')[1] - 1,
              +date.split('-')[2]
            )
            const year = new Intl.DateTimeFormat('pt-Br', {
              year: '2-digit',
            }).format(dateCurrent)
            const month = new Intl.DateTimeFormat('pt-Br', {
              month: 'short',
            }).format(dateCurrent)
            const monthUpperCase =
              month[0].toUpperCase() + month.substring(1).replace('.', '')
            return [monthUpperCase, year]
          })
        : indication.axisX.map(
            (date) => `${date.split('-')[2]}/${date.split('-')[1]}`
          )
    const options = {
      chart: {
        id: 'apinchara-example',
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: dataCategories.map((date) => date) as string[] | string[][],
        labels: {
          show: true,
          rotate: type === 'yearly' ? 0 : -45,
          rotateAlways: type !== 'yearly',
        },
      },
      stroke: {
        width: 1.5,
      },
    }
    const series = indication.data.map((value) => ({
      name: value.name,
      data: value.axisY.map((item) => item),
      color: handleColors(value.name),
      total: value.total,
    }))
    return {options, series}
  }
}

function handleColors(value: string): string {
  const colors = {
    Meta: '#D99D01',
    'Indicações no período': '#8600A7',
    'Indicações no mesmo período do ano anterior': '#858996',
  } as {[key: string]: string}
  function getColors(index: string) {
    return colors[index] ? colors[index] : '#6799C6'
  }
  return getColors(value)
}
