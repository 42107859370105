import {ReactElement} from 'react'
import {Header, TopHeader, Title, WrapTopHeader} from './styles'
import HeaderInputFilter from '~/components/HeaderInputFilter'

type HeaderMovementProps = {
  totalMovements: number
}

export default function HeaderMovement({
  totalMovements,
}: HeaderMovementProps): ReactElement {
  return (
    <Header>
      <TopHeader>
        <WrapTopHeader>
          <Title>Movimentações ({totalMovements})</Title>
          <HeaderInputFilter />
        </WrapTopHeader>
      </TopHeader>
    </Header>
  )
}
