import {ReactElement, memo} from 'react'
import {getHours, formatTitle, getIcon, formatMoney} from './functions'
import {
  ContainerButton,
  Container,
  Hour,
  Icon,
  WrapMovement,
  WrapIconMovement,
  TitleMovement,
  TitleClient,
  NumberDemand,
  Balance,
} from './styles'

type ButtonCreditsCardProps = {
  title: string
  amount: number
  email: string
  hour: string
  requestNumber: string
}

function ButtonCreditsCard({
  title,
  amount,
  email,
  hour,
  requestNumber,
}: ButtonCreditsCardProps): ReactElement {
  return (
    <ContainerButton>
      <Container type={amount > 0}>
        <WrapMovement>
          <Hour>{getHours(hour)}</Hour>
          <WrapIconMovement>
            <Icon src={getIcon(title)} />
            <TitleMovement>{formatTitle(title)}</TitleMovement>
          </WrapIconMovement>
        </WrapMovement>
        <TitleClient>{email}</TitleClient>
        <NumberDemand>{requestNumber}</NumberDemand>
        <Balance type={amount > 0}>{formatMoney(amount)}</Balance>
      </Container>
    </ContainerButton>
  )
}

export const CreditsCard = memo(ButtonCreditsCard)
