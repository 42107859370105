import React, {FC, useState} from 'react'
import Button from '~/components/Button'
import {Modal} from '~/components/Modal'
import {useTheme} from 'styled-components'
import SelectInput from '~/components/SelectInput'
import {Info, InfoContainer, ModalFooter} from './styles'
import {disableBonusRule} from '~/services/bonusRulesService'
import toast from 'react-hot-toast'
import {useQueryClient} from 'react-query'

interface DisableBonusRulesParams {
  onClose?: () => void
  isOpen: boolean
  bonusId: string
}

export const DisableBonusRules: FC<DisableBonusRulesParams> = ({
  isOpen,
  bonusId,
  onClose,
}) => {
  const queryClient = useQueryClient()
  const [currentOption, setCurrentOption] = useState<{
    label: string
    value: string
  }>()
  const theme = useTheme()
  const options = [
    {
      label: 'A regra possui algum dado incorreto de baixo impacto',
      value: 'low',
    },
    {
      label: 'A regra possui algum dado incorreto de alto impacto (URGENTE!)',
      value: 'high',
    },
    {
      label: 'A regra está correta, mas é preciso encerrá-la agora',
      value: 'justFinish',
    },
  ]

  const handleDisable = async () => {
    try {
      await disableBonusRule(bonusId)
      toast.success('A regra foi desativada com sucesso')
      await queryClient.refetchQueries({queryKey: ['bonusRules']})
      await queryClient.refetchQueries({queryKey: ['bonusRulesPage']})
      await queryClient.refetchQueries({queryKey: ['bonusRulesEdit']})
    } catch (e: any) {
      toast.error(e.message)
    }
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      header="INATIVAÇÃO"
      footer={
        <ModalFooter>
          <Button
            disabled={
              currentOption?.value !== 'high' &&
              currentOption?.value !== 'justFinish'
            }
            onClick={handleDisable}
            text="Inativar"
            backgroundColor={theme.colors.font.purpleDark}
          />
          <Button
            text="Cancelar"
            onClick={() => onClose()}
            backgroundColor={theme.colors.font.lighGray}
            textColor={theme.colors.font.purpleDark}
            borderColor={theme.colors.border.modal}
          />
        </ModalFooter>
      }>
      <InfoContainer>
        <p>
          ATENÇÃO: está regra está vigente no momento. Sua inativação
          interromperia todos os cálculos para novas bonificações relativas à
          esta regra.
        </p>
        <p>Se deseja inativá-la mesmo assim selecione um dos motivos abaixo:</p>
        <SelectInput
          options={options}
          value={currentOption}
          onChange={(val) => setCurrentOption(val)}
          placeholder="Selecione a vigência"
        />
        <Info show={currentOption?.value === 'low'}>
          <p>
            Ótimo! As correções de dados de baixo impacto podem se realizar sem
            pressa e dispensam a necessidade de inativação imediata da regra.
          </p>
          <p>
            Feche esta mensagem e clique em &quot;Editar&quot;. Em seguida faça
            as correções necessárias e clique em &quot;Salvar&quot;. O sistema
            vai gerar uma nova versão da regra com os dados corrigidos e
            inativará automaticamente a regra atual.
          </p>
        </Info>
        <Info show={currentOption?.value === 'high'}>
          <p>
            Compreendemos a sua urgência. Você já pode inativar a regra
            imediatamente, se desejar.
          </p>
          <p>
            Após inativar não se esqueça de editar a regra com valores corretos.
          </p>
        </Info>
        <Info show={currentOption?.value === 'justFinish'}>
          <p>
            Ok, esta regra pode ser inativada imediatamente. Mas certifique-se
            de que a inativação, ainda que provisória, não tenha efeitos
            indesejados.
          </p>
          <p>É possível reativar esta regra no futuro.</p>
        </Info>
      </InfoContainer>
    </Modal>
  )
}
