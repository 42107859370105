import react, {ReactElement} from 'react'
import Select, {GroupBase, Props} from 'react-select'
import {useTheme} from 'styled-components'

interface SelectInputProps
  extends Props<
    {label: string; value: string},
    false,
    GroupBase<{label: string; value: string}>
  > {
  width?: string
  borderRadius?: string
  hasError?: boolean
}
const SelectInput: react.FC<SelectInputProps> = ({
  width,
  borderRadius,
  hasError,
  ...rest
}): ReactElement => {
  const {colors} = useTheme()

  const customStyles = {
    indicatorSeparator: () => ({
      display: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      color: state.isSelected && colors.font.purpleMain,
      fontSize: 14,
      fontWeight: 400,
      height: 36,
      fontFamily: `Segoe UI Normal, Segoe UI, sans-serif`,
      backgroundColor: state.isSelected
        ? colors.font.purpleLight
        : colors.input.background,
      '&:not(:last-child)': {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: colors.border.input.light,
      },
      '&:hover': {
        backgroundColor: colors.background.white,
        color: colors.font.purpleMain,
      },
    }),
    menu: (provided) => ({
      ...provided,
      boxShadow: '1px 1px 4px rgb(0 0 0 / 25%)',
      marginTop: 2,
      borderRadius: 'none',
      borderBottomLeftRadius: borderRadius ? borderRadius : '9px',
      borderBottomRightRadius: borderRadius ? borderRadius : '9px',
    }),
    menuList: (provided) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      padding: 0,
      '&:last-child': {
        borderBottomLeftRadius: borderRadius ? borderRadius : '9px',
        borderBottomRightRadius: borderRadius ? borderRadius : '9px',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'inherit',
    }),
    container: (provided) => ({
      ...provided,
      flexGrow: 1,
    }),
    control: () => ({
      display: 'flex',
      fontWeight: '300',
      fontSize: '13px',
      fontFamily: `Segoe UI Normal, Segoe UI, sans-serif`,
      backgroundColor: hasError ? colors.input.error : colors.input.background,
      boxShadow: `1px 1px 4px rgba(0,0,0,0.25)`,
      alignItems: 'center',
      padding: '0px  2px',
      cursor: 'pointer',
      height: '33px',
      borderRadius: borderRadius ? borderRadius : '9px',
      minWidth: width ? width : '220px',
      width: width,
      '&:hover svg': {
        fill: colors.secondary.main,
      },
    }),
  }

  return <Select placeholder="Selecione" {...rest} styles={customStyles} />
}
export default SelectInput
