import {ReactElement, ReactNode} from 'react'
import {Body, Container, Footer} from './styles'

interface StepParams {
  footer?: ReactNode
  children?: ReactNode
}

export function Panel({footer, children}: StepParams): ReactElement {
  return (
    <Container>
      <Body hasFooter={footer !== undefined}>{children}</Body>
      {footer && <Footer>{footer}</Footer>}
    </Container>
  )
}
