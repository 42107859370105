import React, {ReactElement, useState} from 'react'
import {Container, Text} from './styles'
import {ManualScorePageItem} from '~/types/ScoreManagerTypes'
import {
  BsChevronDown,
  BsChevronUp,
  BsPeopleFill,
  BsPersonFill,
} from 'react-icons/bs'
import {useTheme} from 'styled-components'

interface ManualScorePaginationRowProps {
  manualScore: ManualScorePageItem
}

export default function ManualScorePaginationRow({
  manualScore,
}: ManualScorePaginationRowProps): ReactElement {
  const {colors} = useTheme()

  if (manualScore.bonuses.length === 1) {
    return (
      <Container>
        <Text>
          <BsPersonFill size={20} fill={colors.font.dark} />
        </Text>
        <Text>{manualScore.createdAt.toLocaleString()}</Text>
        <Text>{manualScore.bonuses.at(0).description}</Text>
        <Text>{manualScore.bonuses.at(0).customer}</Text>
        <Text>{manualScore.bonuses.at(0).value}</Text>
        {<Text>{manualScore.validUntil.toLocaleString()}</Text>}
      </Container>
    )
  }
  const [isExpanded, setIsExpanded] = useState(false)

  const handleExpansion = () => {
    setIsExpanded((val) => !val)
  }

  return (
    <>
      <Container onClick={handleExpansion}>
        <Text>
          <BsPeopleFill size={20} fill={colors.font.dark} />
        </Text>
        <Text>{manualScore.createdAt.toLocaleString()}</Text>
        <Text>{manualScore.reason}</Text>
        <Text style={{color: colors.font.placeHolder}}>(Diversos)</Text>
        <Text></Text>
        <Text></Text>
        <Text style={{textAlign: 'center'}}>
          ({manualScore.bonuses?.length}){' '}
          {isExpanded ? (
            <BsChevronUp size={15} fill={colors.font.dark} />
          ) : (
            <BsChevronDown size={15} fill={colors.font.dark} />
          )}
        </Text>
      </Container>
      {isExpanded &&
        manualScore.bonuses.map((bonus, idx) => (
          <Container key={idx} expansion>
            <Text>
              <BsPersonFill size={20} fill={colors.font.dark} />
            </Text>
            <Text>{manualScore.createdAt.toLocaleString()}</Text>
            <Text>{bonus.description}</Text>
            <Text>{bonus.customer}</Text>
            <Text>{bonus.value}</Text>
            {<Text>{manualScore.validUntil.toLocaleString()}</Text>}
          </Container>
        ))}
    </>
  )
}
