import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  background-color: ${({theme}) => theme.colors.background.whiteGray};
  padding: 10px 0;
`

export const HeaderBtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 22%;
  & > button {
    margin: 0 6px;
  }
`
export const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ScreenContent = styled.article`
  display: flex;
  flex-direction: column;
  padding: 3vh 3vw;
  background-color: ${({theme}) => theme.colors.background.white};
`
export const WrapRules = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr 3fr 3fr 1fr 1fr 0.8fr 0.5fr;
  width: 100%;
  padding: 14px 27px;
  margin-top: 3vh;
  span {
    text-align: left;
    font-size: 12px;
    font-weight: 300;
    text-transform: uppercase;
    color: ${({theme}) => theme.colors.background.gray};
  }
`
export const EmptyDiv = styled.div`
  flex: 1;
`
export const Loader = styled.div`
  position: absolute;
  top: 50%;
  transform: translateX(-50%);
  left: 53%;
`
