import {ButtonHTMLAttributes, ReactElement} from 'react'
import {Button} from './styles'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string
}

type OptionalStyles = {
  newBackgroundColor: string
  newColor: string
  newBackgroundColorHover: string
  newOpacity: number
  newOpacityHover: number
}

type ModalProps = {
  closeModal: () => void
}

type ButtonFooterProps = Partial<OptionalStyles> &
  ButtonProps &
  Partial<ModalProps>

export default function ButtonModalFooterCommon({
  closeModal,
  text,
  newBackgroundColor,
  newColor,
  newBackgroundColorHover,
  newOpacity,
  newOpacityHover,
  ...rest
}: ButtonFooterProps): ReactElement {
  return (
    <Button
      customBackgroundColor={newBackgroundColor}
      customColor={newColor}
      customBackgroundColorHover={text === 'Ativar' && newBackgroundColorHover}
      customOpacity={newOpacity}
      customOpacityHover={newOpacityHover}
      onClick={text === 'Cancelar' && closeModal}
      {...rest}>
      {text}
    </Button>
  )
}
