import {useQueryClient} from 'react-query'
import {Table, TableBody, TableItem, Button} from './styles'
import {useFinancial} from '~/components/Financial/useFinancial'
import {useMovementProvider} from '~/hooks/useMovementProvider'
import {SelectOptionsProps} from '~/types'
import {ReactElement} from 'react'

export default function RenderData(): ReactElement {
  const {data} = useFinancial()
  const clientQuery = useQueryClient()
  const {updateFilterByType} = useMovementProvider()

  const handleSelect = async (it: SelectOptionsProps) => {
    if (it.value === 'movement') {
      updateFilterByType(null)
      clientQuery.clear()
      return
    }
    updateFilterByType(it)
    clientQuery.clear()
  }

  return (
    <Table>
      <TableBody>
        <TableItem>
          <Button
            onClick={() =>
              handleSelect({value: 'credit', label: 'Valores  creditados'})
            }>
            <span className="title">Creditados</span>
          </Button>
          <Button
            onClick={() =>
              handleSelect({value: 'debit', label: 'Créditos utilizados'})
            }>
            <span className="title">Utilizados</span>
          </Button>
          <Button
            onClick={() =>
              handleSelect({
                value: 'cancellation',
                label: 'Créditos cancelados',
              })
            }>
            <span className="title">Cancelados</span>
          </Button>
          <Button
            onClick={() =>
              handleSelect({value: 'expiration', label: 'Créditos expirados'})
            }>
            <span className="title">Expirados</span>
          </Button>
          <span className="text">Movimentações</span>
        </TableItem>
        <TableItem>
          <span className="value">
            {Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(data?.credits / 100)}
          </span>
          <span className="value signalMinus">
            {Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(data?.debits / 100)}
          </span>
          <span className="value signalMinus">
            {Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(data?.cancellations / 100)}
          </span>
          <span className="value signalMinus">
            {Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(data?.expired / 100)}
          </span>
          <span className="value">
            {Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(data?.movements / 100)}
          </span>
        </TableItem>
      </TableBody>
      <TableBody className="secondaryTable">
        <TableItem>
          <span className="text">Saldo anterior</span>
          <span className="text">Movimentações</span>
          <span className="text">Saldo final</span>
        </TableItem>
        <TableItem>
          <span className="value">
            {Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(data?.previousBalance / 100)}
          </span>
          <span className="value signalMore">
            {Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(data?.movements / 100)}
          </span>
          <span className="value">
            {Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(data?.total / 100)}
          </span>
        </TableItem>
      </TableBody>
    </Table>
  )
}
