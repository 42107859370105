import {FC, ReactElement, useEffect, useState} from 'react'
import {
  Actions,
  Button,
  Container,
  Display,
  DisplayItems,
  DisplayPage,
  Input,
  Options,
  Select,
} from '~/components/NewTable/Paginator/style'
import {FaAngleLeft, FaAngleRight} from 'react-icons/fa'

type PaginatorParams = {
  isLoading?: boolean
  currentPage: number
  perPage: number
  totalItems: number
  onChange: (currentPage: number, perPage: number) => void
  perPageOptions: number[]
}

export const Paginator: FC<PaginatorParams> = ({
  isLoading,
  currentPage,
  perPage,
  totalItems,
  perPageOptions,
  onChange,
}): ReactElement => {
  const [inputPageValue, setInputPageVale] = useState(currentPage.toString())
  const totalPage = Math.ceil(totalItems / perPage) || 1
  const offSet = totalItems > 0 ? perPage * (currentPage - 1) + 1 : 0
  const setNextPage = () => {
    const nextPage = currentPage + 1
    onChange(nextPage, perPage)
    setInputPageVale(nextPage.toString())
  }
  const setPreviousPage = () => {
    const previousPage = currentPage - 1
    onChange(previousPage, perPage)
    setInputPageVale(previousPage.toString())
  }

  const setPerPage = (newPerPage: string) => {
    onChange(currentPage, parseInt(newPerPage))
  }

  useEffect(() => {
    const handleInputChange = () => {
      const newPage = parseInt(inputPageValue)
      if (!isNaN(newPage) && newPage > 0 && newPage <= totalPage) {
        onChange(newPage, perPage)
      } else {
        setInputPageVale(currentPage.toString())
      }
    }
    const debounceTimer = setTimeout(() => {
      handleInputChange()
    }, 500)
    return () => clearTimeout(debounceTimer)
  }, [inputPageValue, currentPage, totalPage, perPage])

  return (
    <Container>
      <Actions>
        <Button
          onClick={setPreviousPage}
          disabled={isLoading || currentPage === 1}>
          <FaAngleLeft />
        </Button>
        <Input
          style={{
            width: `${inputPageValue.length}ch`,
          }}
          type="number"
          value={inputPageValue}
          onChange={(e) => setInputPageVale(e.target.value)}
        />
        <Button
          onClick={setNextPage}
          disabled={isLoading || currentPage === totalPage}>
          <FaAngleRight />
        </Button>
      </Actions>
      <Display>
        <DisplayItems>
          {offSet}-
          {offSet + perPage > totalItems ? totalItems : offSet + perPage}
          &nbsp;de {totalItems} itens
        </DisplayItems>
        <DisplayPage>
          Página {currentPage} / {totalPage}
        </DisplayPage>
      </Display>
      <Options>
        <span>Exibir</span>
        <Select
          value={perPage.toString()}
          onChange={(e) => setPerPage(e.target.value)}>
          {perPageOptions.map((perPageValue, idx) => (
            <option key={idx} value={perPageValue}>
              {perPageValue}
            </option>
          ))}
        </Select>
      </Options>
    </Container>
  )
}
