import {ReactNode, ReactElement} from 'react'
import {AuthProvider} from './useAuthProvider'
import {FinancialProvider} from './useFinancialProvider'
import {MovementProvider} from './useMovementProvider'

interface ContextProviderProps {
  children: ReactNode
}

export function ContextProvider({
  children,
}: ContextProviderProps): ReactElement {
  return (
    <AuthProvider>
      <FinancialProvider>
        <MovementProvider> {children} </MovementProvider>{' '}
      </FinancialProvider>
    </AuthProvider>
  )
}
