import styled from 'styled-components'

export const LoadContainer = styled.div``

export const AddContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-basis: 0;
  border-right: 1px solid ${({theme}) => theme.colors.border.main};
`

export const SearchContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  align-items: center;
  padding-right: 6px;
  border-right: 1px solid ${({theme}) => theme.colors.border.main};
  & svg {
    fill: ${({theme}) => theme.colors.secondary.main};
  }
`

export const InputText = styled.input`
  height: 30px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
`

export const InputFile = styled.input`
  display: none;
`

export const AddButton = styled.button`
  height: 30px;
  width: 30px;
  background-color: transparent;
  & svg {
    fill: ${({theme}) => theme.colors.secondary.main};
  }
`

export const SelectFilter = styled.select`
  height: 30px;
  width: 150px;
  border: none;
  background-color: white;
`

export const CounterBox = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  color: ${({theme}) => theme.colors.secondary.main};
`

export const RemoveButton = styled.button`
  display: flex;
  margin-left: 8px;
  align-items: center;
  background-color: transparent;
`

export const InfoBoxP = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  & svg {
    margin-left: 4px;
    margin-right: 4px;
  }
`

export const BadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: auto;
  align-content: flex-start;
  gap: 4px;
`

export const CsvInfo = styled.div`
  position: relative;
  flex-wrap: nowrap;
  font-weight: bold;
  color: ${({theme}) => theme.colors.secondary.main};
  margin-left: 4px;
  margin-right: 4px;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: normal;
  text-align: center;
  line-height: normal;
  & img {
    box-shadow: 0 0 8px 8px white inset;
  }
`
