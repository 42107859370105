import {useQuery, useQueryClient, useMutation} from 'react-query'
import {Consolidation} from '~/types'
import api from '~/config/apiSauce'
import {queryKeys} from '~/utils/queryKeys'
import {useMovementProvider} from '~/hooks/useMovementProvider'

interface UseFinancialProps {
  data: Consolidation
  isLoading: boolean
  isError: boolean
  monthClose: (month: string) => void
  isFetching: boolean
}

const placeHolderData = {
  credits: 0,
  debits: 0,
  cancellations: 0,
  expired: 0,
  previousBalance: 0,
  total: 0,
  movements: 0,
  totalMovements: 0,
  isClosed: false,
} as Consolidation

export const useFinancial = (): UseFinancialProps => {
  const {yearMonth} = useMovementProvider()
  const client = useQueryClient()

  const fetchData = async (): Promise<Consolidation> => {
    const {data} = await api.get(
      `/movements/month/end-closing?month=${yearMonth}`
    )
    return data as Consolidation
  }

  const closeMonth = async (month: string) => {
    await api.post(`/movements/month/close`, {month})
  }

  const {isError, isLoading, data, isFetching} = useQuery(
    `${queryKeys.consolidation}-${yearMonth}`,
    fetchData,
    {
      placeholderData: placeHolderData,
    }
  )

  const {mutate: monthClose} = useMutation(
    async (month: string) => closeMonth(month),
    {
      onSuccess: () => {
        client.invalidateQueries(`${queryKeys.consolidation}-${yearMonth}`)
      },
    }
  )

  return {data, isLoading, isError, isFetching, monthClose}
}
