import styled from 'styled-components'

interface CardContainer {
  width?: string
}

export const Container = styled.div<CardContainer>`
  display: flex;
  flex-direction: column;
  width: ${({width}) => width ?? '100%'};
  min-height: 75px;
`

export const Header = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
`

interface BodyParams {
  hasFooter: boolean
}
export const Body = styled.div<BodyParams>`
  flex: 1;
  padding: 76px 8px 8px 8px;
  min-height: 80px;
  border-top: 1px solid ${({theme}) => theme.colors.border.main};
  background-color: ${({theme}) => theme.colors.background.white};
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  ${({hasFooter}) =>
    !hasFooter &&
    `
    padding-bottom: 40px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  `}
`

export const Footer = styled.div`
  margin-top: auto;
  background-color: ${({theme}) => theme.colors.input.background};
  padding: 8px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top: 1px solid ${({theme}) => theme.colors.border.main};
  background-color: ${({theme}) => theme.colors.background.step.footerColor};
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  min-height: 40px;
`

export const Filler = styled.div`
  flex-grow: 1;
  height: 100%;
  min-width: 75px;
  border-bottom: 3px solid ${({theme}) => theme.colors.secondary.main};
`

interface ItemParams {
  activated: boolean
}
export const Item = styled.div<ItemParams>`
  width: 150px;
  height: 100%;
  position: relative;
  box-sizing: content-box;
  ${({theme, activated}) =>
    activated && `border-bottom: 3px solid ${theme.colors.secondary.main};`}
`

export const ItemContent = styled.div`
  position: absolute;
  left: -50px;
  bottom: -51px;
  height: 76px;
  width: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
`

interface ItemLabelParams extends ItemParams {
  actual: boolean
}
export const ItemLabel = styled.div<ItemLabelParams>`
  width: 100px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  font-weight: ${({actual}) => (actual ? 'bold' : 'normal')};
  font-size: 14px;
  color: ${({activated, theme}) =>
    activated
      ? theme.colors.secondary.main
      : theme.colors.background.step.disabled};
`
export const IconContainer = styled.div<ItemParams>`
  background-color: ${({activated, theme}) =>
    activated
      ? theme.colors.background.step.enabled
      : theme.colors.background.step.disabled};
  height: 50px;
  width: 50px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    font-size: 25px;
    fill: ${({theme}) => theme.colors.background.white};
  }
`
