import styled from 'styled-components'

export const Container = styled.div`
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`

export const Title = styled.span`
  margin-bottom: 15px;
  margin-left: 19px;
  color: blue;
  text-transform: uppercase;
  color: ${({theme}) => theme.colors.font.main};
  font-size: 14px;
  font-weight: 300;
`

export const Hr = styled.hr`
  border: 1px solid rgba(158, 158, 158, 0.25);
  margin-bottom: 100px;
`
