import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

type InputDateClicked = {
  isClickedInput: boolean
}

interface OptionLabelProps {
  prop: boolean
}
export const Container = styled.div`
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
export const Title = styled.span`
  font-weight: 400;
  color: ${({theme}) => theme.colors.font.main};
  font-size: 14px;
  margin-left: 0.5vw;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const WrapInputText = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-evenly;
  align-items: center;
`

export const InputTypeText = styled.input`
  display: flex;
  font-size: 14px;
  padding: 8px 35px;
  width: 100%;
  border-radius: 9px;
  background-color: ${({theme}) => theme.colors.background.white};
  box-shadow: rgb(0 0 0 / 16%) 0 0 4px 0px, rgb(0 0 0 / 23%) 0px 3px 6px;
  color: ${({theme}) => theme.colors.font.dark};
`

export const ToggleInput = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.background.whiteGray};
  width: 32px;
  height: 32px;
  border-radius: 9px;
  box-shadow: rgb(0 0 0 / 16%) 0 0 4px 0px, rgb(0 0 0 / 23%) 0px 3px 6px;
  &:hover {
    opacity: 0.5;
  }
`

export const ButtonCheck = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  outline: none;
  border: none;
  position: absolute;
  left: 0;
  margin-left: 10px;
`

export const InputTypeBox = styled.input`
  min-width: 15px;
  height: 15px;
  cursor: pointer;
  border: 3px solid ${({theme}) => theme.colors.primaryYellow.main};
  &:hover {
    border: 3px solid ${({theme}) => theme.colors.primaryYellow.main};
  }
`

export const WrapDate = styled.div<InputDateClicked>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${({isClickedInput}) => (isClickedInput ? 242 : 40)}px;
  height: 32px;
  position: relative;
  height: 32px;
  border-radius: 9px;
  background-color: ${({theme}) => theme.colors.background.whiteGray};
  box-shadow: rgb(0 0 0 / 16%) 0 0 4px 0px, rgb(0 0 0 / 23%) 0px 3px 6px;
`

export const InputTyDate = styled(DatePicker)<InputDateClicked>`
  display: flex;
  align-items: center;
  position: absolute;
  width: ${({isClickedInput}) => (isClickedInput ? 240 : 42)}px;
  height: 26px;
  padding: 0px 10px;
  margin-bottom: 3px;
  margin-left: 3px;
  z-index: 3;
  outline: none;
  bottom: -10px;
  background-color: transparent;
  cursor: pointer;
  font-weight: 400;
  font-size: 13px;
  color: ${({theme}) => theme.colors.font.dark};
`

export const WrapImageDate = styled.div`
  display: flex;
  position: absolute;
  justify-content: flex-end;
  align-items: center;
  right: 10px;
  min-width: 30px;
  height: 23px;
  background-color: transparent;
  border-left: none;
`

export const ImgDate = styled.img`
  min-width: 20px;
  height: 20px;
  border: none;
  background-color: transparent;
`
export const SpanLabel = styled.span<OptionLabelProps>`
  font-size: 13px;
  font-weight: 300;
  color: ${({theme, prop}) =>
    prop ? theme.colors.font.purpleMain : theme.colors.font.main};
`
export const OptionLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover span {
    color: ${({theme}) => theme.colors.font.purpleMain};
  }
`
