import styled, {css} from 'styled-components'

interface InputProps {
  haveFocus: boolean
}
export const Container = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-bottom: 1px solid ${({theme}) => theme.colors.border.modal};
`

export const FormContainer = styled.div`
  max-width: 100%;
  justify-content: center;
  align-content: center;
  flex: 1;
  padding: 6px 24px;
`

export const SearchInput = styled.input<InputProps>`
  font-size: 12px;
  border: 1px solid grey;
  float: left;
  width: 90%;
  box-sizing: border-box;
  padding: 6px 12px;
  height: 32px;
  border: 1px;
  border-color: ${({theme}) => theme.colors.background.gray};
  border-radius: 8px 0px 0px 8px;
  box-shadow: 0px 2px 3px -1px ${({theme}) => theme.colors.background.gray};
  background: ${({theme}) => theme.colors.input.background};
  ${({haveFocus}) =>
    haveFocus &&
    css`
      background-color: ${({theme}) => theme.colors.background.white};
    `}
`
export const SearchButton = styled.button`
  background: ${({theme}) => theme.colors.font.purpleDark};
  width: 56px;
  height: 32px;
  border-radius: 0px 5px 5px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`
