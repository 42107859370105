import {useQuery} from 'react-query'
import {queryKeys} from '~/utils/queryKeys'
import api from '~/config/apiSauce'
import {GoalProps} from '~/types'

interface GoalIndicationProps {
  data: GoalProps[]
  isLoading: boolean
  isFetching: boolean
}

export const useBarIndication = (): GoalIndicationProps => {
  const {indicationGoal, indication} = queryKeys

  async function fetchData(): Promise<GoalProps[]> {
    const response = await api.get('/referral/chart/goal')
    const {data} = response as {data: GoalProps[]}
    return data
  }

  const {data, isLoading, isFetching} = useQuery(
    [indication, indicationGoal],
    fetchData
  )

  return {
    isLoading,
    data,
    isFetching,
  }
}
