import {ReactNode, ReactElement, CSSProperties} from 'react'
import {Container} from './styles'

interface RowProps {
  mobile?: number
  tablet?: number
  desktop?: number
  children?: ReactNode
  style?: CSSProperties
}
const Column = ({
  children,
  mobile,
  tablet,
  desktop,
  style,
}: RowProps): ReactElement => {
  return <Container {...{mobile, tablet, desktop, style}}>{children}</Container>
}

export default Column
