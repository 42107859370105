import React, {
  MutableRefObject,
  ReactElement,
  useImperativeHandle,
  useState,
} from 'react'
import {ProgressBar} from '~/components/ProgressBar'
import {Modal} from '~/components/Modal'
import {DateTime} from 'luxon'
import {toast} from 'react-toastify'
import {ApiManagerPagination} from '~/types/ApiManagerTypes'
import {unparse} from 'papaparse'
import {sleep} from '~/utils'

export type CSVRef = {
  generateCSV: () => void
}

type CSVGeneratorProps<T> = {
  csvName: string
  request: (page: number) => Promise<ApiManagerPagination<T>>
  csvRef: MutableRefObject<CSVRef>
  map?: (value: T) => object
}

/**
 * Para utilizá-lo é necessário criar uma referência utilizando o hook useRef do react
 * e chamar a função generateCSV dentro da referência,
 * você pode utilizar o tupo CSVRef para declarar o genérico do hook useRef,
 * o nome do csv não deve conter a extensão, essa é adicionada automáticamente
 *
 * @example
 *  const csvRef = useRef<CSVRef>()
 *
 *  <button onClick={() => {
 *    if (csvRef.current) {
 *      csvRef.current.generateCSV()
 *    }
 *  }}>Click me</button>
 *  <CsvGenerator
 *    csvRef={csvRef}
 *    request={(csvPage) => getPagination(value, csvPage, 1000, filters)}
 *    csvName="csv_name"
 *  />
 *
 * @param csvName
 * @param request
 * @param csvRef
 * @param map
 * @constructor
 */

export function CSVGenerator<T>({
  csvName,
  request,
  csvRef,
  map,
}: CSVGeneratorProps<T>): ReactElement {
  const [progress, setProgress] = useState(0)
  const [csvModal, setCsvModal] = useState(false)
  const generateCsvByPagination = async (
    csvName: string,
    request: (page: number) => Promise<ApiManagerPagination<T>>,
    callback: (percentage: number, pagination: ApiManagerPagination<T>) => any
  ): Promise<void> => {
    const firstPage = await request(1)
    let data: (object | T)[] = firstPage.data.map((row) => map?.(row) ?? row)
    const parts = firstPage.meta.pagination.maxPage
    if (parts > 1) {
      const perPart = 100 / parts
      callback(Math.ceil(perPart), firstPage)
      for (let i = 2; i <= parts; i++) {
        const nextPage = await request(i)
        data = data.concat(nextPage.data.map((row) => map?.(row) ?? row))
        callback(Math.ceil(perPart * i), nextPage)
      }
    } else {
      callback(100, firstPage)
    }
    const csv = unparse(data)
    const csvBlob = new Blob([csv], {type: 'text/csv;charset=utf-8;'})
    const tempLink = document.createElement('a')
    await sleep(1400)
    tempLink.href = window.URL.createObjectURL(csvBlob)
    tempLink.setAttribute('download', csvName)
    tempLink.click()
    tempLink.remove()
  }

  const generateCSV = async () => {
    const now = DateTime.now()
    setCsvModal(true)
    const currentProgress = 0
    setProgress(currentProgress)
    try {
      await generateCsvByPagination(
        `${csvName}_${now.toMillis()}.csv`,
        (page) => request(page),
        (referral) => {
          setProgress(referral)
        }
      )
    } catch (e) {
      toast.error('Falha ao gerar o arquivo CSV')
    }
    toast.info('Dados exportados com sucesso!')
    setCsvModal(false)
  }
  useImperativeHandle(csvRef, () => ({
    generateCSV,
  }))
  return (
    <Modal width="400px" isOpen={csvModal}>
      <p style={{textAlign: 'center'}}>Aguarde enquanto preparamos tudo...</p>
      <ProgressBar percentage={progress} />
    </Modal>
  )
}
