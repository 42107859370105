import React, {ReactElement, useState} from 'react'
import {Container, Text} from './styles'
import Badge from '~/components/Badge'
import {BsChevronDown, BsPeopleFill, BsPersonFill} from 'react-icons/bs'
import {useTheme} from 'styled-components'
import {formatMoney} from '~/components/ButtonCreditsCard/functions'

interface ManualBonusRowData {
  includedAt: string
  description: string
  customer: string
  transactionType: string
  value: number
  processingStatus: string
}

interface ManualBonusRowProps {
  data: ManualBonusRowData | ManualBonusRowData[]
  multiDataDescription?: string
  multiDataTotalValue?: number
  multiDataTransactionType?: string
  multiDataProcessingStatus?: string
}

const ManualBonusRow: React.FC<ManualBonusRowProps> = ({
  data,
  multiDataDescription,
  multiDataTotalValue,
  multiDataTransactionType,
  multiDataProcessingStatus,
}): ReactElement => {
  const {colors} = useTheme()
  const [isExpanded, SetIsExpanded] = useState(false)

  const RenderSingleDataRow = (
    dataEntity: ManualBonusRowData,
    showIcon = true
  ) => (
    <Container
      key={`${dataEntity.value}_${dataEntity.description}_${dataEntity.customer}`}>
      {showIcon ? (
        <BsPersonFill size={20} fill={colors.font.dark} />
      ) : (
        <Text> </Text>
      )}
      <Text>{dataEntity.includedAt}</Text>
      <Text>{dataEntity.description}</Text>
      <Text>{dataEntity.customer}</Text>
      <Text>{dataEntity.transactionType}</Text>
      <Text>{formatMoney(dataEntity.value, false)}</Text>
      <Badge>{dataEntity.processingStatus}</Badge>
      <Text> </Text>
    </Container>
  )
  if (Array.isArray(data)) {
    return (
      <>
        <Container
          onClick={() => {
            SetIsExpanded(!isExpanded)
          }}>
          <BsPeopleFill size={20} fill={colors.font.dark} />
          <Text>{(data as ManualBonusRowData[])[0].includedAt}</Text>
          <Text>{multiDataDescription ?? 'Inserção multipla'}</Text>
          <Text isGray={true}>(Diversos)</Text>
          <Text>{multiDataTransactionType}</Text>
          <Text>{formatMoney(multiDataTotalValue, false)}</Text>
          {multiDataProcessingStatus.includes('Processando') ? (
            <Text>{multiDataProcessingStatus}</Text>
          ) : (
            <Badge>{multiDataProcessingStatus}</Badge>
          )}
          <Text>
            {`(${(data as ManualBonusRowData[]).length})`}
            <BsChevronDown size={15} fill={colors.font.dark} />
          </Text>
        </Container>
        {isExpanded ? (
          data.map((value) => RenderSingleDataRow(value, false))
        ) : (
          <></>
        )}
      </>
    )
  }
  return RenderSingleDataRow(data)
}
export default ManualBonusRow
