import React, {FC, ReactElement, useState} from 'react'
import PageContainer from '~/components/PageContainer'
import WhiteHeader from '~/components/WhiteHeader'
import Row from '~/components/Row'
import Column from '~/components/Column'
import {Panel} from '~/components/Panel'
import Button from '~/components/Button'
import {FaSearch} from 'react-icons/fa'
import * as yup from 'yup'
import {ValidationError} from 'yup'
import Input from '~/components/Input'
import {InputNumber} from '~/components/InputNumber'
import toast from 'react-hot-toast'
import {
  getCustomersData,
  postSingleCustomer,
} from '~/services/scoreManagerService'
import {CustomerData} from '~/types/ScoreManagerTypes'
import {useQueryClient} from 'react-query'
import {useHistory} from 'react-router-dom'
import {
  Divider,
  InputContainer,
  InputContent,
  SubTitle,
  TextContainer,
  TextLabel,
  TextValue,
} from '~/components/Styled'

export const ManualScoreUnitCreation: FC = (): ReactElement => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const [formErrors, setFormErrors] = useState<Record<string, boolean>>({})
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [customer, setCustomer] = useState<CustomerData>(undefined)
  const [formData, setFormData] = useState({
    customer: '',
    description: '',
    reason: '',
    value: undefined,
    validity: undefined,
  })

  const handleYupError = (error: ValidationError) => {
    setFormErrors(
      error.inner.reduce((obj, error) => ({...obj, [error.path]: true}), {})
    )
  }

  const getCustomer = async () => {
    const schema = yup.object({
      customer: yup
        .string()
        .email('O email deve ser válido')
        .required('O campo email é obrigatório'),
    })
    try {
      schema.validateSync(formData, {abortEarly: false})
    } catch (e: any) {
      toast.error(e.errors.at(0))
      return handleYupError(e)
    }
    const [customer] = await getCustomersData([formData.customer])
    if (!customer.isValid) {
      toast.error('Cliente não encontrado, por favor faça o cadastro na Vtex')
      setFormErrors((obj) => ({...obj, customer: true}))
      setCustomer(undefined)
      return
    }
    setCustomer(customer)
  }

  const submit = async () => {
    if (customer === undefined) {
      toast.error('É necessário localizar o cliente para realizar a inserção')
      setFormErrors((obj) => ({...obj, customer: true}))
      return
    }
    const schema = yup.object({
      customer: yup
        .string()
        .email('O email deve ser válido')
        .required('O campo email é obrigatório'),
      description: yup.string().required('O campo Descrição é obrigatório'),
      reason: yup.string().required('O campo Motivo é obrigatório'),
      value: yup
        .number()
        .required('O campo Valor é obrigatório')
        .min(1, 'O valor mínimo é 1'),
      validity: yup
        .number()
        .required('O campo Validade é obrigatório')
        .min(1, 'A validade mínima é 1'),
    })
    try {
      schema.validateSync(formData, {abortEarly: false})
    } catch (e: any) {
      e.errors.map((error) => {
        toast.error(error)
      })
      return handleYupError(e)
    }
    try {
      await postSingleCustomer(formData)
      await queryClient.refetchQueries({queryKey: ['manualScorePage']})
      history.push('/pontuacao-manual')
    } catch (e: any) {
      toast.error(e.message)
    }
  }

  return (
    <PageContainer>
      <WhiteHeader backTo="/pontuacao-manual" />
      <Row style={{padding: '0 24px'}}>
        <Column desktop={12} mobile={12} tablet={12}>
          <Panel
            footer={
              <Button
                text="Processar"
                disabled={disableSubmit}
                onClick={async () => {
                  setDisableSubmit(true)
                  await submit()
                  setDisableSubmit(false)
                }}
              />
            }>
            <Row>
              <Column desktop={6} tablet={12} mobile={12}>
                <InputContainer>
                  <InputContent>
                    <Input
                      rightAdornment={
                        <Button
                          icon={FaSearch}
                          iconColor="#FFF"
                          onClick={getCustomer}
                        />
                      }
                      placeholder="Email"
                      hasError={formErrors.customer}
                      onFocus={() =>
                        setFormErrors((errors) => ({
                          ...errors,
                          customer: false,
                        }))
                      }
                      id="name"
                      value={formData.customer}
                      onChange={(e) =>
                        setFormData((formData) => ({
                          ...formData,
                          customer: e.target.value,
                        }))
                      }
                    />
                  </InputContent>
                </InputContainer>
              </Column>
            </Row>
            <Divider />
            <SubTitle>Cliente Selecionado</SubTitle>
            <Row>
              <Column desktop={6} tablet={12} mobile={12}>
                <TextContainer>
                  <TextLabel>Nome</TextLabel>
                  <TextValue>{customer?.name ?? '-'}</TextValue>
                </TextContainer>
              </Column>
            </Row>
            <Row>
              <Column desktop={6} tablet={12} mobile={12}>
                <TextContainer>
                  <TextLabel>Email</TextLabel>
                  <TextValue>{customer?.email ?? '-'}</TextValue>
                </TextContainer>
              </Column>
            </Row>
            <Row>
              <Column desktop={6} tablet={12} mobile={12}>
                <TextContainer>
                  <TextLabel>CPF</TextLabel>
                  <TextValue>{customer?.cpf ?? '-'}</TextValue>
                </TextContainer>
              </Column>
            </Row>
            <Divider />
            <Row>
              <Column desktop={6} tablet={12} mobile={12}>
                <InputContainer>
                  <label htmlFor="name">Descrição</label>
                  <InputContent>
                    <Input
                      hasError={formErrors.description}
                      onFocus={() =>
                        setFormErrors((errors) => ({
                          ...errors,
                          description: false,
                        }))
                      }
                      id="name"
                      value={formData.description}
                      onChange={(e) =>
                        setFormData((formData) => ({
                          ...formData,
                          description: e.target.value,
                        }))
                      }
                    />
                  </InputContent>
                </InputContainer>
              </Column>
            </Row>
            <Row>
              <Column desktop={6} tablet={12} mobile={12}>
                <InputContainer>
                  <label htmlFor="name">Motivo</label>
                  <InputContent>
                    <Input
                      hasError={formErrors.reason}
                      onFocus={() =>
                        setFormErrors((errors) => ({
                          ...errors,
                          reason: false,
                        }))
                      }
                      id="name"
                      value={formData.reason}
                      onChange={(e) =>
                        setFormData((formData) => ({
                          ...formData,
                          reason: e.target.value,
                        }))
                      }
                    />
                  </InputContent>
                </InputContainer>
              </Column>
            </Row>
            <Row>
              <Column desktop={6} tablet={12} mobile={12}>
                <InputContainer>
                  <label htmlFor="validityDays">Valor</label>
                  <InputContent style={{flex: 0.3}}>
                    <InputNumber
                      hasError={formErrors.value}
                      onFocus={() =>
                        setFormErrors((errors) => ({
                          ...errors,
                          value: false,
                        }))
                      }
                      id="validityDays"
                      value={formData.value}
                      onChange={(e) =>
                        setFormData((formData) => ({
                          ...formData,
                          value: e.target.value,
                        }))
                      }
                    />
                  </InputContent>
                </InputContainer>
              </Column>
            </Row>
            <Row>
              <Column desktop={6} tablet={12} mobile={12}>
                <InputContainer>
                  <label htmlFor="validityDays">Validade (em dias)</label>
                  <InputContent style={{flex: 0.3}}>
                    <InputNumber
                      hasError={formErrors.validity}
                      onFocus={() =>
                        setFormErrors((errors) => ({
                          ...errors,
                          validityDays: false,
                        }))
                      }
                      id="validityDays"
                      value={formData.validity}
                      onChange={(e) =>
                        setFormData((formData) => ({
                          ...formData,
                          validity: e.target.value,
                        }))
                      }
                    />
                  </InputContent>
                </InputContainer>
              </Column>
            </Row>
          </Panel>
        </Column>
      </Row>
    </PageContainer>
  )
}
