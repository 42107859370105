import {
  useContext,
  useState,
  createContext,
  ReactNode,
  ReactElement,
} from 'react'
import {SelectOptionsProps} from '~/types'

interface ContextProps {
  dayMin: string
  dayMax: string
  updateDayMax: (day: string) => void
  updateDayMin: (day: string) => void
  yearMonth: string
  updateYearMonth: (yearMonth: string) => void
  minDate: Date
  maxDate: Date
  updateMaxDate: (date: Date) => void
  updateMinDate: (date: Date) => void
  filterByType: SelectOptionsProps
  filterByEmail: string
  updateFilterByType: (type: SelectOptionsProps) => void
  updateFilterByEmail: (email: string) => void
  updateScroll: (status: boolean) => void
  isFinishedScroll: boolean
}

export const MovementContext = createContext<ContextProps>({} as ContextProps)

type MovementProviderProps = {children: ReactNode}

const MovementProvider = ({children}: MovementProviderProps): ReactElement => {
  const today =
    new Date().getDate() < 10
      ? `0${new Date().getDate()}`
      : `${new Date().getDate()}`
  const year = new Date().getFullYear()
  const month =
    new Date().getMonth() + 1 < 10
      ? `0${new Date().getMonth() + 1}`
      : new Date().getMonth() + 1
  const [dayMax, setDayMax] = useState(today)
  const [dayMin, setDayMin] = useState('01')
  const [yearMonth, setYearMonth] = useState(`${year}-${month}`)
  const [minDate, setMinDate] = useState(
    new Date(year, new Date().getMonth(), 1)
  )
  const [maxDate, setMaxDate] = useState(
    new Date(year, new Date().getMonth(), Number(today))
  )
  const [filterByType, setFilterByType] = useState<SelectOptionsProps>({
    value: 'movement',
    label: 'Todas movimentações',
  })
  const [filterByEmail, setFilterByEmail] = useState('')
  const [isFinishedScroll, setIsFinishedScroll] = useState(false)

  const updateScroll = (status: boolean) => setIsFinishedScroll(status)

  const updateFilterByType = (type: SelectOptionsProps | null) =>
    setFilterByType(type)

  const updateFilterByEmail = (email: string) => setFilterByEmail(email)

  const updateMaxDate = (date: Date) => setMaxDate(date)

  const updateMinDate = (date: Date) => setMinDate(date)

  const updateDayMax = (dayMax: string) => setDayMax(dayMax)

  const updateDayMin = (dayMin: string) => setDayMin(dayMin)

  const updateYearMonth = (yearMonth: string) => setYearMonth(yearMonth)

  return (
    <MovementContext.Provider
      value={{
        updateDayMax,
        updateDayMin,
        dayMin,
        dayMax,
        updateYearMonth,
        yearMonth,
        minDate,
        maxDate,
        updateMinDate,
        updateMaxDate,
        updateFilterByEmail,
        updateFilterByType,
        filterByType,
        filterByEmail,
        updateScroll,
        isFinishedScroll,
      }}>
      {children}
    </MovementContext.Provider>
  )
}

const useMovementProvider = (): ContextProps => {
  const context = useContext(MovementContext)
  return context
}

export {MovementProvider, useMovementProvider}
