import styled from 'styled-components'

export const CalendarContainer = styled.div`
  * {
    color: inherit;
  }
  abbr {
    text-decoration: none;
  }
  .react-calendar {
    background: white;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
  }
  .react-calendar.react-calendar--doubleView {
    width: 450px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    padding: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 2px;
    border: 0;
    outline: none;
    border-radius: 6px;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    display: flex;
    height: 25px;
    margin-bottom: 1em;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: unset;
  }
  .react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.59em;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  .react-calendar__month-view__days {
    display: grid !important;
    grid-template-columns: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%;

    .react-calendar__tile {
      max-width: initial !important;
    }
  }
  .react-calendar__month-view__days__day--weekend {
    background-color: ${({theme}) => theme.colors.background.calendar.weekend};
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    color: ${({theme}) => theme.colors.font.black};
    max-width: 100%;
    padding: 10px 6px;
    background-color: ${({theme}) => theme.colors.background.calendar.days};
    text-align: center;
    line-height: 9px;
    font-size: 0.7em;
  }
  .react-calendar__century-view__decades__decade {
    flex: 0 0 calc(33.33% - 4px) !important;
    font-size: 0.62em;
  }
  .react-calendar__decade-view__years__year {
    flex: 0 0 calc(33.33% - 4px) !important;
  }
  .react-calendar__tile.react-calendar__year-view__months__month {
    flex: 0 0 calc(33.33% - 4px) !important;
  }
  .react-calendar__tile.react-calendar__month-view__days__day--weekend {
    background-color: ${({theme}) => theme.colors.background.calendar.weekend};
  }
  .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth {
    background: none;
  }
  .react-calendar__tile:disabled {
    background-color: #f0f0f0;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: ${({theme}) => theme.colors.background.calendar.hover};
  }
  .react-calendar__tile--now {
    background: ${({theme}) => theme.colors.background.calendar.today};
  }
  .react-calendar__tile--active {
    background: ${({theme}) => theme.colors.background.calendar.daySelected};
    color: ${({theme}) => theme.colors.font.white};
  }

  .react-calendar__month-view__days__day--weekend.react-calendar__tile--active {
    background-color: ${({theme}) =>
      theme.colors.background.calendar.weekendSelected};
  }
  .react-calendar__tile--active:enabled:focus {
    background-color: ${({theme}) =>
      theme.colors.background.calendar.daySelected};
  }
  .react-calendar__tile--active:enabled:hover {
    background-color: ${({theme}) =>
      theme.colors.background.calendar.hoverSelected};
  }
  .react-calendar__navigation__arrow {
    font-size: 35px;
    height: 35px;
  }
  .react-calendar__navigation__label {
    height: 35px;
  }
  .react-calendar__month-view__days__day--neighboringMonth,
  .react-calendar__month-view__days__day--neighboringMonth.react-calendar__month-view__days__day--weekend,
  .react-calendar__month-view__days__day--neighboringMonth.react-calendar__tile--active:enabled:focus {
    color: black;
    background: none;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: ${({theme}) => theme.colors.background.calendar.range};
  }
`
