import {FC, ReactElement, ReactNode} from 'react'
import {SkeletonBox} from '~/components/Skeleton/style.css'
import {StandardLonghandProperties} from 'csstype'

type SkeletonParams = {
  isLoading?: boolean
  content?: ReactNode
  width?: string | number
} & Pick<
  StandardLonghandProperties<string | number>,
  'width' | 'height' | 'fontSize' | 'fontWeight' | 'color'
>

export const Skeleton: FC<SkeletonParams> = ({
  isLoading,
  content,
  width,
  height,
  fontSize,
  fontWeight,
  color,
}): ReactElement => {
  return (
    <>
      {isLoading !== undefined && !isLoading ? (
        <span style={{fontSize, fontWeight, color}}>{content}</span>
      ) : (
        <SkeletonBox style={{width, height, backgroundColor: color}} />
      )}
    </>
  )
}
