import styled from 'styled-components'

interface ContainerProps {
  backgroundColor?: string
  fontSize: number
  fontColor?: string
  type?: 'error' | 'info' | 'success' | 'disabled' | 'warning'
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  height: 30px;
  padding: 5px 15px;
  text-align: center;
  border-radius: 13px;
  background-color: ${({backgroundColor, type, theme}) =>
    backgroundColor ??
    theme.colors.background.badge[type] ??
    theme.colors.background.badge.success};
  font-weight: 300;
  font-size: ${({fontSize}) => fontSize ?? 13}px;
  color: ${({fontColor, theme, type}) =>
    fontColor ??
    theme.colors.font.badge[type] ??
    theme.colors.font.badge.success};
`
