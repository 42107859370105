import react, {InputHTMLAttributes, ReactElement, useRef} from 'react'
import {
  Container,
  Input,
  InputButton,
  Wrapper,
  Message,
  InputWrapper,
} from './styles'
import {FaCloudUploadAlt} from 'react-icons/fa'
import React from 'react'
import {useTheme} from 'styled-components'

interface FileInputProps extends InputHTMLAttributes<HTMLInputElement> {
  accepts: string
  placeHolder: string
  message?: React.ReactNode
}
const FileInput: react.FC<FileInputProps> = ({
  accepts,
  message,
  placeHolder,
  ...rest
}): ReactElement => {
  const fileInput = useRef(null)
  const {colors} = useTheme()
  return (
    <Container>
      <Wrapper>
        <InputWrapper
          onClick={(e) => fileInput.current && fileInput.current.click()}>
          <span>{placeHolder}</span>
          <Input
            placeholder={'Selecione a planilha com ajustes'}
            ref={fileInput}
            type="file"
            accept={accepts}
            {...rest}
          />
        </InputWrapper>
        <InputButton
          onClick={(e) => fileInput.current && fileInput.current.click()}>
          <FaCloudUploadAlt size={20} fill={colors.font.purpleLight} />
        </InputButton>
      </Wrapper>
      {message}
    </Container>
  )
}
export default FileInput
