import {ReactElement} from 'react'
import {Container} from './styles'
import FinancialClose from './FinancialClose'

function Financial(): ReactElement {
  return (
    <Container>
      <FinancialClose />
    </Container>
  )
}

export default Financial
