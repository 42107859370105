import React, {
  ForwardedRef,
  InputHTMLAttributes,
  ReactElement,
  ReactNode,
  useState,
} from 'react'
import {AdornmentContainer, InputContainer, InputText} from './styles'

interface InputDefaultProps extends InputHTMLAttributes<HTMLInputElement> {
  forwardedRef?: ForwardedRef<HTMLInputElement>
  inputWidth?: string
  hasError?: boolean
  leftAdornment?: ReactNode
  rightAdornment?: ReactNode
}

const Input: React.FC<InputDefaultProps> = ({
  inputWidth,
  hasError,
  onFocus,
  onBlur,
  leftAdornment,
  rightAdornment,
  ...rest
}): ReactElement => {
  const [isFocus, setIsFocus] = useState(false)

  const handleFocus = (e) => {
    setIsFocus(true)
    onFocus?.(e)
  }

  const handleBlur = (e) => {
    setIsFocus(false)
    onBlur?.(e)
  }
  return (
    <InputContainer>
      {leftAdornment && (
        <AdornmentContainer>{leftAdornment}</AdornmentContainer>
      )}
      <InputText
        ref={rest.forwardedRef}
        hasError={hasError}
        inputWidth={inputWidth}
        haveFocus={isFocus}
        onBlur={handleBlur}
        onFocus={handleFocus}
        {...rest}
      />
      {rightAdornment && (
        <AdornmentContainer>{rightAdornment}</AdornmentContainer>
      )}
    </InputContainer>
  )
}
export default Input
