import {FC, ReactElement} from 'react'
import {Container, Content, Tab} from '~/components/ContentTab/styles'

export const ContentTab: FC<{
  tabs: Array<{title: string; value: string}>
  currentValue: string
  onChange: (currentValue: string) => any
}> = ({tabs, currentValue, onChange}): ReactElement => {
  return (
    <Container>
      <Content>
        {tabs.map((tab, idx) => (
          <Tab
            key={idx}
            activated={currentValue === tab.value}
            onClick={() => onChange(tab.value)}>
            {tab.title}
          </Tab>
        ))}
      </Content>
    </Container>
  )
}
