import {ReactElement, Fragment, useState} from 'react'
import {useTheme} from 'styled-components'
import {MdSettings} from 'react-icons/md'
import MetaChart from './Meta'
import {Container, Title, Body, WrapHeader, WrapIcon, Button} from './styles'
import ConsolidaAnualChart from './ConsolidacaoAnual'
import ConsolidaMensalChart from './ConsolidacaoMensal'
import ModalMetaIndication from '~/components/ModalGoalIndication'

export default function Indication(): ReactElement {
  const {colors} = useTheme()
  const [openModal, setOpenModal] = useState(false)

  const handleOpenModal = () => setOpenModal(true)

  const handleCloseModal = () => setOpenModal(false)

  return (
    <Fragment>
      <Container>
        <WrapHeader>
          <Title>Indicações</Title>
          <Button onClick={handleOpenModal}>
            <WrapIcon>
              <MdSettings size={16} fill={colors.background.white} />
            </WrapIcon>
          </Button>
        </WrapHeader>
        <Body>
          <div
            style={{
              display: 'flex',
              width: '90%',
              gap: 70,
            }}>
            <MetaChart text="Indicações por meta" />
            <ConsolidaAnualChart text="Consolidação Anual" />
          </div>
          <div
            style={{
              display: 'flex',
              width: '90%',
            }}>
            <ConsolidaMensalChart text="Consolidação Mensal" />
          </div>
        </Body>
      </Container>
      <ModalMetaIndication
        closeModal={handleCloseModal}
        opened={openModal}
        openModal={setOpenModal}
      />
    </Fragment>
  )
}
