import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 25px;
  background-color: #f7f7f7;
`

export const BodyDouble = styled.div`
  display: flex;
  max-width: 1152px;
  width: 100%;
  height: 100%;
  margin-bottom: 50px;
  justify-content: space-between;
  gap: 20px;
`

export const Body = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 100;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
`

export const Title = styled.h1`
  font-size: 16px;
  color: ${({theme}) => theme.colors.font.main};
  font-weight: 700;
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
`

export const Block = styled.div`
  height: 400px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
`

export const GaugeChart = styled.div`
  &#test {
    width: 50%;
  }
`

export const BigNumber = styled.div`
  display: block;
  text-align: center;
  font-size: 22px;
  color: #c35252;
  font-weight: bold;

  span.gauge-period {
    display: grid;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    color: #d99d01;
    margin-top: 10px;

    &.ideal {
      font-size: 10px;
      color: ${({theme}) => theme.colors.font.main};
      font-weight: 500;
    }
  }
`
