import {ReactElement, ReactNode} from 'react'
import {WrapContent} from '~/components/Indications/Meta/styles'
import {ContentTitle} from '../DoubleCardsBreakage/styles'
import {Container, Title} from './styles'

interface SingleCardProps {
  text: string
  children: ReactNode
}

export interface CustomSelectPeriodBreakageProps {
  value: string
  label: string
  icon?: ReactElement
}

function SingleCard({text, children}: SingleCardProps): ReactElement {
  return (
    <Container>
      <ContentTitle>
        <Title> {text} </Title>
      </ContentTitle>
      <WrapContent>{children}</WrapContent>
    </Container>
  )
}

export default SingleCard
