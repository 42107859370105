import styled from 'styled-components'

export const InfoBox = styled.div`
  display: flex;
  margin: auto;
  flex-direction: column;
  flex: 1;
  line-height: 1.5em;
  justify-content: center;
  overflow: hidden;
  max-width: 50%;
  text-align: center;
`
