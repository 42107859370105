import styled, {css} from 'styled-components'

interface InputProps {
  customBorderRadius?: string
  inputWidth?: string
  haveFocus?: boolean
  hasError?: boolean
}

export const InputWrapper = styled.div<InputProps>`
  border-radius: ${({customBorderRadius}) =>
    customBorderRadius ? customBorderRadius : '8px'};
  border: 1px solid grey;
  width: ${({inputWidth}) => (inputWidth ? inputWidth : '100%')};
  box-sizing: border-box;
  padding: 6px 12px;
  height: 32px;
  border: 1px;
  border-color: ${({theme}) => theme.colors.background.gray};
  box-shadow: 0px 2px 3px -1px ${({theme}) => theme.colors.background.gray};
  background: ${({theme, hasError}) =>
    hasError ? theme.colors.input.error : theme.colors.input.background};
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({haveFocus}) =>
    haveFocus &&
    css`
      background-color: ${({theme}) => theme.colors.background.white};
    `}
`
export const Label = styled.label`
  &:hover {
    color: ${({theme}) => theme.colors.font.purpleMain};
  }
  cursor: pointer;
`

export const Input = styled.input`
  box-sizing: border-box;
  height: 33px;
  font-size: 12px;
  padding-left: 0.5rem;
  background-color: transparent;
`
