import {ReactElement, ReactNode} from 'react'
import {Container} from './styles'

interface PageContentParams {
  children?: ReactNode
}
export default function PageContainer({
  children,
}: PageContentParams): ReactElement {
  return <Container>{children}</Container>
}
