import React, {ReactElement} from 'react'
import {IconType} from 'react-icons/lib'

import {ButtonContainer} from './styles'

interface ButtonProps {
  width?: string
  heigth?: string
  align?: string
  backgroundColor?: string
  text?: string | number
  textColor?: string
  borderColor?: string
  icon?: IconType
  iconSize?: number
  iconColor?: string
  iconGap?: string
  onClick?: () => void
  disabled?: boolean
}

function Button({
  width,
  heigth = '36px',
  align,
  backgroundColor,
  text,
  textColor = '#fff',
  icon: IconComponent,
  iconSize,
  iconColor,
  iconGap,
  borderColor,
  onClick,
  disabled,
}: ButtonProps): ReactElement {
  return (
    <ButtonContainer
      disabled={disabled}
      width={width}
      heigth={heigth}
      align={align}
      gap={iconGap}
      backgroundColor={backgroundColor}
      textColor={textColor}
      onClick={onClick}
      borderColor={borderColor}>
      {IconComponent && <IconComponent fill={iconColor} size={iconSize} />}
      <span>{text}</span>
    </ButtonContainer>
  )
}

export default Button
