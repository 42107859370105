import styled from 'styled-components'

export const Container = styled.button<{expansion?: boolean}>`
  display: grid;
  grid-template-columns: 0.5fr 0.8fr 3fr 2fr 1fr 0.8fr 0.5fr;
  align-items: center;
  padding: 14px 27px;
  background-color: ${({expansion, theme}) =>
    expansion ? theme.colors.background.whiteGray : 'transparent'};
  border-top: 0.5px solid ${({theme}) => theme.colors.border.main};
  border-bottom: 0.5px solid ${({theme}) => theme.colors.border.main};
  &:hover {
    background-color: ${({theme}) => theme.colors.font.acentoUltraLight};
  }
`

export const Text = styled.span`
  text-align: left;
  font-weight: 300;
  font-size: 13px;
  color: ${({theme}) => theme.colors.font.greenTag};
`
export const DivTag = styled.div`
  margin: 0 10px;
`
