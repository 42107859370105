import styled from 'styled-components'

export const TableContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`

export const TableContent = styled.div`
  flex: 1;
`

export const TableOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`
