import styled, {css} from 'styled-components'

interface ContainerProps {
  routeActive: boolean
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 36px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.875rem;

  a {
    text-decoration: none;
  }
  ${({routeActive}) =>
    routeActive &&
    css`
      background-color: ${({theme}) => theme.colors.input.background};
    `}
  span {
    color: ${({theme}) => theme.colors.font.purpleMain};
  }

  &:hover {
    background-color: ${({theme}) => theme.colors.input.background};
  }
`
