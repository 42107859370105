import React, {
  ChangeEvent,
  FC,
  KeyboardEvent,
  MutableRefObject,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react'
import {ComponentPanel} from '~/components/Styled'
import {
  FaCheck,
  FaPlus,
  FaSpinner,
  FaTimes,
  FaTrash,
  FaUpload,
} from 'react-icons/fa'
import {HeaderButton} from '~/components/Styled/ComponentPanel/HeaderButton'
import styled, {useTheme} from 'styled-components'
import {HealthDot} from '~/components/HealthDot'
import Popover from '~/components/Popover'
import csvSample from '~/assets/images/csv_score_bonus.png'
import * as yup from 'yup'
import toast from 'react-hot-toast'
import {parse as csvParse} from 'papaparse'
import {getCustomersData} from '~/services/scoreManagerService'

const HeaderBox = styled.div`
  display: flex;
  padding: 8px;
  align-items: center;
  color: ${({theme}) => theme.colors.secondary.main};
`

const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ExempleBtn = styled.span`
  color: ${({theme}) => theme.colors.secondary.main};
  font-weight: bold;
  cursor: pointer;
`

const InputBox = styled.div`
  display: flex;
  flex: 1;
`

const InvisibleBtn = styled.button`
  background-color: transparent;
  margin: -10px;
  height: 100%;
  width: 100%;
`

const ClientTable = styled.table`
  text-align: center;
  margin: -10px;
  border-spacing: 0;
  & td {
    padding: 10px;
  }
  & tr:nth-child(even) {
    background-color: ${({theme}) => theme.colors.background.table.evenRow};
  }
  & tr:nth-child(even).invalid {
    background-color: ${({theme}) =>
      theme.colors.background.table.evenErrorRow};
  }
  & tr:nth-child(odd) {
    background-color: ${({theme}) => theme.colors.background.table.oddRow};
  }
  & tr:nth-child(odd).invalid {
    background-color: ${({theme}) => theme.colors.background.table.oddErrorRow};
  }
  & th {
    background-color: ${({theme}) => theme.colors.border.main};
    padding: 10px;
  }
  & tr td:last-child {
    padding: 0;
    padding-top: 5.5px;
    & svg {
      height: 18px;
      width: 18px;
    }
  }
`

const Spinner = styled(FaSpinner)`
  animation: spin 1s infinite linear;
  @keyframes spin {
    from {
      transform: scale(1) rotate(0deg);
    }
    to {
      transform: scale(1) rotate(360deg);
    }
  }
`

export const InputFile = styled.input`
  display: none;
`

const schema = yup.object({
  description: yup
    .string()
    .required('O campo Descrição é obrigatório')
    .min(5, 'A descrição precisa ter 5 ou mais caracteres')
    .transform((val) => val.trim()),
  value: yup
    .number()
    .required('O campo Pontuação é obrigatório')
    .min(1, 'O campo Pontuação não pode ser zero')
    .integer('A pontuação precisa ser inteiro')
    .transform((val) => Number(val)),
  customer: yup
    .string()
    .required('O campo E-mail é obrigatório')
    .email('O campo E-mail precisar ser um e-mail válido')
    .transform((val) => val.trim()),
})

type Customer = {
  customer: string
  value: number
  description: string
  valid?: boolean
}

interface CsvPanelParams {
  data: Customer[]
  onChange?: (customers: Customer[]) => void
}

export const CsvPanel: FC<CsvPanelParams> = ({
  data,
  onChange,
}): ReactElement => {
  const inputFileRef = useRef<HTMLInputElement>()
  const emailInputRef = useRef<HTMLInputElement>()
  const scoreInputRef = useRef<HTMLInputElement>()
  const descInputRef = useRef<HTMLInputElement>()
  const [isPopupVisible, setPopupVisibility] = useState(false)
  const [customers, setCustomers] = useState(data)
  const [addData, setAddData] = useState({
    customer: '',
    value: '',
    description: '',
  })
  const exampleBtnRef = useRef<HTMLAnchorElement>()
  const theme = useTheme()

  useEffect(() => {
    const notVerified = customers.filter(
      (customer) => customer.valid === undefined
    )
    if (notVerified.length > 0) {
      // void handleVerify(notVerified)
    }
    onChange?.(customers)
  }, [customers])

  const handleVerify = async (toVerify: Customer[]) => {
    const validatedCustomers = await getCustomersData(
      toVerify.map(({customer}) => customer)
    )
    const customersMap = new Map(
      validatedCustomers.map((customer) => [customer.email, customer])
    )
    setCustomers((prev) => {
      prev.forEach((customer) => {
        if (customersMap.has(customer.customer)) {
          customer.valid = customersMap.get(customer.customer).isValid
        }
      })
      return [...prev]
    })
  }

  const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files.length === 0) {
      return
    }
    const reader = new FileReader()
    reader.onload = async (body) => {
      try {
        const csvRows = csvParse<[string, string, string]>(
          body.target.result as string,
          {
            skipEmptyLines: true,
          }
        )
        const withError: Customer[] = []
        const toAdd: Customer[] = []
        for (const [customer, value, description] of csvRows.data) {
          try {
            const data = await schema.validate({
              customer,
              value,
              description,
            })
            toAdd.push(data)
          } catch (e) {
            withError.push({
              customer,
              value: Number(value),
              description,
              valid: false,
            })
          }
        }
        setCustomers((prev) => [...withError, ...toAdd, ...prev])
        await handleVerify(toAdd)
      } catch (e) {
        toast.error('Arquivo csv inválido!')
      }
    }
    reader.readAsText(e.target.files[0])
  }

  const handleRemove = (idx: number) => {
    setCustomers((prev) => {
      prev.splice(idx, 1)
      return [...prev]
    })
  }

  const handleAddData = async () => {
    try {
      const data = await schema.validate(addData)
      setCustomers((prev) => [data, ...prev])
      setAddData({
        customer: '',
        value: '',
        description: '',
      })
      const validatedCustomers = await getCustomersData([data.customer])
      setCustomers((prev) => {
        prev.forEach((customer) => {
          if (data.customer === customer.customer) {
            customer.valid = validatedCustomers[0].isValid
          }
        })
        return [...prev]
      })
    } catch (e: any) {
      for (const error of e.errors) {
        toast.error(error)
      }
      return
    }
  }

  const handleInputEnter = (
    ref: MutableRefObject<HTMLInputElement>,
    submit?: boolean
  ) => {
    return async (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key == 'Enter') {
        if (submit ?? false) {
          await handleAddData()
        }
        ref.current.focus()
      }
    }
  }

  return (
    <ComponentPanel.Container>
      <ComponentPanel.Header>
        <HeaderButton onClick={() => inputFileRef.current.click()}>
          <FaUpload />
        </HeaderButton>
        <InputFile
          ref={inputFileRef}
          type="file"
          accept="text/csv"
          onInput={handleFile}
          onClick={(e) => {
            e.currentTarget.value = null
          }}
        />
        <InputBox>
          <ComponentPanel.HeaderInput
            ref={emailInputRef}
            placeholder="E-mail do cliente"
            style={{maxWidth: 300}}
            value={addData.customer}
            onChange={(e) =>
              setAddData((data) => ({...data, customer: e.target.value}))
            }
            onKeyDown={handleInputEnter(scoreInputRef)}
          />
          <ComponentPanel.HeaderInput
            ref={scoreInputRef}
            placeholder="Pontuação"
            style={{maxWidth: 150}}
            value={addData.value}
            onChange={(e) =>
              setAddData((data) => ({...data, value: e.target.value}))
            }
            onKeyDown={(e) => {
              handleInputEnter(descInputRef)(e)
              if (
                !(
                  e.key === 'Backspace' ||
                  e.key === 'Delete' ||
                  e.key === 'ArrowLeft' ||
                  e.key === 'ArrowRight' ||
                  e.key === 'Tab'
                ) &&
                !e.key.match(/[0-9]+/)
              ) {
                e.preventDefault()
              }
            }}
          />
          <ComponentPanel.HeaderInput
            ref={descInputRef}
            placeholder="Descrição"
            value={addData.description}
            onChange={(e) =>
              setAddData((data) => ({...data, description: e.target.value}))
            }
            onKeyDown={handleInputEnter(emailInputRef, true)}
          />
          <HeaderButton onClick={handleAddData}>
            <FaPlus />
          </HeaderButton>
        </InputBox>
        <HeaderBox>
          {customers.length} cliente(s){' '}
          <HealthDot
            color={
              customers.length === 0 ||
              customers.some((customer) => customer.valid === undefined)
                ? 'disabled'
                : customers.some((customer) => !customer.valid)
                ? 'error'
                : 'success'
            }
          />
        </HeaderBox>
      </ComponentPanel.Header>
      <ComponentPanel.Body>
        {customers.length > 0 ? (
          <ClientTable>
            <thead>
              <tr>
                <th>#</th>
                <th>E-mail</th>
                <th>Pontos</th>
                <th>Descrição</th>
                <th>Status</th>
                <th>#</th>
              </tr>
            </thead>
            <tbody>
              {customers.map(({customer, description, value, valid}, idx) => (
                <tr
                  key={idx}
                  className={valid === false ? 'invalid' : undefined}>
                  <td>{idx + 1}</td>
                  <td>{customer}</td>
                  <td>{value}</td>
                  <td>{description}</td>
                  <td>
                    {valid === undefined && (
                      <Spinner style={{fill: theme.colors.font.dot.disabled}} />
                    )}
                    {valid === false && (
                      <FaTimes style={{fill: theme.colors.font.dot.error}} />
                    )}
                    {valid && (
                      <FaCheck style={{fill: theme.colors.font.dot.success}} />
                    )}
                  </td>
                  <td>
                    <InvisibleBtn aria-label="oi mundo">
                      <FaTrash
                        onClick={() => handleRemove(idx)}
                        style={{fill: theme.colors.font.dot.error}}
                      />
                    </InvisibleBtn>
                  </td>
                </tr>
              ))}
            </tbody>
          </ClientTable>
        ) : (
          <ComponentPanel.InfoBox>
            <span>
              Ainda não existem clientes selecionados, clique no botão&nbsp;
              <FaUpload />
              &nbsp;para carregar um arquivo&nbsp;
              <ExempleBtn
                onMouseEnter={() => setPopupVisibility(true)}
                onMouseLeave={() => setPopupVisibility(false)}
                ref={exampleBtnRef}>
                csv
              </ExempleBtn>
              &nbsp;ou adicione manualmente preenchendo os campos acima
            </span>
            <Popover
              isOpen={isPopupVisible}
              position="top right"
              anchor={exampleBtnRef}>
              <PopupContainer>
                <img alt="csv example" src={csvSample} />
                Crie uma lista com e-mail, pontuação e descrição e exporte como
                csv
              </PopupContainer>
            </Popover>
          </ComponentPanel.InfoBox>
        )}
      </ComponentPanel.Body>
    </ComponentPanel.Container>
  )
}
