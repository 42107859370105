import {ReactElement, useState} from 'react'
import {
  Container,
  WrapContainer,
  Footer,
  TitleFooter,
  MiddleFooter,
  TextMiddleFooter,
  FinalFooter,
  TextBalanceFooter,
  PriceTextFooter,
} from './styles'
import Details from '~/components/DetailsMovement/Details'
import HeaderMovement from '~/components/Movement/Header'
import CSV from '~/assets/images/csv.svg'
import PDF from '~/assets/images/pdf.svg'
import {
  keyStorageTotalPositive,
  keyStorageTotalNegative,
} from '~/utils/keystorage'
import {ButtonIcon} from '~/components/Movement/ContentHome/styles'
import {useMovementProvider} from '~/hooks/useMovementProvider'

export default function DetailsMovement(): ReactElement {
  const {yearMonth, dayMin, dayMax} = useMovementProvider()

  const [totalMovements, setTotalMovements] = useState(0)
  const totalPositiveStorage = sessionStorage.getItem(keyStorageTotalPositive)
  const totalNegativeStorage = sessionStorage.getItem(keyStorageTotalNegative)
  const total =
    totalPositiveStorage &&
    totalNegativeStorage &&
    (JSON.parse(totalPositiveStorage) - JSON.parse(totalNegativeStorage)) / 100

  return (
    <Container>
      <WrapContainer>
        <HeaderMovement totalMovements={totalMovements} />
        <Details setTotalMovement={setTotalMovements} />
      </WrapContainer>
      <Footer>
        <TitleFooter>Movimentações ({totalMovements})</TitleFooter>
        <MiddleFooter>
          <TextMiddleFooter>RELATÓRIO COMPLETO</TextMiddleFooter>
          <ButtonIcon
            href={`${process.env.REACT_APP_BASE_URL}/movements/detailing/pdf?startDate=${yearMonth}-${dayMin}&endDate=${yearMonth}-${dayMax}`}
            download
            target="blank">
            <img alt="icon pdf" width={28} height={28} src={PDF} />
          </ButtonIcon>
          <ButtonIcon
            href={`${process.env.REACT_APP_BASE_URL}/movements/detailing/csv?startDate=${yearMonth}-${dayMin}&endDate=${yearMonth}-${dayMax}`}
            download
            target="blank">
            <img alt="icon csv" width={28} height={28} src={CSV} />
          </ButtonIcon>
        </MiddleFooter>
        <FinalFooter>
          <TextBalanceFooter>Saldo das movimentações:</TextBalanceFooter>
          <PriceTextFooter>
            {Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(total)}
          </PriceTextFooter>
        </FinalFooter>
      </Footer>
    </Container>
  )
}
