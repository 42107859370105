import styled from 'styled-components'

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.input.background};
  box-shadow: 1px 1px 4px rgb(0 0 0 / 25%);
  border-radius: 9px;
  height: 32px;
  width: 32px;
  &:hover svg {
    fill: ${({theme}) => theme.colors.secondary.main};
  }
`

export const InputContainer = styled.div`
  display: flex;
  width: 200px;
  position: relative;
  justify-content: space-evenly;
  align-items: center;
  &:hover svg {
    fill: #610178;
  }
  input {
    padding-right: 30px;
  }
  svg {
    position: absolute;
    top: 7px;
    right: 5px;
    height: 18px;
    width: 18px;
    cursor: pointer;
  }
`
