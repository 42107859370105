import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface FilterProvider {
  key: string
  label?: string
}

export interface FiltersProvider {
  instances: FilterProvider
  methods: FilterProvider
  status: FilterProvider
}

interface FiltersState {
  defaultFilter: FiltersProvider
  currentFilter: FiltersProvider
}

const initialState: FiltersState = {
  defaultFilter: {
    instances: {
      key: 'all',
      label: 'Todas as instâncias',
    },
    methods: {
      key: 'all',
      label: 'Todos os métodos',
    },
    status: {
      key: 'all',
    },
  },
  currentFilter: {
    instances: {
      key: 'all',
      label: 'Todas as instâncias',
    },
    methods: {
      key: 'all',
      label: 'Todos os métodos',
    },
    status: {
      key: 'all',
    },
  },
}

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    set(state, action: PayloadAction<FiltersProvider>) {
      return {...state, currentFilter: action.payload}
    },
    clean(state) {
      return {...state, currentFilter: state.defaultFilter}
    },
  },
})

export const {set, clean} = filtersSlice.actions

export default filtersSlice.reducer
