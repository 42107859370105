import styled from 'styled-components'

interface RowProps {
  mobile?: number
  tablet?: number
  desktop?: number
}

function getWidthGrid(value) {
  if (value === undefined) {
    return
  }
  const width = (value / 12) * 100
  return `width: ${width}%;`
}
export const Container = styled.div<RowProps>`
  float: left;
  padding: 0.25rem;
  min-height: 1px;
  box-sizing: border-box;
  @media only screen and (max-width: 768px) {
    ${({mobile}) => mobile && getWidthGrid(mobile)}
  }
  @media only screen and (min-width: 768px) {
    ${({tablet}) => tablet && getWidthGrid(tablet)}
  }
  @media only screen and (min-width: 1180px) {
    ${({desktop}) => desktop && getWidthGrid(desktop)}
  }
`
