import React, {ReactElement, useState} from 'react'
import {useTheme} from 'styled-components'
import Button from '~/components/Button'
import {Content, WrapRules} from './styles'
import ScreenHeader from '~/components/ScreenHeader'
import {useHistory} from 'react-router-dom'
import defaultTheme from '~/assets/styles/themes/default'
import Paginator from '~/components/Table/Paginator'
import {useQuery} from 'react-query'
import PageContent from '~/components/PageContainer'
import {ManualScoreFilterBar} from '~/pages/ManualScore/Components/ManualScoreFilterBar'
import ManualScorePaginationRow from '~/pages/ManualScore/Components/ManualScorePaginationRow'
import {getManualScorePagination} from '~/services/scoreManagerService'

export default function ManualScore(): ReactElement {
  const {colors} = useTheme()
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState<{
    date?: Date
    search?: string
  }>({})
  const {data} = useQuery(
    [
      'manualScorePage',
      {
        page,
        ...filters,
      },
    ],
    () => {
      return getManualScorePagination({page, ...filters})
    }
  )

  return (
    <PageContent>
      <ScreenHeader title="Pontuação manual">
        <Button
          text="+ Pontuação Individual"
          onClick={() => history.push('/pontuacao-manual/novo')}
          backgroundColor={colors.font.purpleMain}
        />
        <Button
          text="+ Pontuação em Carga"
          onClick={() => history.push('/pontuacao-manual/lote')}
          backgroundColor={colors.font.purpleMain}
        />
      </ScreenHeader>
      <Content>
        <>
          <ManualScoreFilterBar
            count={data?.meta.pagination.count ?? 0}
            onChange={(newFilters) => setFilters(() => newFilters)}
          />
          <WrapRules>
            <span></span>
            <span>INCLUSÃO</span>
            <span>DESCRIÇÃO</span>
            <span>CLIENTE</span>
            <span>VALOR</span>
            <span>DATA DE EXPIRAÇÃO</span>
            <span></span>
          </WrapRules>
          {data?.data.map((it) => (
            <ManualScorePaginationRow key={it.id} manualScore={it} />
          ))}
          <Paginator
            totalItens={data?.meta.pagination.count}
            itensPerPage={data?.meta.pagination.limit}
            visiblePages={data?.meta.pagination.maxPage}
            hoverColor={defaultTheme.colors.font.purpleDark}
            selectedPageColor={defaultTheme.colors.font.purpleDark}
            selectedPage={page}
            setSelectedPage={(value) => {
              setPage(value + 1)
            }}
          />
        </>
      </Content>
    </PageContent>
  )
}
