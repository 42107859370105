import React, {Fragment, ReactElement, useState} from 'react'
import {useTheme} from 'styled-components'
import Button from '~/components/Button'
import ButtonRulesBonus from '~/components/ButtonBonusRules'
import {Content, WrapRules} from './styles'
import ScreenHeader from '~/components/ScreenHeader'
import {useHistory} from 'react-router-dom'
import defaultTheme from '~/assets/styles/themes/default'
import Paginator from '~/components/Table/Paginator'
import {
  getBonusRuleAnalysis,
  getBonusRulesPagination,
} from '~/services/bonusRulesService'
import {useQuery} from 'react-query'
import {StringListTruncated} from '~/components/StringListTruncated'
import {BonusRuleFilterBar} from '~/pages/BonusRules/Components/BonusRuleFilterBar'
import PageContent from '~/components/PageContainer'
import {unparse} from 'papaparse'
import {DateTime} from 'luxon'

export default function BonusRules(): ReactElement {
  const {colors} = useTheme()
  const status = {
    ongoing: 'Vigente',
    scheduled: 'Prevista',
    expired: 'Expirada',
  }

  const history = useHistory()
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState<{
    endDate?: Date
    startDate?: Date
    active?: boolean
    sellerType?: string
    searchField?: string
  }>({})
  const {data} = useQuery(
    [
      'bonusRulesPage',
      {
        page,
        ...filters,
      },
    ],
    () => {
      return getBonusRulesPagination({page, ...filters})
    }
  )

  const generateCSV = async () => {
    const now = DateTime.now()
    const csv = unparse(await getBonusRuleAnalysis())
    const csvBlob = new Blob([csv], {type: 'text/csv;charset=utf-8;'})
    const tempLink = document.createElement('a')
    tempLink.href = window.URL.createObjectURL(csvBlob)
    tempLink.setAttribute(
      'download',
      `bonus_rules_analysis_${now.toMillis()}.csv`
    )
    tempLink.click()
    tempLink.remove()
  }

  return (
    <PageContent>
      <ScreenHeader title="Regras de bonificação">
        <Button
          text="Gerar CSV de Análise"
          onClick={generateCSV}
          backgroundColor={colors.background.gray}
        />
        <Button
          text="+ Nova regra"
          onClick={() => history.push('/regras-bonificacao/novo')}
          backgroundColor={colors.font.purpleMain}
        />
      </ScreenHeader>
      <Content>
        <>
          <BonusRuleFilterBar
            count={data?.meta.pagination.count ?? 0}
            onChange={(newFilters) => setFilters(() => newFilters)}
          />
          <WrapRules>
            <span>NOME</span>
            <span>VERSÃO</span>
            <span>TIPO</span>
            <span>SELLER</span>
            <span>INÍCIO DA VIGÊNCIA</span>
            <span>FIM DA VIGÊNCIA </span>
            <span>VIGÊNCIA</span>
            <span>STATUS</span>
          </WrapRules>
          {data?.data.map((it) => (
            <Fragment key={it.id}>
              <ButtonRulesBonus
                id={it.id}
                name={it.name}
                seller={<StringListTruncated items={it.sellers} limit={3} />}
                version={it.version}
                type={it.sellerType.toUpperCase()}
                dateStart={it.startedAt?.toFormat("dd/LL/yyyy - HH'h':mm")}
                dateEnd={it.endedAt?.toFormat("dd/LL/yyyy - HH'h':mm")}
                validity={status[it.status]}
                status={it.activated ? 'Ativa' : 'Inativa'}
              />
            </Fragment>
          ))}
          <Paginator
            totalItens={data?.meta.pagination.count}
            itensPerPage={data?.meta.pagination.limit}
            visiblePages={data?.meta.pagination.maxPage}
            hoverColor={defaultTheme.colors.font.purpleDark}
            selectedPageColor={defaultTheme.colors.font.purpleDark}
            selectedPage={page}
            setSelectedPage={(value) => {
              setPage(value + 1)
            }}
          />
        </>
      </Content>
      {/*<ModalBonus closeModal={handleToggleModal} toggleModal={toggleModal} />*/}
    </PageContent>
  )
}
