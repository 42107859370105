import styled from 'styled-components'

interface ComponentParam {
  disabled?: boolean
}

export const PickerContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
`

export const HourContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  height: 40px;
`

export const MinuteContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  height: 40px;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const Button = styled.button`
  height: 22px;
  background-color: transparent;
  &[disabled] {
    cursor: auto;
  }
  & svg {
    fill: ${({theme, disabled}) =>
      disabled ? theme.colors.font.disabled : theme.colors.secondary.main};
  }
`

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 22px;
  justify-content: center;
  width: 55px;
  margin: auto;
`

export const Number = styled.span`
  font-size: 22px;
`

export const Type = styled.span`
  margin-top: 7px;
  margin-left: 5px;
  font-size: 14px;
`

export const Container = styled.div<ComponentParam>`
  padding: 5px;
  display: flex;
  flex-direction: column;
  &[disabled] svg {
    fill: ${({theme}) => theme.colors.font.disabled};
  }
  &[disabled] span,
  &[disabled] p {
    color: ${({theme}) => theme.colors.font.disabled};
  }
`
