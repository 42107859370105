import React, {ReactElement, ReactNode} from 'react'
import {HiMenu} from 'react-icons/hi'

import {useAppDispatch} from '~/app/hooks'
import {toggled} from '~/features/drawer/drawer-slice'

import {Container, TitleContainer} from './styles'

import mercuriusLogo from '~/assets/images/mercurius-logo.png'
import logo from '~/assets/images/logox460.png'

interface HeaderProps {
  backgroundColor: string
  menuColor: string
  titleAlign: string
  children: ReactNode
}

function Header({
  backgroundColor,
  menuColor,
  titleAlign,
  children,
}: HeaderProps): ReactElement {
  const dispatch = useAppDispatch()

  function handleToggleDrawerState() {
    dispatch(toggled())
  }

  return (
    <Container backgroundColor={backgroundColor}>
      <HiMenu
        size={24}
        style={{fill: menuColor, cursor: 'pointer'}}
        onClick={handleToggleDrawerState}
      />

      <TitleContainer align={titleAlign}>
        <img src={logo} height={50} alt="Logo" />
      </TitleContainer>

      {children}
    </Container>
  )
}
export default Header
