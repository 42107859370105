import react from 'react'

import {
  Container,
  Header,
  Information,
  InformationContainer,
  InformationLabel,
  InformationWrapper,
} from './styles'

interface SelectedClientProps {
  name: string
  email: string
  document: string
}

const SelectedClient: react.FC<SelectedClientProps> = ({
  document,
  email,
  name,
}) => {
  return (
    <Container>
      <Header>Cliente Selecionado</Header>
      <InformationWrapper>
        <InformationContainer>
          <InformationLabel>Nome</InformationLabel>
          <Information>{name}</Information>
        </InformationContainer>
        <InformationContainer>
          <InformationLabel>E-mail</InformationLabel>
          <Information>{email}</Information>
        </InformationContainer>
        <InformationContainer>
          <InformationLabel>CPF</InformationLabel>
          <Information>{document}</Information>
        </InformationContainer>
      </InformationWrapper>
    </Container>
  )
}
export default SelectedClient
