import styled from 'styled-components'

export const Container = styled.div<{checked: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 6px;
  background-color: ${({theme}) => theme.colors.input.background};
  & > svg {
    display: ${({checked}) => (checked ? 'flex' : 'none')};
    fill: ${({theme}) => theme.colors.font.purpleMain};
  }
`

export const InputCheckBox = styled.input`
  display: none;
`
