import styled from 'styled-components'

export const Container = styled.div`
  padding: 24px;
  width: 100%;
  height: 178px;
  display: flex;
  flex-direction: column;
  flex: 1;
`
export const ErrorHeader = styled.h3`
  color: ${({theme}) => theme.colors.font.main};
`
export const ErrorMessage = styled.span`
  color: ${({theme}) => theme.colors.font.placeHolder};
  margin-top: 20px;
`
export const Tip = styled.span`
  color: ${({theme}) => theme.colors.font.placeHolder};
`
export const TipsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 10%;
  margin-top: 20px;
`
