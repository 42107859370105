import {createSlice} from '@reduxjs/toolkit'

interface DrawerState {
  opened: boolean
}

const initialState: DrawerState = {
  opened: true,
}

const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    toggled(state) {
      return {...state, opened: !state.opened}
    },
  },
})

export const {toggled} = drawerSlice.actions

export default drawerSlice.reducer
