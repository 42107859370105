import styled from 'styled-components'
import {MdKeyboardArrowLeft} from 'react-icons/md'
import {FaRegEdit} from 'react-icons/fa'

interface ButtonEditProps {
  marginCustomLeft?: number
}

interface ModalEditProps {
  isOpen: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  background-color: ${({theme}) => theme.colors.background.white};
  span {
    font-weight: 400;
    font-size: 10px;
    text-transform: uppercase;
    color: ${({theme}) => theme.colors.font.main};
  }
  p {
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    color: ${({theme}) => theme.colors.font.main};
  }
`

export const ButtonEdit = styled.button<ButtonEditProps>`
  background-color: transparent;
  border: none;
  margin-left: ${({marginCustomLeft}) => marginCustomLeft ?? 32}px;
`

export const Back = styled(MdKeyboardArrowLeft)`
  fill: ${({theme}) => theme.colors.font.purpleMain};
  &:hover {
    fill: ${({theme}) => theme.colors.font.purpleDark};
  }
`

export const SectionLeft = styled.section`
  display: flex;
  align-items: center;
  gap: 56px;
  margin-right: auto;
  margin-left: 16px;
`

export const SectionRight = styled.section`
  display: flex;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`

export const WrapBack = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  h3 {
    font-size: 18px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.font.main};
  }
`

export const WrapHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const Content = styled.div`
  display: flex;
  gap: 24px;
  padding: 0px 24px;
  margin-bottom: 18px;
`

export const WrapContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`

export const ContainerDoubleCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  height: 90%;
`

export const WrapDoubleCard = styled.div`
  span {
    display: inline-block;
    font-weight: 100;
    font-size: 14px;
    color: ${({theme}) => theme.colors.font.dark};
    text-transform: uppercase;
    margin-bottom: 8px;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    color: ${({theme}) => theme.colors.font.dark};
  }
`

export const SKU = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  p {
    font-weight: 300;
    font-size: 14px;
    color: ${({theme}) => theme.colors.font.purpleMain};
  }
  a {
    font-weight: 700;
    text-decoration: none;
    font-size: 14px;
    color: ${({theme}) => theme.colors.font.purpleMain};
  }
  a:hover {
    color: ${({theme}) => theme.colors.font.purpleDark};
  }
`
export const ViewEdit = styled.div`
  position: relative;
`
export const ModalEdit = styled.div<ModalEditProps>`
  display: ${({isOpen}) => (isOpen ? 'flex' : 'none')} !important;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 300;
`

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0px !important;
  width: 100%;
  height: 100%;
  background-color: ${({theme}) => theme.colors.background.whiteActive};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  border-radius: 8px !important;
`

export const ButtonModal = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  text-align: left;
  padding: 8px 72px 16px 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: ${({theme}) => theme.colors.menu.activated};
  cursor: pointer;
  &:hover:enabled {
    background-color: ${({theme}) => theme.colors.menu.hover};
    color: ${({theme}) => theme.colors.secondary.main};
  }
  &:disabled {
    background-color: ${({theme}) => theme.colors.menu.disabled};
    color: ${({theme}) => theme.colors.font.menu.disabled};
    cursor: auto;
  }
`

export const ButtonModalEditThree = styled.button`
  border-bottom: 8px;
  background-color: rgba(242, 242, 242, 1);
  color: rgb(170, 170, 170);
  &:hover {
    background-color: rgba(242, 242, 242, 1);
    color: rgb(170, 170, 170);
  }
`

export const TitleButton = styled.span`
  text-align: right;
`

export const Edit = styled(FaRegEdit)`
  z-index: 1;
  fill: ${({theme}) => theme.colors.font.purpleMain};
  &:hover {
    fill: ${({theme}) => theme.colors.font.purpleDark};
  }
`
export const ContentImg = styled.article`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: flex-end;
  padding: 24px 40px;
  gap: 16px;
`
export const WrapImg = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

export const TitleImg = styled.span`
  font-weight: 600;
  font-size: 18px;
  color: ${({theme}) => theme.colors.font.main};
`

export const SubTitleImg = styled.p`
  font-weight: 300;
  font-size: 12px;
  color: ${({theme}) => theme.colors.font.dark};
  text-transform: uppercase;
`

export const ContentValidity = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  div {
    width: 2px;
    background-color: ${({theme}) => theme.colors.background.whiteGray};
    height: 60px;
    margin-right: 40px;
  }
`

export const Validity = styled(WrapImg)`
  gap: 3px;
  flex-grow: 1;
`

export const DescriptionValidity = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: ${({theme}) => theme.colors.font.main};
  text-transform: uppercase;
`

export const SubtitleValidity = styled.strong`
  font-weight: bold;
  font-size: 22px;
  color: ${({theme}) => theme.colors.font.main};
`
export const Restriction = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: 0px 24px;
  h3 {
    font-weight: 300;
    font-size: 12px;
    color: ${({theme}) => theme.colors.font.dark};
  }
  span {
    font-weight: 400;
    font-size: 14px;
    color: ${({theme}) => theme.colors.font.dark};
  }
`

export const ClusterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80%;
`

export const ClusterContent = styled.div`
  flex-grow: 1;
  width: 20%;
  min-width: 140px;
  padding: 10px;
`

export const ClusterBadge = styled.div<{color: string}>`
  text-align: center;
  & svg {
    font-size: 48px;
    fill: ${({color}) => color};
  }
`

export const ClusterValue = styled.p`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
`

export const ClusterLabel = styled.p`
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
`

export const Divider = styled.div`
  width: 1px;
  height: 80%;
  background-color: ${({theme}) => theme.colors.border.main};
  margin: auto 0;
`

export const BonusRow = styled.div`
  display: flex;
`
export const ValidityContainer = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const TextLabel = styled.p`
  text-transform: uppercase;
  padding: 5px 18px;
  font-size: 14px;
  color: ${({theme}) => theme.colors.font.dark};
`

export const TextValue = styled.p`
  text-transform: capitalize;
  font-size: 14px;
  padding: 2px 18px;
  color: ${({theme}) => theme.colors.font.dark};
`
