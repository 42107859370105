import {Fragment, ReactElement} from 'react'
import Chart from 'react-apexcharts'
import {Container, Legend, BigNumber, Text, Block} from './styles'
import {useIndicationYear} from './useIndicationYear'
import {Loading} from '~/components/Load'
import {useGoalIndication} from '../ModalGoalIndication/useGoalIndication'

function ChartLineAnual(): ReactElement {
  const {series, isLoading, options, isFetching} = useIndicationYear()
  const {isLoading: isLoadingGoal, isFetch} = useGoalIndication()
  const loading = isFetching || isLoadingGoal || isFetch

  if (isLoading) return <Loading />

  return (
    <Container>
      <Block>
        <Chart
          options={options}
          series={series}
          type="line"
          width={500}
          height={250}
        />
        <Legend>
          {series?.map((value) => (
            <Fragment key={`${value.name}ChartLineAnual`}>
              <BigNumber color={value.color}>
                {loading ? <Loading /> : value.total}
              </BigNumber>
              <Text>{value.name}</Text>
            </Fragment>
          ))}
        </Legend>
      </Block>
    </Container>
  )
}

export default ChartLineAnual
