import styled from 'styled-components'

type ContainerProps = {
  customHeight: number
  customWidth: number
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 15px;
  position: relative;
  width: ${({customWidth}) => customWidth ?? '100%'}%;
  gap: 20px;
  height: ${({customHeight}) => customHeight ?? 289}px;
  background-color: ${({theme}) => theme.colors.background.white};
`

export const ContentTitle = styled.div`
  height: 48px;
  border-bottom: 1px solid ${({theme}) => theme.colors.border.input.light};
`

export const Title = styled.h2`
  text-transform: uppercase;

  margin-left: 24px;
  position: absolute;
  top: 15px;
  color: ${({theme}) => theme.colors.font.main};
  font-size: 14px;
  font-weight: 300;
`

export const WrapContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: -5vh;
`
