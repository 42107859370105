import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  position: relative;
  height: 32px;
  justify-content: space-evenly;
  align-items: center;
  background-color: ${({theme}) => theme.colors.background.white};
  border-radius: 9px;
  &.collapsed {
    background-color: ${({theme}) => theme.colors.input.background};
    box-shadow: rgb(0 0 0 / 16%) 0 0 4px 0, rgb(0 0 0 / 23%) 0 3px 6px;
    width: 32px;
  }
  &.collapsed input {
    display: none;
  }
  & input {
    padding-right: 32px;
  }
  & input:focus + svg {
    fill: ${({theme}) => theme.colors.secondary.main};
  }
  &:hover svg {
    fill: ${({theme}) => theme.colors.secondary.main};
  }
  svg {
    position: absolute;
    top: 7px;
    right: 7px;
    height: 18px;
    width: 18px;
    cursor: pointer;
  }
`
