import styled, {css} from 'styled-components'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

type InputDateClicked = {
  isClickedInput: boolean
}

export const Container = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  button {
    &:hover {
      border: 1px solid ${({theme}) => theme.colors.primaryYellow.main};
    }
    &:focus {
      border: 1px solid ${({theme}) => theme.colors.primaryYellow.main};
    }
  }
`

export const WrapInputText = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-evenly;
  align-items: center;
`

export const InputTypeText = styled.input`
  display: flex;
  font-size: 14px;
  padding: 4px 2px 2px 8px;
  width: 100%;
  border-radius: 3px;
  background-color: transparent;
  border: 1px solid ${({theme}) => theme.colors.border.main};
  color: ${({theme}) => theme.colors.font.dark};
  &:hover {
    border: 1px solid ${({theme}) => theme.colors.primaryYellow.main};
  }
  &:focus {
    border: 1px solid ${({theme}) => theme.colors.primaryYellow.main};
  }
`

export const ToggleInput = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  outline: none;
  border: none;
  &:hover {
    border: 1px solid ${({theme}) => theme.colors.primaryYellow.main};
  }
  &:focus {
    border: 1px solid ${({theme}) => theme.colors.primaryYellow.main};
  }
`

export const ButtonCheck = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  outline: none;
  border: none;
  position: absolute;
  right: 0;
  margin-right: 10px;
  &:hover {
    border: 1px solid ${({theme}) => theme.colors.primaryYellow.main};
  }
  &:focus {
    border: 1px solid ${({theme}) => theme.colors.primaryYellow.main};
  }
`

export const InputTypeBox = styled.input`
  min-width: 15px;
  height: 15px;
  cursor: pointer;
  border: 3px solid ${({theme}) => theme.colors.primaryYellow.main};
  &:hover {
    border: 3px solid ${({theme}) => theme.colors.primaryYellow.main};
  }
`

export const WrapDate = styled.div<InputDateClicked>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${({isClickedInput}) => (isClickedInput ? 142 : 45)}px;
  border-radius: 3px;
  height: 25px;
  position: relative;
  border: 1px solid ${({theme}) => theme.colors.border.main};
  &:hover {
    border: 0.3px solid ${({theme}) => theme.colors.primaryYellow.main};
  }
  &:focus {
    border: 1px solid ${({theme}) => theme.colors.primaryYellow.main};
  }
`

export const InputTyDate = styled(DatePicker)<InputDateClicked>`
  display: flex;
  align-items: center;
  position: absolute;
  width: ${({isClickedInput}) => (isClickedInput ? 140 : 42)}px;
  height: 26px;
  margin-bottom: 3px;
  margin-left: 3px;
  z-index: 3;
  outline: none;
  bottom: -10px;
  background-color: transparent;
  cursor: pointer;
  font-weight: 400;
  font-size: 13px;
  color: ${({theme}) => theme.colors.font.dark};
`

export const WrapImageDate = styled.div<InputDateClicked>`
  display: flex;
  position: absolute;
  justify-content: flex-end;
  align-items: center;
  right: 10px;
  min-width: 30px;
  height: 23px;
  background-color: transparent;
  border-left: none;
  ${({isClickedInput}) =>
    isClickedInput &&
    css`
      border-left: 1px solid ${({theme}) => theme.colors.primaryYellow.main};
    `}
`

export const ImgDate = styled.img`
  min-width: 20px;
  height: 20px;
  border: none;
  background-color: transparent;
`
