import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  top: 0;
  margin-top: 32px;
  width: 100%;
  align-items: flex-start;
  flex: 2;
`
export const Header = styled.h3`
  margin-left: 24px;
  font-size: 14px;
`
export const MessageContainer = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
`
export const Message = styled.span`
  font-size: 0.25%;
  color: ${({theme}) => theme.colors.font.placeHolder};
`
export const MessageHighLight = styled.span`
  font-size: 0.25%;
  color: ${({theme}) => theme.colors.font.purpleDark};
`
