import React, {ReactElement} from 'react'
import {IoClose} from 'react-icons/io5'
import {useTheme} from 'styled-components'
import {
  Container,
  ErrorMsg,
  FooterRight,
  FooterLeft,
  HeaderButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Overlay,
  Title,
} from './styles'
import ReactDOM from 'react-dom'

type WideModalProps = {
  children?: JSX.Element
  title?: string
  isOpened: boolean
  footerRight?: React.ReactNode
  footerLeft?: React.ReactNode
  onPressClose?: () => void
  error?: string
}

const WideModal: React.FC<WideModalProps> = ({
  isOpened,
  footerRight,
  footerLeft,
  title,
  children,
  onPressClose,
  error,
}): ReactElement => {
  const {colors} = useTheme()
  return (
    <>
      {isOpened && (
        <Overlay>
          <Container>
            <ModalHeader>
              <Title>{title}</Title>
              <HeaderButton onClick={() => onPressClose()}>
                <IoClose size={26} fill={colors.font.purpleDark} />
              </HeaderButton>
            </ModalHeader>
            <ModalBody>{children}</ModalBody>
            <ModalFooter>
              {footerLeft && <FooterLeft>{footerLeft}</FooterLeft>}
              {error && <ErrorMsg>{error}</ErrorMsg>}
              <FooterRight>{footerRight}</FooterRight>
            </ModalFooter>
          </Container>
        </Overlay>
      )}
    </>
  )
}

export default WideModal
