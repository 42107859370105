import React, {ReactElement, useState} from 'react'
import {
  BonusRow,
  ButtonEdit,
  ButtonModal,
  ClusterBadge,
  ClusterContainer,
  ClusterContent,
  ClusterLabel,
  ClusterValue,
  ContainerDoubleCard,
  Content,
  ContentModal,
  DescriptionValidity,
  Divider,
  ModalEdit,
  SectionLeft,
  SectionRight,
  SubtitleValidity,
  TextLabel,
  TextValue,
  Validity,
  ValidityContainer,
  ViewEdit,
  WrapDoubleCard,
  WrapHeader,
} from './styles'
import {match as ParamMatch, useHistory} from 'react-router-dom'
import Badge from '~/components/Badge'
import {FaAward as Award} from 'react-icons/fa'
import {IoEllipsisVerticalSharp} from 'react-icons/io5'
import {useTheme} from 'styled-components'
import {getBonusRule} from '~/services/bonusRulesService'
import {useQuery} from 'react-query'
import {Loading} from '~/components/Load'
import Column from '~/components/Column'
import Row from '~/components/Row'
import Card from '~/components/Card'
import PageContainer from '~/components/PageContainer'
import WhiteHeader from '~/components/WhiteHeader'
import {SkuPanel} from '~/components/SkuPanel'
import {DisableBonusRules} from '~/pages/BonusRulesDetails/Components/DisableBonusRules'
import {EnableBonusRules} from '~/pages/BonusRulesDetails/Components/EnableBonusRules'
import toast from 'react-hot-toast'
import {BonusRule} from '~/types'

interface BonusRulesDetailsParams {
  match: ParamMatch<{
    id: string
  }>
}

export default function BonusRulesDetails({
  match,
}: BonusRulesDetailsParams): ReactElement {
  const theme = useTheme()
  const history = useHistory()
  const [showEditMenu, setShowEditMenu] = useState(false)
  const [showDisableModal, setShowDisableModal] = useState(false)
  const [showEnableModal, setShowEnableModal] = useState(false)
  const {isLoading, data, error} = useQuery<BonusRule, Error>(
    ['bonusRules', match.params.id],
    () => {
      return getBonusRule(match.params.id)
    }
  )
  if (isLoading) {
    return <Loading />
  }
  if (error !== null) {
    toast.error(error.message)
    history.push('/regras-bonificacao')
    return
  }
  const sellerType = new Map([
    ['lb', 'Linha Branca'],
    ['mkt', 'Marketplace'],
  ])
  const statuses = new Map([
    ['ongoing', 'Vigente'],
    ['scheduled', 'Prevista'],
    ['expired', 'Expirada'],
  ])
  const aditions = new Map([
    ['discount', 'Desconto'],
    ['shipping', 'Frete'],
  ])
  return (
    <PageContainer>
      <WhiteHeader backTo="/regras-bonificacao">
        <SectionLeft>
          <WrapHeader>
            <h4>{data.name}</h4>
          </WrapHeader>
          <WrapHeader>
            <span>versão</span>
            <p>{data.version}</p>
          </WrapHeader>
          <WrapHeader>
            <span>Tipo de venda</span>
            <p>{sellerType.get(data.sellerType)}</p>
          </WrapHeader>
        </SectionLeft>
        <SectionRight>
          <div>
            <Badge
              fontSize={18}
              type={data.status === 'ongoing' ? 'success' : 'disabled'}>
              {statuses.get(data.status)}
            </Badge>
            <Badge fontSize={18} type={data.activated ? 'success' : 'disabled'}>
              {data.activated ? 'Ativa' : 'Inativa'}
            </Badge>
            {data.isLegacy && (
              <Badge fontSize={18} type="error">
                Legado
              </Badge>
            )}
          </div>
          <ViewEdit>
            <ButtonEdit onClick={() => setShowEditMenu((value) => !value)}>
              <IoEllipsisVerticalSharp
                size={20}
                fill={theme.colors.font.purpleMain}
              />
            </ButtonEdit>
            <ModalEdit isOpen={showEditMenu}>
              <ContentModal>
                <ButtonModal
                  disabled={data.isLegacy}
                  onClick={() => {
                    data.activated
                      ? setShowDisableModal(true)
                      : setShowEnableModal(true)
                    setShowEditMenu(false)
                  }}>
                  {data.activated ? 'Inativar' : 'Ativar'}
                </ButtonModal>
                <ButtonModal
                  disabled={data.isLegacy}
                  onClick={() =>
                    history.push(
                      `/regras-bonificacao/editar/${match.params.id}`
                    )
                  }>
                  Editar
                </ButtonModal>
                <ButtonModal disabled={true}>Histórico</ButtonModal>
              </ContentModal>
            </ModalEdit>
          </ViewEdit>
        </SectionRight>
      </WhiteHeader>
      <Row>
        <Column desktop={4} tablet={12} mobile={12}>
          <Content>
            <Card header="SEGMENTAÇÃO">
              <ContainerDoubleCard>
                <WrapDoubleCard>
                  <span>Sellers</span>
                  <p>{data.sellers.map(({name}) => name).join(', ')}</p>
                </WrapDoubleCard>
                <WrapDoubleCard>
                  <span>Categorias</span>
                  <p>{data.categories.map(({name}) => name).join(', ')}</p>
                </WrapDoubleCard>
              </ContainerDoubleCard>
            </Card>
          </Content>
        </Column>
        <Column desktop={8} tablet={12} mobile={12}>
          <Content>
            <Card header="BONIFICAÇÕES">
              <BonusRow>
                <ClusterContainer>
                  {data.clusters.map((cluster) => (
                    <ClusterContent key={cluster.id}>
                      <ClusterBadge color={cluster.color}>
                        <Award />
                      </ClusterBadge>
                      <ClusterValue>{cluster.value.toFixed(1)}%</ClusterValue>
                      <ClusterLabel>{cluster.name}</ClusterLabel>
                    </ClusterContent>
                  ))}
                </ClusterContainer>
                <ValidityContainer>
                  <Divider />
                  <Validity>
                    <DescriptionValidity>Validade</DescriptionValidity>
                    <SubtitleValidity>{data.validityDays}</SubtitleValidity>
                    <DescriptionValidity>Dias</DescriptionValidity>
                  </Validity>
                </ValidityContainer>
              </BonusRow>
            </Card>
          </Content>
        </Column>
        <Column desktop={8} tablet={12} mobile={12}>
          <Content>
            <Card header="LIMITES & RESTRIÇÕES">
              <TextLabel>considerar para bonificação</TextLabel>
              <TextValue>
                {data.additions.length === 0
                  ? '-'
                  : data.additions
                      .map((addition) => aditions.get(addition))
                      .join(', ')}
              </TextValue>
            </Card>
          </Content>
        </Column>
        <Column desktop={12} tablet={12} mobile={12}>
          <Content>
            <Card header="SKUs">
              <SkuPanel
                readonly
                value={data.skus.map((sku) => {
                  return {
                    name: sku,
                    status: 'valid',
                  }
                })}
              />
            </Card>
          </Content>
        </Column>
      </Row>
      <DisableBonusRules
        isOpen={showDisableModal}
        onClose={() => setShowDisableModal(false)}
        bonusId={match.params.id}
      />
      <EnableBonusRules
        isOpen={showEnableModal}
        onClose={() => setShowEnableModal(false)}
        bonusId={match.params.id}
        name={data.name}
      />
    </PageContainer>
  )
}
