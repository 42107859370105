import styled from 'styled-components'

export const HeaderButton = styled.button<{
  variant?: 'main' | 'green'
}>`
  background-color: ${({theme, variant}) =>
    theme.colors.secondary[variant ?? 'main']};
  height: 30px;
  width: 80px;
  & svg {
    fill: ${({theme}) => theme.colors.background.white};
  }
`
