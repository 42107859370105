import styled from 'styled-components'

interface ContentProps {
  drawerOpened: boolean
}

interface UserContentProps {
  backgroundColor: string
  textColor: string
}

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 100vh;
`

export const Content = styled.div<ContentProps>`
  flex-grow: 1;
  width: 100%;
  display: flex;
  .main-content {
    display: flex;
    background-color: #f7f7f7;
    width: 100%;
  }
`

export const UserContainer = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
`

export const UserContent = styled.div<UserContentProps>`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 100%;
  background: ${({backgroundColor}) => backgroundColor};

  span {
    color: ${({textColor}) => textColor};
    font-size: 1.125rem;
    font-weight: 700;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  > * {
    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }
`

export const Footer = styled.div`
  position: absolute;
  width: 217px;
  height: 50px;
  bottom: 0;
  padding: 0 9px;
`

export const WrapFooter = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  border-top: 1px solid ${({theme}) => theme.colors.border.main};
  justify-content: space-between;
`

export const Cite = styled.span`
  font-family: 'Segoe UI Normal', 'Segoe UI', sans-serif;
  font-weight: 400;
  color: ${({theme}) => theme.colors.font.main};
  font-size: 13px;
  line-height: 17px;
  margin-bottom: 3%;
`
