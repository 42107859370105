import {ReactElement} from 'react'
import ColumnChart from 'react-apexcharts'

interface BarChartProps {
  options: Options
  series: Series
  width?: number | string
  height?: number | string
}
type Options = {
  chart: Chart
  xaxis: Xaxis
}
type Chart = {
  id: string
  width?: string
}
type Xaxis = {
  categories: number[] | string[]
}
type Series = {
  name: string
  data: number[]
}[]

export default function BarChart({
  options,
  series,
  width,
  height,
}: BarChartProps): ReactElement {
  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <ColumnChart
            options={options}
            series={series}
            type="bar"
            width={width}
            height={height}
          />
        </div>
      </div>
    </div>
  )
}
