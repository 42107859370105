import {FC, useEffect, useState} from 'react'
import {PageHeader} from '~/components/PageHeader'
import {InputDateRangePicker} from '~/components/InputDateRangePicker'
import SelectInput from '~/components/SelectInput'
import {GoSearch} from 'react-icons/go'
import {FaFilter} from 'react-icons/fa'
import {Button} from './style'
import {CollapsedInput} from '~/components/CollapsedInput'

interface BonusRuleFilterBarProps {
  count: number
  onChange: (values: {
    startDate?: Date
    endDate?: Date
    searchField?: string
    active?: boolean
    sellerType?: string
  }) => void
}

export const BonusRuleFilterBar: FC<BonusRuleFilterBarProps> = ({
  count,
  onChange,
}) => {
  const statusOptions = [
    {
      label: 'Ativas e Inativas',
      value: '',
    },
    {
      label: 'Somente Ativas',
      value: '1',
    },
    {
      label: 'Somente Inativas',
      value: '0',
    },
  ]
  const typesOptions = [
    {
      label: 'Todos os Tipos',
      value: '',
    },
    {
      label: 'Somente Linha Branca',
      value: 'lb',
    },
    {
      label: 'Somente Marketplace',
      value: 'mkt',
    },
  ]
  const [dates, setDates] = useState<[Date, Date]>(undefined)
  const [searchString, setSearchString] = useState('')
  const [statusOption, setStatusOption] = useState(statusOptions[0])
  const [typeOption, setTypeOption] = useState(typesOptions[0])

  useEffect(() => {
    const data = {
      startDate: dates?.[0] ?? undefined,
      endDate: dates?.[1] ?? undefined,
      searchField: searchString !== '' ? searchString : undefined,
      sellerType: typeOption.value !== '' ? typeOption.value : undefined,
      active:
        statusOption.value !== '' ? statusOption.value === '1' : undefined,
    }
    onChange(data)
  }, [dates, searchString, statusOption, typeOption])

  return (
    <PageHeader title={`Regras de produtos: ${count}`}>
      <CollapsedInput
        icon={GoSearch}
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
      />
      <SelectInput
        options={typesOptions}
        value={typeOption}
        onChange={(val) => setTypeOption(val)}
      />
      <InputDateRangePicker
        title="Selecione o período que deve conter a data de início da vigência"
        position="bottom right"
        value={dates}
        onChange={(dates) => setDates(dates)}
        collapseInput
      />
      <SelectInput
        options={statusOptions}
        value={statusOption}
        onChange={(val) => setStatusOption(val)}
      />
      <Button
        onClick={() => {
          setDates(undefined)
          setSearchString('')
          setStatusOption(statusOptions[0])
          setTypeOption(typesOptions[0])
        }}>
        <FaFilter />
      </Button>
    </PageHeader>
  )
}
