import React, {FC, ReactElement, useEffect, useRef, useState} from 'react'
import {
  AddButton,
  AddContainer,
  BadgeContainer,
  CounterBox,
  CsvInfo,
  InfoBoxP,
  InfoContainer,
  InputFile,
  InputText,
  LoadContainer,
  RemoveButton,
  SearchContainer,
  SelectFilter,
} from './styles'
import {FaPlus, FaSearch, FaUpload} from 'react-icons/fa'
import {IoMdClose} from 'react-icons/io'
import Badge from '~/components/Badge'
import Popover from '~/components/Popover'
import csvSample from '../../assets/images/csv_bonus_rules_sample.png'
import toast from 'react-hot-toast'
import {validateSku} from '~/services/bonusRulesService'
import {createPipeline} from '~/utils'
import {parse as csvParse} from 'papaparse'
import {ComponentPanel} from '~/components/Styled'
import {HeaderButton} from '~/components/Styled/ComponentPanel/HeaderButton'
import {HealthDot} from '~/components/HealthDot'

type SkuStatus = 'processing' | 'valid' | 'invalid' | 'duplicated'

interface SkuItem {
  name: string
  status: SkuStatus
}

interface SkuPanelProps {
  readonly?: boolean
  value?: SkuItem[]
  onChange?: (skus: SkuItem[]) => void
}
export const SkuPanel: FC<SkuPanelProps> = ({
  readonly,
  value,
  onChange,
}): ReactElement => {
  const didMountRef = useRef(false)
  const didUpdate = useRef(false)
  const fileRef = useRef<HTMLInputElement>()
  const [csvInfoIsOpen, setCsvInfoIsOpen] = useState(false)
  const [skuToAdd, setSkuToAdd] = useState('')
  const [statusFilter, setStatusFilter] = useState<SkuStatus | ''>('')
  const [nameFilter, setNameFilter] = useState('')
  const [skuList, setSkuList] = useState<SkuItem[]>([])

  useEffect(() => {
    if (didMountRef.current && !didUpdate.current) {
      onChange?.(skuList)
    }
    didMountRef.current = true
    didUpdate.current = false
  }, [skuList])

  useEffect(() => {
    if (value && value !== skuList) {
      didUpdate.current = true
      setSkuList(value)
    }
  }, [value, skuList])

  const fileHandler = (e) => {
    const files = e.target.files
    const reader = new FileReader()
    reader.onload = async (body) => {
      try {
        const parsedCsv = csvParse<[string]>(body.target.result as string, {
          skipEmptyLines: true,
        })
        const importedSkus = parsedCsv.data.map(
          ([skuName]): SkuItem => ({
            name: skuName,
            status: 'processing',
          })
        )
        setSkuList((skus) => {
          const duplicatesMap = new Map()
          const newSkuList = [...skus, ...importedSkus]
          newSkuList.forEach((sku) => {
            if (duplicatesMap.has(sku.name)) {
              const duplicated = duplicatesMap.get(sku.name)
              if (duplicated.length === 1) {
                duplicated[0].status = 'duplicated'
              }
              sku.status = 'duplicated'
              duplicated.push(sku)
            } else {
              duplicatesMap.set(sku.name, [sku])
            }
          })
          void validateSkuBatch(
            newSkuList
              .filter((sku) => sku.status === 'processing')
              .map((sku) => sku.name)
          )
          return newSkuList
        })
      } catch (e) {
        toast.error('Arquivo csv inválido!')
      }
    }
    reader.readAsText(files[0])
  }

  const addSingleSku = () => {
    if (skuToAdd.length <= 2) {
      return toast.error('Adicione 3 ou mais caracteres')
    }
    const duplicates: number[] = []
    skuList.forEach((sku, pos) => {
      if (sku.name === skuToAdd) {
        sku.status = 'duplicated'
        duplicates.push(pos)
      }
    })
    if (duplicates.length > 0) {
      setSkuList((skus) => [...skus, {name: skuToAdd, status: 'duplicated'}])
      return setSkuToAdd('')
    }
    setSkuList((skus) => {
      void validateSingleSku(skuToAdd, skus.length)
      return [...skus, {name: skuToAdd, status: 'processing'}]
    })
    setSkuToAdd('')
  }

  const validateSkuBatch = async (skus: string[]) => {
    const skuValidation = await validateSku(skus)
    setSkuList((skus) => {
      skuValidation.forEach((skuValidation) => {
        skus.forEach((sku) => {
          if (sku.name === skuValidation.name) {
            sku.status = skuValidation.isValid ? 'valid' : 'invalid'
          }
        })
      })
      return [...skus]
    })
  }

  const validateSingleSku = async (skuName: string, skuPostion: number) => {
    const [skuValidation] = await validateSku([skuName])
    setSkuList((skus) => {
      skus[skuPostion] = {
        name: skuValidation.name,
        status: skuValidation.isValid ? 'valid' : 'invalid',
      }
      return [...skus]
    })
  }

  const handleRemove = async (skuToRemove: SkuItem, pos: number) => {
    setSkuList((skus) => {
      skus.splice(pos, 1)
      const newList = [...skus]
      if (skuToRemove.status === 'duplicated') {
        const duplicates: Array<[number, SkuItem]> = []
        newList.forEach((skuFromList, pos) => {
          if (skuFromList.name === skuToRemove.name) {
            duplicates.push([pos, skuFromList])
          }
        })
        if (duplicates.length === 1) {
          const [skuPos, skuToValidate] = duplicates[0]
          skuToValidate.status = 'processing'
          validateSingleSku(skuToValidate.name, skuPos)
        }
      }
      return newList
    })
  }

  const pipelineFilter = createPipeline(
    (skus) => {
      return skus.map((sku, pos) => {
        return {...sku, pos}
      })
    },
    (skus) => {
      if (statusFilter === '') {
        return skus
      }
      return skus.filter((sku) => sku.status === statusFilter)
    },
    (skus) => {
      if (nameFilter === '') {
        return skus
      }
      const reg = new RegExp(`.*${nameFilter}.*`, 'i')
      return skus.filter((sku) => reg.test(sku.name))
    }
  )

  return (
    <ComponentPanel.Container>
      <ComponentPanel.Header>
        {!readonly && (
          <>
            <LoadContainer>
              <HeaderButton onClick={() => fileRef.current.click()}>
                <FaUpload />
              </HeaderButton>
              <InputFile
                ref={fileRef}
                type="file"
                accept="text/csv"
                onChange={fileHandler}
              />
            </LoadContainer>
            <AddContainer>
              <InputText
                placeholder="Adicionar novo sku"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') addSingleSku()
                }}
                value={skuToAdd}
                onChange={(e) => setSkuToAdd(e.target.value)}
              />
              <AddButton onClick={() => addSingleSku()}>
                <FaPlus />
              </AddButton>
            </AddContainer>
          </>
        )}
        <SearchContainer>
          <InputText
            placeholder="Procurar"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
          />
          <FaSearch />
        </SearchContainer>
        {!readonly && (
          <SelectFilter
            onChange={(e) => {
              setStatusFilter(e.target.value as SkuStatus)
            }}>
            <option value="">Todos</option>
            <option value="processing">Não processados</option>
            <option value="valid">Válidos</option>
            <option value="invalid">Inválidos</option>
            <option value="duplicated">Duplicados</option>
          </SelectFilter>
        )}
        <CounterBox>{skuList.length} skus</CounterBox>
        {!readonly && (
          <HealthDot
            color={
              skuList.length === 0
                ? 'disabled'
                : !skuList.some((sku) => sku.status !== 'valid')
                ? 'success'
                : 'error'
            }
          />
        )}
      </ComponentPanel.Header>
      <ComponentPanel.Body>
        {readonly || skuList.length ? (
          <BadgeContainer>
            {pipelineFilter(skuList).map((sku, pos) => {
              return (
                <Badge
                  key={pos}
                  type={
                    sku.status === 'processing'
                      ? 'disabled'
                      : sku.status === 'duplicated'
                      ? 'warning'
                      : sku.status === 'valid'
                      ? 'success'
                      : 'error'
                  }>
                  {sku.name}
                  {!readonly && (
                    <RemoveButton onClick={() => handleRemove(sku, sku.pos)}>
                      <IoMdClose />
                    </RemoveButton>
                  )}
                </Badge>
              )
            })}
          </BadgeContainer>
        ) : (
          <ComponentPanel.InfoBox>
            <InfoBoxP>
              Ainda não existem SKUs selecionados, clique no botão
              <FaUpload />
              para carregar um arquivo
              <CsvInfo
                onMouseEnter={() => setCsvInfoIsOpen(true)}
                onMouseLeave={() => setCsvInfoIsOpen(false)}>
                csv
                <Popover position="top right" isOpen={csvInfoIsOpen}>
                  <InfoContainer>
                    <img alt="csv example" src={csvSample} />
                    Crie uma lista de sku em uma coluna e exporte como csv
                  </InfoContainer>
                </Popover>
              </CsvInfo>
            </InfoBoxP>
            <InfoBoxP>
              ou adicione manualmente um item pelo campo de &ldquo;adicionar
              novo sku&ldquo;
            </InfoBoxP>
          </ComponentPanel.InfoBox>
        )}
      </ComponentPanel.Body>
    </ComponentPanel.Container>
  )
}
