import {InputHTMLAttributes, ReactElement, useState} from 'react'
import {
  Container,
  Label,
  Input,
  WrapButtonIcon,
  Button,
  EyeOpen,
  EyeClose,
} from './styles'

interface InputCommonProps extends InputHTMLAttributes<HTMLInputElement> {
  textLabeL: string
  isPassword?: boolean
}

export default function InputLogin({
  textLabeL,
  isPassword = false,
  ...rest
}: InputCommonProps): ReactElement {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <Container>
      <Label htmlFor={textLabeL}>{textLabeL}</Label>
      {isPassword ? (
        <WrapButtonIcon>
          <Input
            type={showPassword ? 'text' : 'password'}
            id={textLabeL}
            {...rest}
          />
          <Button
            type="button"
            onClick={() => setShowPassword((previous) => !previous)}>
            {showPassword ? <EyeOpen size={20} /> : <EyeClose size={20} />}
          </Button>
        </WrapButtonIcon>
      ) : (
        <Input id={textLabeL} {...rest} />
      )}
    </Container>
  )
}
