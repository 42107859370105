import React, {
  ChangeEvent,
  InputHTMLAttributes,
  ReactElement,
  useRef,
  useState,
} from 'react'
import {Container, FormContainer, SearchButton, SearchInput} from './styles'
import {useTheme} from 'styled-components'
import {FaSearch} from 'react-icons/fa'

interface SearchWithSelector extends InputHTMLAttributes<HTMLInputElement> {
  searchPlaceHolder: string
  onPress: () => void
}

const SearchWithSearchBtn: React.FC<SearchWithSelector> = ({
  searchPlaceHolder,
  onPress,
  ...rest
}): ReactElement => {
  const {colors} = useTheme()
  const [isFocus, setIsFocus] = useState(false)

  const handleFocus = () => setIsFocus(true)

  const handleBlur = () => setIsFocus(false)

  return (
    <Container>
      <FormContainer>
        <SearchInput
          placeholder={searchPlaceHolder}
          haveFocus={isFocus}
          onBlur={handleBlur}
          onFocus={handleFocus}
          {...rest}
        />
        <SearchButton onClick={onPress}>
          <FaSearch size={20} fill={colors.font.purpleLight} />
        </SearchButton>
      </FormContainer>
    </Container>
  )
}

export default SearchWithSearchBtn
