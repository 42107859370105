import {FC, ReactElement} from 'react'
import {
  Container,
  SvgImage,
} from '~/pages/CustomerProfile/CustomerNotFound/style'
import errorImage from '../../../assets/images/error_404.svg'

type CustomerNotFoundParams = {
  email: string
}

export const CustomerNotFound: FC<CustomerNotFoundParams> = ({
  email,
}): ReactElement => {
  return (
    <Container>
      <h2>Ops! Não encontramos o cliente com o e-mail {email}.</h2>
      <h2>Por favor, verifique o e-mail e tente novamente.</h2>
      <SvgImage src={errorImage} alt="error 404 image" />
    </Container>
  )
}
