import styled, {css} from 'styled-components'

interface ShowModal {
  opened: boolean
  customHeight?: string
  customWidth?: string
  positionTop?: number
  positionRight?: string
}

interface ResetContent {
  havePadding: boolean
}

interface InputTitle {
  isUppercase: boolean
}

export const Background = styled.div<ShowModal>`
  display: ${({opened}) => (opened ? 'flex' : 'none')};
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.56);
  height: 100vh;
  width: 100vw;
  z-index: 100;
`
export const Modal = styled.div<ShowModal>`
  position: fixed;
  margin-top: ${({positionTop}) => positionTop ?? 30}vh;
  right: ${({positionRight}) => positionRight ?? '30vw'};
  display: ${({opened}) => (opened ? 'flex' : 'none')};
  top: 0;
  height: ${({customHeight}) => customHeight ?? '310px'};
  width: ${({customWidth}) => customWidth ?? '590px'};
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({theme}) => theme.colors.background.white};
  border-radius: 12px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  z-index: 400;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 17px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: ${({theme}) => theme.colors.background.whiteGray};
  border-bottom: 1px solid ${({theme}) => theme.colors.border.modal};
`

export const Title = styled.span<InputTitle>`
  font-family: Segoe UI Normal, Segoe UI, sans-serif;
  font-size: 14px;
  color: ${({theme}) => theme.colors.font.main};
  font-weight: 400;
  text-transform: ${({isUppercase}) => (isUppercase ? 'uppercase' : 'none')};
`

export const Content = styled.section<ResetContent>`
  display: flex;
  height: 100%;
  position: relative;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 33px;
  ${({havePadding}) =>
    havePadding &&
    css`
      padding: 20px 17px;
    `}
`

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  background-color: ${({theme}) => theme.colors.background.whiteGray};
  padding: 9px 17px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top: 2px solid ${({theme}) => theme.colors.border.modal};
  gap: 13px;
  min-height: 50px;
`
