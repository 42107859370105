import {ReactElement, ReactNode} from 'react'
import {Container, ContentTitle, Title, WrapContent} from './styles'

interface DoubleCardProps {
  title: string
  children: ReactNode
  customWidth?: number
  customHeight?: number
}

function DoubleCard({
  title,
  children,
  customWidth,
  customHeight,
}: DoubleCardProps): ReactElement {
  return (
    <Container customHeight={customHeight} customWidth={customWidth}>
      <ContentTitle>
        <Title>{title} </Title>
      </ContentTitle>
      <WrapContent>{children}</WrapContent>
    </Container>
  )
}

export default DoubleCard
