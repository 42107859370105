import {ReactElement} from 'react'
import Chart from 'react-apexcharts'
import {Container, Content, Title, Legend, BigNumber, Text} from './styles'
import {validateColors} from './validateColors'
import {GoalProps, ApexChartRadialBarProps} from '~/types'
import {useBarIndication} from './useBarIndication'
import {Loading} from '~/components/Load'
import {useGoalIndication} from '~/components/ModalGoalIndication/useGoalIndication'

function ApexChartRadialBar(): ReactElement {
  const {data, isLoading, isFetching} = useBarIndication()
  const {isFetch, isLoading: isLoadingGoal} = useGoalIndication()
  const loading = isFetching || isLoadingGoal || isFetch

  if (isLoading) return <Loading />

  const formatData = data.map((value: GoalProps) => ({
    data: {
      ...value,
    },

    series: [typeof value.percentual !== 'number' ? 0 : value.percentual],

    options: {
      chart: {
        id: value.name,
        height: 350,
      },

      plotOptions: {
        radialBar: {
          hollow: {
            size: '57%',
          },

          dataLabels: {
            show: true,

            name: {
              show: true,

              offsetY: 7,
            },

            value: {
              show: false,
            },
          },
        },
      },

      labels: [
        `${
          typeof value.percentual !== 'number' ? 0 : value.percentual.toString()
        }%`,
      ],

      colors: [validateColors(value.percentual)],
    },
  }))

  return (
    <Container>
      {formatData.map((value: ApexChartRadialBarProps) => {
        const ranDom = Math.random() * 1000
        return (
          <Content key={`${value.data.name}/${ranDom}`}>
            <Title>{value.options.chart.id}</Title>

            <Chart
              options={value.options}
              series={value.series}
              type="radialBar"
              height={150}
              width={150}
            />

            <Legend>
              <BigNumber typeText={value.data.percentual}>
                {value.data.value ? value.data.value : 0}
              </BigNumber>

              <Text typeText={value.data.percentual}>INDICAÇÕES</Text>

              {loading ? (
                <Loading />
              ) : (
                <p className="paragraph">
                  META : {value.data.goal ? value.data.goal : 0}
                </p>
              )}
            </Legend>
          </Content>
        )
      })}
    </Container>
  )
}

export default ApexChartRadialBar
