import styled from 'styled-components'

export const Container = styled.div``
export const StepFooterPanel = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2px 15px;
`

interface SectionParams {
  value: number
  actual: number
}
export const Section = styled.div<SectionParams>`
  display: ${({value, actual}) => (value === actual ? 'flex' : 'none')};
  flex-direction: column;
`
export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 24px;
  gap: 30px;
`

export const CheckbocContent = styled.div`
  display: flex;
  height: 24px;
  align-items: center;
  & > label {
    margin-left: 12px;
  }
`

export const InputIcon = styled.div`
  position: relative;
  flex-grow: 1;
  & input {
    text-align: right;
    padding-right: 40px;
  }
  & svg {
    position: absolute;
    align-items: center;
    right: 0;
    padding-right: 6px;
    display: flex;
    height: 32px;
    font-weight: bold;
  }
`

export const MoneyIcon = styled.div`
  position: absolute;
  align-items: center;
  padding-left: 6px;
  display: flex;
  height: 32px;
  font-weight: bold;
`
