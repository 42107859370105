import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  position: relative;
  min-height: 32px;
  min-width: 32px;
  background-color: ${({theme}) => theme.colors.background.white};
  border-radius: 9px;
`

export const PopoverFooter = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
`

export const PopoverBody = styled.div`
  display: flex;
  flex-direction: column;
`

export const CalendarContainer = styled.div`
  display: flex;
  padding: 10px;
`

export const TimeContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const TimeContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  align-items: center;
`

export const DateContainer = styled.p`
  font-size: 14px;
  width: 100%;
  border-top: 1px solid ${({theme}) => theme.colors.border.main};
  padding-top: 8px;
`

export const TimeLabel = styled.p`
  font-size: 12px;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 4px;
`

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  justify-content: space-evenly;
  align-items: center;
  &:hover svg {
    fill: #610178;
  }
  svg {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 22px;
    width: 22px;
    cursor: pointer;
  }
`

export const Input = styled.input`
  display: flex;
  font-size: 14px;
  padding: 8px 35px;
  width: 100%;
  border-radius: 9px;
  background-color: ${({theme}) => theme.colors.background.white};
  color: ${({theme}) => theme.colors.font.main};
`
