import react, {ReactElement, useEffect, useState} from 'react'
import {FormSelectorType} from '~/types'
import {OptionLabel, SpanLabel} from '~/components/HeaderInput/styles'
import React from 'react'
import Input from '~/components/Input'
import SelectInput from '~/components/SelectInput'
import InputMoneyWithSelector from '~/components/InputMoneyWithSelector'
import {
  Container,
  Header,
  FieldContainer,
  FieldLabel,
  FieldsWrapper,
} from './styles'

interface BatchDescriptionFormProps {
  errorFields: string[]
  description: string
  setDescription: (arg: string) => void
  reasons: FormSelectorType[]
  reasonValue: FormSelectorType
  onChangeReasonValue: (arg: FormSelectorType) => void
}

const BatchDescriptionForm: react.FC<BatchDescriptionFormProps> = ({
  description,
  errorFields,
  onChangeReasonValue,
  reasons,
  reasonValue,
  setDescription,
}): ReactElement => {
  const getValue = (option: Partial<FormSelectorType>) => option.value

  const getLabel = (option: Partial<FormSelectorType>) => option.label

  const formatTypeOptionLabel = (data: unknown) => (
    <OptionLabel>
      <SpanLabel prop={reasons[0].label === (data as FormSelectorType).label}>
        {(data as FormSelectorType).label}
      </SpanLabel>
    </OptionLabel>
  )
  const [error, setError] = useState<{
    description: boolean
    reason: boolean
  }>({
    description: false,
    reason: false,
  })
  useEffect(() => {
    errorFields.forEach((field) => {
      setError({...error, [field]: true})
    })
  }, [errorFields])
  return (
    <Container>
      <Header>Descrição da carga</Header>
      <FieldsWrapper>
        <FieldContainer>
          <FieldLabel>Descrição</FieldLabel>
          <Input
            inputMode="text"
            hasError={error.description}
            value={description}
            onChange={(e) => {
              setError({...error, description: false})
              setDescription(e.target.value)
            }}></Input>
        </FieldContainer>
        <FieldContainer>
          <FieldLabel>Motivo</FieldLabel>
          <SelectInput
            onChange={(e) => {
              setError({...error, reason: false})
              onChangeReasonValue(e as FormSelectorType)
            }}
            hasError={error.reason}
            placeholder="Selecione o motivo"
            options={reasons}
            getOptionLabel={getLabel}
            value={reasonValue}
            getOptionValue={getValue}
            formatOptionLabel={formatTypeOptionLabel}
          />
        </FieldContainer>
      </FieldsWrapper>
    </Container>
  )
}
export default BatchDescriptionForm
