import {FC, PropsWithChildren} from 'react'
import {Container} from './styles'

interface BadgeParams {
  fontSize?: number
  fontColor?: string
  backgroundColor?: string
  type?: 'error' | 'info' | 'success' | 'disabled' | 'warning'
}

const Badge: FC<PropsWithChildren<BadgeParams>> = ({
  children,
  fontSize,
  fontColor,
  backgroundColor,
  type,
}) => {
  return (
    <Container {...{fontColor, backgroundColor, fontSize, type}}>
      {children}
    </Container>
  )
}

export default Badge
