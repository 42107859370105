import styled from 'styled-components'

export const TruncatedText = styled.span`
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  font-weight: 300;
  font-size: 13px;
  color: #32363b;
`

export const PopoverTrigger = styled.span`
  cursor: pointer;
  text-align: left;
  font-weight: 300;
  font-size: 13px;
  color: #32363b;
  &:hover {
    text-decoration: underline;
  }
`

export const PopoverContent = styled.div`
  position: absolute;
  left: 0;
  width: 200px;
  padding: 8px;
  background-color: #f2f2f2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  z-index: 1;
`

export const PopoverItem = styled.div`
  padding: 4px 0;
`
