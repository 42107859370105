import React, {useState} from 'react'
import {HiChevronLeft, HiChevronRight} from 'react-icons/hi'
import {
  Container,
  ChevronButton,
  PageContainer,
  PageList,
  NumberContainer,
} from './styles'
interface PaginatorProps {
  totalItens: number
  itensPerPage: number
  pageWidth?: string
  visiblePages: number
  hoverColor: string
  selectedPageColor: string
  selectedPage: number
  setSelectedPage: (arg: number) => void
}
function Paginator({
  totalItens,
  itensPerPage,
  pageWidth = '24px',
  visiblePages,
  hoverColor,
  selectedPageColor,
  selectedPage,
  setSelectedPage,
}: PaginatorProps) {
  const pages = Array.from(
    {length: Math.ceil(totalItens / itensPerPage)},
    (_, index) => index + 1
  )
  const [scrollX, setScrollX] = useState(0)

  const isPossibleToChange = selectedPage < pages.length - 2 && selectedPage > 2

  function handleSelectedPage(currentPage: number) {
    setSelectedPage(currentPage - 1)
    const offset = currentPage - Math.ceil(visiblePages / 2)

    if (pages.length > 5) {
      if (offset > 0 && currentPage < pages.length - 2) {
        setScrollX(offset * -24)
        return
      }

      if (currentPage >= pages.length - 2) {
        setScrollX((pages.length - 5) * -24)
        return
      }

      setScrollX(0)
    }
  }

  function handleNextPage() {
    if (selectedPage + 1 <= pages.length) {
      setSelectedPage(selectedPage)
    }

    if (isPossibleToChange) {
      setScrollX((prevState) => prevState - 24)
    }
  }

  function handleBackPage() {
    if (selectedPage - 1 > 0) {
      setSelectedPage(selectedPage - 2)
    }

    if (isPossibleToChange) {
      setScrollX((prevState) => prevState + 24)
    }
  }

  return (
    <Container>
      <ChevronButton disabled={selectedPage === 1} hoverColor={hoverColor}>
        <HiChevronLeft size={32} onClick={handleBackPage} />
      </ChevronButton>
      <PageContainer pageWidth={pageWidth} visiblePages={visiblePages}>
        <PageList style={{marginLeft: scrollX}}>
          {pages.map((page: number) => (
            <NumberContainer
              key={page}
              pageWidth={pageWidth}
              hoverColor={hoverColor}
              selectedPageColor={selectedPageColor}
              isSelectedPage={page === selectedPage}
              onClick={() => handleSelectedPage(page)}>
              <span>{page}</span>
            </NumberContainer>
          ))}
        </PageList>
      </PageContainer>
      <ChevronButton
        disabled={selectedPage === pages.length}
        hoverColor={hoverColor}>
        <HiChevronRight size={32} onClick={handleNextPage} />
      </ChevronButton>
    </Container>
  )
}

export default Paginator
