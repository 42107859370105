import styled, {css} from 'styled-components'

interface InputProps {
  haveFocus: boolean
}

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 20px 17px;
  gap: 33px;
`

export const TitleContent = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: ${({theme}) => theme.colors.font.main};
`
export const WrapInputMeta = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  margin-left: 10px;
  margin-right: 10px;
`

export const TitleMeta = styled.span`
  font-family: Segoe UI Normal, Segoe UI, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: ${({theme}) => theme.colors.font.dark};
`

export const Input = styled.input<InputProps>`
  background-color: ${({theme}) => theme.colors.border.modal};
  border-radius: 7px;
  width: 54px;
  padding: 7px 0px;
  text-align: center;
  font-family: Segoe UI Light, Segoe UI, Segoe UI Normal, sans-serif;
  font-size: 14px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  color: ${({theme}) => theme.colors.font.dark};
  transition: all ease-in-out 0.3s;
  ${({haveFocus}) =>
    haveFocus &&
    css`
      background-color: ${({theme}) => theme.colors.background.white};
    `}
  &:hover {
    background-color: ${({theme}) => theme.colors.background.white};
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
`

export const Information = styled.span`
  font-family: Segoe UI Light, Segoe UI, Segoe UI Normal, sans-serif;
  font-size: 13px;
  font-weight: 300;
  color: ${({theme}) => theme.colors.font.dark};
`
export const AboutMonth = styled.div`
  text-align: center;
  background-color: ${({theme}) => theme.colors.background.red};
  padding: 10px 15px;
`

// export const ButtonCancellation = styled(ButtonApplication)`
//   background-color: ${({theme}) => theme.colors.background.white};
//   color: ${({theme}) => theme.colors.font.purpleMain};
//   opacity: 0.9;
//   &:hover {
//     background-color: ${({theme}) => theme.colors.background.white};
//     opacity: 1;
//   }
// `
