import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  width: 100%;
  height: 100%;
  padding: 0px 20px;
  p {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 11px;
    color: ${({theme}) => theme.colors.font.main};
  }
`
export const WrapContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 35px;
`
export const WrapBigNumber = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const BigNumber = styled.div`
  display: block;
  text-align: center;
  font-size: 20px;
  color: #51a176;
  font-weight: 600;
  text-transform: uppercase;
  span {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    margin-top: 5px;
    font-weight: normal;
    color: ${({theme}) => theme.colors.font.main};
  }
`
