import {ReactElement, ReactNode} from 'react'
import {Container, Header, Body} from './styles'

interface PopoverParams {
  width?: string
  header?: ReactNode
  children?: ReactNode
}

const Popover = ({width, header, children}: PopoverParams): ReactElement => {
  return (
    <Container {...{width}}>
      {header && <Header>{header}</Header>}
      <Body className={!header && 'withoutHeader'}>{children}</Body>
    </Container>
  )
}

export default Popover
