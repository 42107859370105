import React, {useEffect, useRef, useState} from 'react'
import {
  Button,
  ButtonContainer,
  Container,
  HourContainer,
  LabelContainer,
  MinuteContainer,
  Number,
  PickerContainer,
  Type,
} from './styles'
import {FaCaretDown, FaCaretUp} from 'react-icons/fa'

interface TimePickerParams {
  onChange?: (time: {hour: number; minute: number}) => void
  value?: {
    hour: number
    minute: number
  }
}

export const TimePicker: React.FC<TimePickerParams> = ({onChange, value}) => {
  const limitValue = (value, min, max) => {
    return value < min ? min : value > max ? max : value
  }
  const [hour, setHour] = useState<number>(limitValue(value.hour, 0, 23))
  const [minute, setMinute] = useState<number>(limitValue(value.minute, 0, 59))
  const didMountRef = useRef(false)
  const hourContainer = useRef<HTMLDivElement>()
  const minuteContainer = useRef<HTMLDivElement>()

  const changeHourOnWheel = (event: WheelEvent) => {
    event.preventDefault()
    if (hour < 23 && event.deltaY < 0) {
      setHour((current) => current + 1)
    }
    if (hour > 0 && event.deltaY > 0) {
      setHour((current) => current - 1)
    }
  }

  const changeMinuteOnWheel = (event: WheelEvent) => {
    event.preventDefault()
    if (minute < 59 && event.deltaY < 0) {
      setMinute((current) => current + 1)
    }
    if (minute > 0 && event.deltaY > 0) {
      setMinute((current) => current - 1)
    }
  }

  useEffect(() => {
    if (didMountRef.current) {
      onChange?.({hour, minute})
    }
    hourContainer.current.addEventListener('wheel', changeHourOnWheel, {
      passive: false,
    })
    minuteContainer.current.addEventListener('wheel', changeMinuteOnWheel, {
      passive: false,
    })
    didMountRef.current = true
    return () => {
      hourContainer.current?.removeEventListener('wheel', changeHourOnWheel)
      minuteContainer.current?.removeEventListener('wheel', changeMinuteOnWheel)
    }
  }, [hour, minute])
  return (
    <Container disabled={false}>
      <PickerContainer>
        <HourContainer ref={hourContainer}>
          <ButtonContainer>
            <Button
              onClick={() => setHour((current) => current + 1)}
              disabled={hour === 23}>
              <FaCaretUp size={22} viewBox="0 160 320 200"></FaCaretUp>
            </Button>
            <Button
              onClick={() => setHour((current) => current - 1)}
              disabled={hour === 0}>
              <FaCaretDown size={22} viewBox="0 160 320 200"></FaCaretDown>
            </Button>
          </ButtonContainer>
          <LabelContainer>
            <Number>{hour.toString().padStart(2, '0')}</Number>
            <Type>hs</Type>
          </LabelContainer>
        </HourContainer>
        <MinuteContainer ref={minuteContainer}>
          <ButtonContainer>
            <Button
              onClick={() => setMinute((current) => current + 1)}
              disabled={minute === 59}>
              <FaCaretUp size={22} viewBox="0 160 320 200"></FaCaretUp>
            </Button>
            <Button
              onClick={() => setMinute((current) => current - 1)}
              disabled={minute === 0}>
              <FaCaretDown size={22} viewBox="0 160 320 200"></FaCaretDown>
            </Button>
          </ButtonContainer>
          <LabelContainer>
            <Number>{minute.toString().padStart(2, '0')}</Number>
            <Type>min</Type>
          </LabelContainer>
        </MinuteContainer>
      </PickerContainer>
    </Container>
  )
}
