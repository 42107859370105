import styled from 'styled-components'

export const SkeletonBox = styled.span`
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      #ffffff00 0%,
      #ffffff20 30%,
      #ffffff40 50%,
      #ffffff20 70%,
      #ffffff00 100%
    );
    animation: shimmer 5s infinite;
    content: '';
  }
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
`
