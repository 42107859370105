import styled from 'styled-components'

interface CardContainer {
  width?: string
}

export const Container = styled.div<CardContainer>`
  display: flex;
  flex-direction: column;
  width: ${({width}) => width ?? '100%'};
  min-height: 75px;
  margin-top: 37px;
  border-top: 3px solid #8600a7;
`

export const Header = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
`

interface BodyParams {
  hasFooter: boolean
}
export const Body = styled.div<BodyParams>`
  flex: 1;
  padding: 20px 8px 8px 8px;
  min-height: 80px;
  border-top: 1px solid ${({theme}) => theme.colors.border.main};
  background-color: ${({theme}) => theme.colors.background.white};
  box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px, rgba(0, 0, 0, 0.23) 0 3px 6px;
  ${({hasFooter}) =>
    !hasFooter &&
    `
    padding-bottom: 40px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  `}
`

export const Footer = styled.div`
  display: flex;
  padding: 10px 23px;
  flex-direction: row-reverse;
  margin-top: auto;
  background-color: ${({theme}) => theme.colors.input.background};
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top: 1px solid ${({theme}) => theme.colors.border.main};
  background-color: ${({theme}) => theme.colors.background.step.footerColor};
  box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px, rgba(0, 0, 0, 0.23) 0 3px 6px;
  min-height: 40px;
`
