import React, {FC, ReactElement, useRef, useState} from 'react'
import {Container, Header, IconContainer, MessageContainer} from './style'
import {useQuery} from 'react-query'
import {getScores} from '~/services/apiManagerService'
import {dateTimeToFormat, numberFormat} from '~/utils'
import {TableHeader} from '~/components/NewTable/TableHeader'
import {
  Paginator,
  Table,
  TableCol,
  TableHeaderCol,
  TableRow,
} from '~/components/NewTable'
import {CSVGenerator, CSVRef} from '~/components/CSVGenerator'
import {Score} from '~/types/ApiManagerTypes'
import {MdAddShoppingCart} from 'react-icons/md'
import {ScoreFilterBar} from '~/pages/CustomerProfile/ScoreTab/ScoreFilterBar'

type ScoreTabParams = {
  email: string
}

export const ScoreTab: FC<ScoreTabParams> = ({email}): ReactElement => {
  const csvRef = useRef<CSVRef>()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(15)
  const [filters, setFilters] = useState({})
  const {data, isLoading, isPreviousData} = useQuery(
    [
      'customerAnalysisScores',
      email,
      {
        page,
        perPage,
        ...filters,
      },
    ],
    async () => getScores(email, page, perPage, filters),
    {
      keepPreviousData: true,
    }
  )
  const getClusterName = (slug: string) => {
    switch (slug) {
      case 'brass':
        return 'Latão'
      case 'bronze':
        return 'Bronze'
      case 'silver':
        return 'Prata'
      case 'gold':
        return 'Ouro'
    }
  }

  return (
    <Container>
      <Header>
        <ScoreFilterBar
          onClickCsvButton={() => {
            if (csvRef.current) {
              void csvRef.current.generateCSV()
            }
          }}
          onChange={(filter) => {
            setPage(1)
            setFilters(filter)
          }}
          count={data?.meta.pagination.count ?? 0}
        />
        <CSVGenerator
          csvRef={csvRef}
          request={(csvPage) => getScores(email, csvPage, 1000, filters)}
          csvName={`${email}_referrals`}
          map={(score: Score) => {
            return {
              data: dateTimeToFormat(score.createdAt, {}),
              motivo: score.reason,
              descricao: score.description,
              pontos: `${
                score.movementType === 'credit' ? '+' : '-'
              } ${numberFormat(score.score, 0)}`,
              pontuacaoAtual: numberFormat(score.balance, 0),
              nivel: getClusterName(score.cluster),
            }
          }}
        />
      </Header>
      {isLoading ? (
        <MessageContainer>
          Carregando histórico de saldo de pontos e mudança de nível
        </MessageContainer>
      ) : data?.data.length === 0 ? (
        <MessageContainer>
          Sem histórico de saldo de pontos e mudança de nível.
        </MessageContainer>
      ) : (
        <>
          <Table isLoading={isPreviousData}>
            <TableHeader>
              <IconContainer></IconContainer>
              <TableHeaderCol>data</TableHeaderCol>
              <TableHeaderCol>motivo</TableHeaderCol>
              <TableHeaderCol>descrição</TableHeaderCol>
              <TableHeaderCol>pontos</TableHeaderCol>
              <TableHeaderCol>pontuação atual</TableHeaderCol>
              <TableHeaderCol>nível</TableHeaderCol>
            </TableHeader>
            {data?.data.map((row) => (
              <TableRow key={row.id}>
                <IconContainer>
                  <MdAddShoppingCart />
                </IconContainer>
                <TableCol>{dateTimeToFormat(row.createdAt, {})}</TableCol>
                <TableCol>{row.reason}</TableCol>
                <TableCol>{row.description}</TableCol>
                <TableCol>
                  {row.movementType === 'credit' ? '+' : '-'}&nbsp;
                  {numberFormat(row.score, 0)}
                </TableCol>
                <TableCol>{numberFormat(row.balance, 0)}</TableCol>
                <TableCol>{getClusterName(row.cluster)}</TableCol>
              </TableRow>
            ))}
          </Table>
          <Paginator
            isLoading={isPreviousData}
            currentPage={page}
            perPage={perPage}
            totalItems={data?.meta.pagination.count}
            onChange={(page, perPage) => {
              setPage(page)
              setPerPage(perPage)
            }}
            perPageOptions={[15, 30, 45, 60]}
          />
        </>
      )}
    </Container>
  )
}
