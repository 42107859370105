import styled from 'styled-components'

export const CustomerInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({theme}) => theme.colors.background.white};
  margin: 20px;
  padding: 20px;
  gap: 70px;
`

export const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const ClusterContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
`

export const ClusterBadge = styled.div<{color: string}>`
  display: flex;
  align-items: center;
  text-align: center;
  & svg {
    font-size: 60px;
    fill: ${({color}) => color};
  }
`

export const CashbackContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
`

export const ReferralContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
`

export const Section = styled.div<{activated: boolean}>`
  display: ${({activated}) => (activated ? 'flex' : 'none')};
`

export const TableContainer = styled.div`
  display: flex;
  padding: 40px;
  width: 100%;
`
