import react, {InputHTMLAttributes, ReactElement} from 'react'
import {Container} from './styles'
import SelectInput from '~/components/SelectInput'
import {FormSelectorType} from '~/types'
import React from 'react'
import InputMoney from '~/components/InputMoney'
import {OptionLabel, SpanLabel} from '~/components/HeaderInput/styles'

interface InputMoneyWithSelectorProps
  extends InputHTMLAttributes<HTMLInputElement> {
  selectOptions: FormSelectorType[]
  selectedValue: FormSelectorType
  onSelectChange: (arg: FormSelectorType) => void
  hasError?: boolean
}
const InputMoneyWithSelector: react.FC<InputMoneyWithSelectorProps> = ({
  onSelectChange,
  selectOptions,
  selectedValue,
  hasError,
  ...rest
}): ReactElement => {
  const getValue = (option: Partial<FormSelectorType>) => option.value

  const getLabel = (option: Partial<FormSelectorType>) => option.label

  const formatTypeOptionLabel = (data: unknown) => (
    <OptionLabel>
      <SpanLabel
        prop={selectOptions[0].label === (data as FormSelectorType).label}>
        {(data as FormSelectorType).label}
      </SpanLabel>
    </OptionLabel>
  )

  return (
    <Container>
      <SelectInput
        hasError={hasError}
        borderRadius={'8px 0px 0px 8px'}
        width="124px"
        onChange={(e) => {
          onSelectChange(e as FormSelectorType)
        }}
        placeholder="Selecione o motivo"
        options={selectOptions}
        getOptionLabel={getLabel}
        value={selectedValue}
        getOptionValue={getValue}
        formatOptionLabel={formatTypeOptionLabel}
      />
      <InputMoney
        hasError={hasError}
        currency={'R$'}
        customBorderRadius={'0px 8px 8px 0px'}
        {...rest}></InputMoney>
    </Container>
  )
}
export default InputMoneyWithSelector
