import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  float: left;
  box-sizing: border-box;
  &:before {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
  }
`
