import React, {FC, ReactElement, useState} from 'react'
import Input from '~/components/Input'
import Button from '~/components/Button'
import {useHistory} from 'react-router-dom'
import * as yup from 'yup'
import {toast} from 'react-toastify'
import {useQuery} from 'react-query'
import {getCustomer} from '~/services/apiManagerService'

export const SearchCustomer: FC = (): ReactElement => {
  const history = useHistory()
  const [formErrors, setFormErrors] = useState<Record<string, boolean>>({})
  const [formData, setFormData] = useState({
    email: '',
  })
  const {refetch, isLoading} = useQuery(
    ['customerProfile', formData.email],
    async () => getCustomer(formData.email),
    {
      enabled: false,
    }
  )
  const searchCustomer = async () => {
    const schema = yup.object({
      email: yup
        .string()
        .email('O e-mail do cliente é inválido')
        .required('O e-mail do cliente é obrigatório'),
    })
    try {
      schema.validateSync(formData, {abortEarly: false})
      const {error} = await refetch()
      if (error !== null) {
        return toast.error('Cliente não encontrado')
      }
      history.push(`/cliente/${formData.email}`)
    } catch (e: any) {
      toast.error(e.message)
      setFormErrors(
        e.inner.reduce((obj, error) => ({...obj, [error.path]: true}), {})
      )
      console.error(e)
    }
  }
  return (
    <>
      <p>
        Localize o cliente através do <b>e-mail cadastrado</b> no Compra Certa.
      </p>
      <div
        style={{
          marginTop: 35,
          width: '40%',
        }}>
        <Input
          autoComplete="off"
          placeholder="Digite o e-mail do cliente"
          hasError={formErrors.email}
          onFocus={() =>
            setFormErrors((errors) => ({
              ...errors,
              email: false,
            }))
          }
          id="email"
          value={formData.email}
          disabled={isLoading}
          onChange={(e) =>
            setFormData((formData) => ({
              ...formData,
              email: e.target.value,
            }))
          }
        />
      </div>
      <div
        style={{
          marginTop: 35,
        }}>
        <Button
          text="Localizar cliente"
          disabled={isLoading}
          onClick={searchCustomer}
        />
      </div>
    </>
  )
}
