import styled, {css} from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  padding: 24px 24px;
  width: 100%;
  align-items: flex-start;
  border-top: 1px solid ${({theme}) => theme.colors.border.modal};
  border-bottom: 1px solid ${({theme}) => theme.colors.border.modal};
  background: ${({theme}) => theme.colors.background.main};
`
type InputProps = {
  haveFocus?: boolean
}
export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  width: 80%;
  align-items: center;
`
export const InputWrapper = styled.div<InputProps>`
  font-size: 12px;
  border: 1px solid grey;
  float: left;
  width: 90%;
  box-sizing: border-box;
  padding: 6px 12px;
  height: 32px;
  border: 1px;
  cursor: pointer;
  border-color: ${({theme}) => theme.colors.background.gray};
  border-radius: 8px 0px 0px 8px;
  box-shadow: 1px 1px 4px rgb(0 0 0 / 25%);
  background: ${({theme}) => theme.colors.input.background};
  ${({haveFocus}) =>
    haveFocus &&
    css`
      background-color: ${({theme}) => theme.colors.background.white};
    `}
`
export const Input = styled.input`
  visibility: hidden;
  flex: 1;
`
export const InputButton = styled.button`
  box-shadow: 1px 1px 4px rgb(0 0 0 / 25%);
  background: ${({theme}) => theme.colors.font.purpleDark};
  width: 56px;
  height: 32px;
  border-radius: 0px 5px 5px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Message = styled.span``
