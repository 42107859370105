import ReactDOM from 'react-dom/client'
import {Provider} from 'react-redux'
import {store} from './app/store'
import {ContextProvider} from '~/hooks'
import Route from './routes'
import {ConfiguredToastContainer} from '~/config/toastify'

const root = document.getElementById('root')

if (root) {
  const container = ReactDOM.createRoot(root)

  container.render(
    <Provider store={store}>
      <ConfiguredToastContainer />
      <ContextProvider>
        <Route />
      </ContextProvider>
    </Provider>
  )
}
