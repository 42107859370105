import styled from 'styled-components'

export const ModalFooter = styled.div`
  width: 100%;
  height: 56px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  gap: 16px;
  border-radius: 0 0 12px 12px;
  border-top: 1px solid ${({theme}) => theme.colors.border.modal};
  background: ${({theme}) => theme.colors.background.main};
  bottom: 0;
  left: 0;
  right: 0;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`

export const Info = styled.div<{show: boolean}>`
  display: ${({show}) => (show ? 'flex' : 'none')};
  flex-direction: column;
  gap: 20px;
  width: 100%;
`
