import {ReactElement, useEffect} from 'react'
import Financial from '~/components/Financial'
import Movement from '~/components/Movement'
import {Container} from './styles'
import {useMovementProvider} from '~/hooks/useMovementProvider'

function FinancialMain(): ReactElement {
  const {updateFilterByType} = useMovementProvider()

  useEffect(() => {
    updateFilterByType({
      value: 'movement',
      label: 'Todas movimentações',
    })
  }, [])

  return (
    <Container>
      <Financial />
      <Movement />
    </Container>
  )
}

export default FinancialMain
