import React, {FC} from 'react'
import styled from 'styled-components'
import {FaCircle} from 'react-icons/fa'

export const Container = styled.div<{color: string}>`
  display: flex;
  padding: 8px;
  align-items: center;
  & svg {
    fill ${({theme, color}) => theme.colors.font.dot[color]}
  }
`

interface HealthDotParams {
  color: 'error' | 'info' | 'success' | 'warning' | 'disabled'
}

export const HealthDot: FC<HealthDotParams> = ({color}) => {
  return (
    <Container {...{color}}>
      <FaCircle />
    </Container>
  )
}
