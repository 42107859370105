import {useState} from 'react'
import {useQuery} from 'react-query'
import api from '~/config/apiSauce'
import {Series, OptionsProps, DataProps} from '~/types'
import {queryKeys} from '~/utils/queryKeys'
import {handleFetchData} from '~/utils/requests'

async function getIndication(): Promise<DataProps> {
  const response = (await api.get('/referral/chart/monthly')) as {
    data: DataProps
  }

  return response.data
}

interface UseIndicationProps {
  isLoading: boolean
  series: Series[]
  options: OptionsProps
  isFetching: boolean
}

export const useIndicationMonth = (): UseIndicationProps => {
  const {indicationMonth, indication} = queryKeys
  const [series, setSeries] = useState<Series[]>([])
  const [options, setOptions] = useState<OptionsProps>({} as OptionsProps)

  const {isLoading, isFetching, data} = useQuery(
    [indication, indicationMonth],
    getIndication,

    {
      onSuccess: (data: DataProps) => {
        const {series, options} = handleFetchData(data, 'monthly')
        setSeries(series)
        setOptions(options)
      },
      staleTime: 2000,
      keepPreviousData: true,
    }
  )
  if (series.length === 0 && data) {
    const {series, options} = handleFetchData(data, 'monthly')
    setSeries(series)
    setOptions(options)
  }

  return {
    isLoading,
    series,
    options,
    isFetching,
  }
}
