import {DateTime} from 'luxon'
import {isNullOrUndef} from 'chart.js/helpers'

export function createJsonDateHandler(
  map: string[]
): (key: string, value: any) => any {
  const index = map.reduce((prev, curr) => {
    return {
      ...prev,
      [curr]: true,
    }
  }, {})
  return (body) => {
    return JSON.parse(body, (key: string, value: any) => {
      if (index[key] !== undefined) {
        return DateTime.fromISO(value)
      }
      return value
    })
  }
}

export function createPipeline(...steps) {
  return (context) =>
    steps.reduce((pipedValue, next) => next(pipedValue), context)
}

export function dateTimeToFormat(
  date: DateTime,
  {
    dateFormat = 'dd/LL/yyyy',
    timeFormat = "HH'h'mm",
    withTime = false,
    invalidLabel = '-',
  }: {
    dateFormat?: string
    timeFormat?: string
    withTime?: boolean
    invalidLabel?: string
  }
) {
  return date.isValid
    ? date.toFormat(dateFormat) +
        (withTime ? date.toFormat(' ' + timeFormat) : '')
    : invalidLabel
}

export function moneyFormat(value?: number): string {
  return !isNullOrUndef(value)
    ? value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
    : '-'
}

export function numberFormat(value: number, decimals = 2): string {
  return !isNullOrUndef(value)
    ? value.toLocaleString('pt-BR', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      })
    : '-'
}

export function capitalize(text?: string): string {
  if (text === undefined) {
    return text
  }
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
