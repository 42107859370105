import {HTMLAttributes, ReactElement} from 'react'
import {Container, Button} from './styles'
import {useFinancialProvider} from '~/hooks/useFinancialProvider'

interface TabsItemProps extends HTMLAttributes<HTMLButtonElement> {
  text: string
}

function TabsItem({text, ...rest}: TabsItemProps): ReactElement {
  const {tab} = useFinancialProvider()

  return (
    <Container monthSelect={tab.month === text}>
      <Button {...rest} type="button">
        <span>{text}</span>
      </Button>
    </Container>
  )
}

export default TabsItem
