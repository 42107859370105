import React, {ChangeEvent, ReactElement, useEffect, useState} from 'react'
import {useTheme} from 'styled-components'
import ScreenHeader from '~/components/ScreenHeader'
import Button from '~/components/Button'
import toast, {Toaster} from 'react-hot-toast'
import {parse as csvParse} from 'papaparse'
import {
  Container,
  EmptyDiv,
  HeaderBtnWrapper,
  ModalContainer,
  ScreenContent,
  WrapRules,
  Loader,
} from './styles'
import HeaderInputFilter from '~/components/HeaderInput'
import {BsCheckLg} from 'react-icons/bs'
import ManualBonusRow from '~/components/ManualBonus/ManualBonusRow'
import WideModal from '~/components/WideModal'
import api from '~/config/apiSauce'
import {
  BatchBonusForm,
  BonusReason,
  DataPagination,
  FormSelectorType,
  ManualBonusClientData,
  ManualBonusFilter,
  ManualBonusListType,
  SingleManualBonusForm,
} from '~/types'
import {ApiErrorResponse, ApiOkResponse} from 'apisauce'
import SearchWithSearchBtn from '~/components/SearchWithSearchBtn'
import ModalBodyError from '~/components/ModalBodyError'
import SelectedClient from './Components/SelectedClient'
import ManualBonusForm from './Components/ManualBonusForm'
import * as yup from 'yup'
import BatchDescriptionForm from './Components/BatchDescriptionForm'
import BatchUploadForm from './Components/BatchUploadForm'
import Paginator from '~/components/Table/Paginator'
import PulseLoader from 'react-spinners/PulseLoader'
import defaultTheme from '~/assets/styles/themes/default'
import {ValidationError} from 'yup'

const ManualBonus: React.FC = (): ReactElement => {
  //#region ValidationSchema
  const singleBonusValidationSchema = yup.object().shape({
    clusterName: yup.string().required(),
    clusterId: yup.string().required(),
    customerId: yup.string().required(),
    description: yup.string().required(),
    customer: yup.string().email(),
    transactionType: yup.string().required(),
    reason: yup.string().required(),
    value: yup.number().required().positive(),
    document: yup.string(),
    customerName: yup.string(),
    expireAt: yup.date().required(),
  })
  const batchFirstStepValidation = yup.object().shape({
    reason: yup.string().required(),
    description: yup.string().required(),
  })
  const csvValidation = yup.object().shape({
    data: yup.array().of(
      yup.object({
        email: yup.string().email().required(),
        description: yup.string().required(),
        value: yup.number().required(),
      })
    ),
  })
  //#endRegion
  type AdjustType = {
    name: string
    value: string
    step: number
  }
  const {colors} = useTheme()
  //#region constants
  const optionsStatus = [
    {
      value: undefined,
      label: 'Todos Processamentos',
      icon: null,
    },
    {
      value: 'toBeProcessed',
      label: 'Processando',
      icon: null,
    },
    {
      value: 'processed',
      label: 'Processamento completo',
      icon: null,
    },
    {
      value: 'error',
      label: 'Erro',
      icon: null,
    },
  ]
  const optionsType = [
    {
      value: undefined,
      label: 'Créditos e débitos',
      icon: null,
    },
    {
      value: 'Credit',
      label: 'Somente créditos',
      icon: null,
    },
    {
      value: 'Debit',
      label: 'Somente débitos',
      icon: null,
    },
  ]
  const tips = [
    '- Verifique se o e-mail foi digitado corretamente; ou',
    '- Crie uma conta para este cliente',
  ]
  const [manualBonusData, setManualBonusData] = useState<
    DataPagination<ManualBonusListType>
  >({
    data: [],
  })
  const filtersInitialState = {
    customer: '',
    includedAt: undefined,
    processingStatus: optionsStatus[0],
    transactionType: optionsType[0],
    page: 1,
  }
  const [filters, setFilters] = useState<ManualBonusFilter>(filtersInitialState)

  const [file, setFile] = useState<File | null>(null)
  const [bonusReasons, setBonusReasons] = useState<FormSelectorType[]>([])
  const [modalContext, setModalContext] = useState<AdjustType>({
    name: '',
    value: '',
    step: 0,
  })
  const [isModalOpened, setModalIsModalOpened] = useState<boolean>(false)
  const [isDataProcessing, setDataProcessing] = useState<boolean>(false)
  const [clientEmail, setClientEmail] = useState<string>('')
  const [clientEmailError, setClientEmailError] = useState<boolean>()
  const [manualBonusErrorFields, setManualBonusErrorFields] = useState<
    string[]
  >([])
  const [batchBonusErrorFields, setBatchBonusErrorFields] = useState<string[]>(
    []
  )

  const manualBonusInitialValue: SingleManualBonusForm = {
    clusterName: '',
    customer: '',
    customerName: '',
    customerId: '',
    description: '',
    expireAt: new Date(),
    reason: {
      key: '',
      label: '',
      value: '',
    },
    transactionType: {
      key: '',
      label: '',
      value: '',
    },
    value: '',
    clusterId: '',
    document: '',
    daysToExpire: '0',
  }
  const [singleBonusForm, setSingleBonusForm] = useState<SingleManualBonusForm>(
    manualBonusInitialValue
  )
  const batchBonusInitialValue: BatchBonusForm = {
    data: [],
    description: '',
    reason: {
      key: '',
      label: '',
      value: '',
    },
  }
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [batchBonusData, setBatchBonusData] = useState<BatchBonusForm>(
    batchBonusInitialValue
  )
  const [isBatchBonusFormated, setIsBatchBonusFormated] =
    useState<boolean>(true)

  useEffect(() => {
    if (file) {
      const reader = new FileReader()
      reader.onload = async (body) => {
        const parsedCsv = csvParse<[string, number, string]>(
          body.target.result as string,
          {
            dynamicTyping: true,
            skipEmptyLines: true,
          }
        )
        const csvAsObject = parsedCsv.data.map(
          ([email, value, description]) => {
            return {email, value, description}
          }
        )
        try {
          await csvValidation.validate(
            {
              data: csvAsObject,
            },
            {
              strict: true,
              abortEarly: false,
            }
          )
          setBatchBonusData({
            ...batchBonusData,
            data: csvAsObject,
          })
        } catch (e) {
          if (e instanceof ValidationError) {
            e.inner?.map((err) => {
              setIsBatchBonusFormated(false)
            })
          }
          toast.error('Csv é inválido')
        }
      }
      reader.readAsText(file)
    }
  }, [file])

  const fetchBonusData = async (filter: {
    includedAt?: string
    transactionType?: string
    processingStatus?: string
    customer?: string
    page: number
  }): Promise<
    ApiErrorResponse<any> | ApiOkResponse<DataPagination<ManualBonusListType>>
  > => {
    const params = {
      transactionType: filter.transactionType,
      includedAt: filter.includedAt,
      processingStatus: filter.processingStatus,
    }
    if (filter.customer !== undefined && filter.customer.length >= 3) {
      params['customer'] = filter.customer
    }
    setIsLoading(true)
    const res = api.get<DataPagination<ManualBonusListType>, any>(
      'mb/manualBonus',
      params
    )
    setIsLoading(false)
    return res
  }

  useEffect(() => {
    if (filters?.customer?.length > 0 && filters?.customer?.length < 4) return
    fetchBonusData({
      page: filters.page,
      customer: filters.customer,
      includedAt: filters?.includedAt
        ? filters?.includedAt?.toISOString().split('T')[0]
        : undefined,
      processingStatus: filters.processingStatus.value,
      transactionType: filters.transactionType.value,
    }).then((res) => {
      if (res.ok) {
        return setManualBonusData(
          res.data as DataPagination<ManualBonusListType>
        )
      }
      toast('Erro ao aplicar os filtros')
    })
  }, [filters, isLoading])

  useEffect(() => {
    const fetchBonusReasonData = async (): Promise<
      ApiErrorResponse<{error: string}> | ApiOkResponse<BonusReason[]>
    > => await api.get<BonusReason[], {error: string}>('mb/bonusReasons')
    fetchBonusReasonData().then((value) => {
      if (Array.isArray(value.data)) {
        setBonusReasons(
          (value.data as BonusReason[]).map((x) => {
            return {
              key: x.id,
              label: x.value,
              value: x.name,
            }
          })
        )
      }
    })

    //#endregion listBonusReasons
  }, [])

  useEffect(() => {
    if (isModalOpened === false) {
      setSingleBonusForm(manualBonusInitialValue)
      setBatchBonusData(batchBonusInitialValue)
    }
  }, [isModalOpened])
  //#endregion eventHandlers
  //#region GetClientData
  const getClientData = (): void => {
    const fetchClientData = async (): Promise<
      ApiErrorResponse<{error: string}> | ApiOkResponse<ManualBonusClientData>
    > =>
      await api.get<ManualBonusClientData, {error: string}>('mb/clients', {
        email: clientEmail,
      })
    fetchClientData().then((value) => {
      if (value.status === 404 || value.status === 422) {
        setClientEmailError(true)
        return
      }
      setClientEmailError(false)
      const data: ManualBonusClientData = value.data as ManualBonusClientData
      setSingleBonusForm({
        ...singleBonusForm,
        clusterId: data.clusterId,
        clusterName: data.clusterName,
        customer: data.customer,
        customerName: data.customerName,
        customerId: data.customerId,
        document: data.document,
      })
      setClientEmail('')
    })
  }

  //#region SingleBonusPost
  const postSingleBonus = async (): Promise<void> => {
    const fetchPostRequest = async (
      reqBody: any
    ): Promise<ApiErrorResponse<{error: string}> | ApiOkResponse<any>> =>
      await api.post('mb/manualBonus', reqBody)
    setManualBonusErrorFields([])
    const reqBody = {
      clusterName: singleBonusForm.clusterName,
      clusterId: singleBonusForm.clusterId,
      customerId: singleBonusForm.customerId,
      description: singleBonusForm.description,
      customer: singleBonusForm.customer,
      transactionType: singleBonusForm.transactionType.value,
      reason: singleBonusForm.reason.key,
      value: Number(singleBonusForm.value),
      document: singleBonusForm.document,
      customerName: singleBonusForm.customerName,
      expireAt: new Date(
        new Date().setDate(
          new Date().getDate() + Number(singleBonusForm.daysToExpire)
        )
      ),
    }
    singleBonusValidationSchema
      .validate(reqBody, {strict: true, abortEarly: false})
      .then(() => {
        setDataProcessing(true)
        fetchPostRequest(reqBody).then((resp) => {
          if (resp.status == 201) {
            toast.success('Inserção manual inserida com sucesso!')
            setModalIsModalOpened(false)
            setClientEmail('')
            setSingleBonusForm(manualBonusInitialValue)
            fetchBonusData({page: 1}).then((value) => {
              setManualBonusData(
                value.data as DataPagination<ManualBonusListType>
              )
            })
          } else {
            toast.error(
              'Não foi possível realizar a inserção manual, verifique os dados ou tente mais tarde'
            )
          }
        })
      })
      .catch((e) => {
        const schemaErrors = e.inner?.map((err) => {
          return err.path
        })
        setManualBonusErrorFields(schemaErrors)
        return
      })
      .finally(() => setDataProcessing(false))
  }

  //#endregion
  //#region PostBatchBonus
  const postBatchBonus = async (): Promise<void> => {
    setBatchBonusErrorFields([])
    if (!isBatchBonusFormated) {
      setDataProcessing(false)
      toast.error(
        'Não foi possível realizar as inserções manuais, verifique o erro de formatação'
      )
      return
    }
    const reqBody = {
      data: batchBonusData.data,
      description: batchBonusData.description,
      reason: batchBonusData.reason.key,
    }
    const fetchPostRequest = async (
      reqBody: any
    ): Promise<ApiErrorResponse<{error: string}> | ApiOkResponse<any>> =>
      await api.post('mb/manualBonus/batch', reqBody)
    setDataProcessing(true)
    fetchPostRequest(reqBody)
      .then((resp) => {
        if (resp.status == 201) {
          toast.success('Inserções manuais inseridas com sucesso!')
          setModalIsModalOpened(false)
          setIsBatchBonusFormated(true)
          setClientEmail('')
          setBatchBonusData(batchBonusInitialValue)
          fetchBonusData({page: 1}).then((value) => {
            setManualBonusData(
              value.data as DataPagination<ManualBonusListType>
            )
          })
        } else {
          toast.error(
            'Não foi possível realizar as inserções manuais, verifique os dados'
          )
        }
      })
      .finally(() => setDataProcessing(false))
  }
  //#endregion

  //#endRegion
  const EmailHandler = (e: ChangeEvent<HTMLInputElement>) =>
    setClientEmail(e.target.value)

  const handleModalButtonAction = async (): Promise<void> => {
    if (modalContext.name === 'single') {
      await postSingleBonus()
      return
    } else if (modalContext.step === 0 && modalContext.name === 'charge') {
      const dataToValidate = {
        reason: batchBonusData.reason.key,
        description: batchBonusData.description,
      }
      batchFirstStepValidation
        .validate(dataToValidate, {strict: true, abortEarly: false})
        .then(() => {
          setModalContext({...modalContext, step: 1})
          return
        })
        .catch((e) => {
          const schemaErrors = e.inner?.map((err) => {
            return err.path
          })
          setBatchBonusErrorFields(schemaErrors)
          return
        })
    } else if (modalContext.step === 1 && modalContext.name === 'charge') {
      await postBatchBonus()
      return
    }
  }

  const SingleBonusModalBodyHandler = () => {
    return (
      <>
        <SearchWithSearchBtn
          value={clientEmail}
          onChange={(e) => {
            EmailHandler(e)
          }}
          searchPlaceHolder="Email"
          name="email"
          onPress={() => getClientData()}></SearchWithSearchBtn>
        {clientEmailError ? (
          <ModalBodyError
            errorMessage={`O sistema não encontrou um cliente com o e-mail ${clientEmail} no Compra Certa.`}
            errorTitle="Cliente não localizado"
            tips={tips}></ModalBodyError>
        ) : singleBonusForm.customerId === '' ? (
          <EmptyDiv></EmptyDiv>
        ) : (
          <ModalContainer>
            <SelectedClient
              name={singleBonusForm.customerName}
              document={singleBonusForm.document}
              email={singleBonusForm.customer}></SelectedClient>
            <ManualBonusForm
              errorFields={manualBonusErrorFields}
              description={singleBonusForm.description}
              setDescription={(value) =>
                setSingleBonusForm({...singleBonusForm, description: value})
              }
              daysToExpire={singleBonusForm.daysToExpire}
              setDaysToExpire={(value) =>
                setSingleBonusForm({...singleBonusForm, daysToExpire: value})
              }
              reasons={bonusReasons}
              reasonValue={singleBonusForm.reason}
              onChangeReasonValue={(value) => {
                setSingleBonusForm({...singleBonusForm, reason: value})
              }}
              moneyValue={singleBonusForm.value}
              onChangeMoneyValue={(value) => {
                setSingleBonusForm({...singleBonusForm, value: value})
              }}
              transactionType={singleBonusForm.transactionType}
              onChangeTransactionType={(value) => {
                setSingleBonusForm({...singleBonusForm, transactionType: value})
              }}
            />
          </ModalContainer>
        )}
      </>
    )
  }

  const BatchBonusModalBodyHandler = () => {
    return (
      <>
        {modalContext.step === 0 ? (
          <BatchDescriptionForm
            description={batchBonusData.description}
            setDescription={(value) => {
              setBatchBonusErrorFields([])
              setBatchBonusData({...batchBonusData, description: value})
            }}
            reasons={bonusReasons}
            errorFields={batchBonusErrorFields}
            reasonValue={batchBonusData.reason}
            onChangeReasonValue={(value) => {
              setBatchBonusErrorFields([])
              setBatchBonusData({...batchBonusData, reason: value})
            }}></BatchDescriptionForm>
        ) : (
          <BatchUploadForm
            file={file}
            setSetValue={(e) => {
              setIsBatchBonusFormated(true)
              setFile(e.target.files[0])
            }}></BatchUploadForm>
        )}
      </>
    )
  }
  return (
    <>
      <Container>
        <ScreenHeader title="Bonificação manual">
          <HeaderBtnWrapper>
            <Button
              text="+ Ajuste individual"
              backgroundColor={colors.font.purpleMain}
              onClick={() => {
                setModalContext({
                  ...modalContext,
                  name: 'single',
                  value: 'AJUSTE INDIVIDUAL',
                })
                setModalIsModalOpened(true)
              }}
            />
            <Button
              text="+ Ajuste em carga"
              backgroundColor={colors.font.purpleMain}
              onClick={() => {
                setModalContext({
                  name: 'charge',
                  value: 'AJUSTE POR CARGA',
                  step: 0,
                })
                setModalIsModalOpened(true)
              }}
            />
          </HeaderBtnWrapper>
        </ScreenHeader>
        <ScreenContent>
          <HeaderInputFilter
            title={`Bonificações: ${manualBonusData?.total ?? 0}`}
            optionsTypes={optionsType}
            optionsStatus={optionsStatus}
            icon={optionsStatus[0].icon}
            value={optionsStatus[0].value}
            label={optionsStatus[0].label}
            clearFilter={() => {
              setFilters(filtersInitialState)
            }}
            searchValue={filters.customer}
            onSearchValueChange={(value) =>
              setFilters({...filters, customer: value})
            }
            dateValue={filters?.includedAt?.toLocaleDateString() ?? ''}
            onChangeDateValue={(value) =>
              setFilters({...filters, includedAt: value[0]})
            }
            selectedOptionsType={filters.transactionType}
            handleOptionsType={(value) => {
              const index = optionsStatus.findIndex(
                (o) => o.label === value.label
              )
              setFilters({
                ...filters,
                transactionType: {
                  value: value.value,
                  label: value.label,
                  icon: <BsCheckLg size={15} fill={colors.font.purpleMain} />,
                },
              })
            }}
            selectedOptionsStatus={filters.processingStatus}
            handleOptionsStatus={(value) => {
              setFilters({
                ...filters,
                processingStatus: {
                  value: value.value,
                  label: value.label,
                  icon: <BsCheckLg size={15} fill={colors.font.purpleMain} />,
                },
              })
            }}
          />
          <WrapRules>
            <span> </span>
            <span>INCLUSÃO</span>
            <span>DESCRIÇÃO</span>
            <span>CLIENTE</span>
            <span>TIPO</span>
            <span>VALOR</span>
            <span>PROCESSAMENTO</span>
            <span> </span>
          </WrapRules>
          {isLoading ? (
            <Loader>
              <PulseLoader color="#8600a7" />
            </Loader>
          ) : (
            <>
              {manualBonusData.data.map((value, index) => (
                <ManualBonusRow
                  key={value.id}
                  data={
                    !value.multiData
                      ? {
                          includedAt: value.includedAt,
                          customer: value.customer,
                          description: value.description,
                          processingStatus: value.processingStatus,
                          transactionType: value.transactionType,
                          value: value.value,
                        }
                      : value.data
                  }
                  multiDataDescription={value.description}
                  multiDataProcessingStatus={value.processingStatus}
                  multiDataTotalValue={value.value}
                  multiDataTransactionType={value.transactionType}
                />
              ))}
              <Paginator
                totalItens={manualBonusData?.total ?? 0}
                itensPerPage={10}
                visiblePages={manualBonusData?.pages ?? 0}
                hoverColor={defaultTheme.colors.font.purpleDark}
                selectedPageColor={defaultTheme.colors.font.purpleDark}
                selectedPage={filters?.page + 1 ?? 1}
                setSelectedPage={(value) => {
                  setFilters({...filters, page: value ?? 1})
                }}
              />
            </>
          )}
        </ScreenContent>
      </Container>
      <WideModal
        onPressClose={() => {
          setManualBonusErrorFields([])
          setModalIsModalOpened(false)
          setIsBatchBonusFormated(true)
          setClientEmail('')
          setModalContext({...modalContext, step: 0})
          setBatchBonusErrorFields([])
          setFile(null)
        }}
        error={
          (modalContext.name === 'single' &&
            manualBonusErrorFields?.length > 0) ||
          (modalContext.name === 'charge' && batchBonusErrorFields?.length > 0)
            ? 'Preencha todos os campos para processar a bonificação'
            : modalContext.name === 'charge' &&
              batchBonusErrorFields?.length == 0 &&
              !isBatchBonusFormated &&
              'O arquivo não corresponde ao formato esperado'
        }
        footerLeft={
          modalContext.name == 'charge' &&
          modalContext.step === 1 && (
            <Button
              text={'Voltar'}
              backgroundColor={
                isDataProcessing
                  ? colors.font.placeHolder
                  : colors.font.purpleMain
              }
              onClick={() => {
                if (isDataProcessing) {
                  return
                }
                setModalContext({...modalContext, step: 0})
                setBatchBonusErrorFields([])
                setIsBatchBonusFormated(true)
                setFile(null)
              }}
              disabled={isDataProcessing}
            />
          )
        }
        footerRight={
          <Button
            text={
              modalContext.name == 'single'
                ? 'Processar'
                : modalContext.name == 'charge' && modalContext.step !== 0
                ? 'Processar'
                : 'Próximo'
            }
            backgroundColor={
              isDataProcessing
                ? colors.font.placeHolder
                : colors.font.purpleMain
            }
            disabled={isDataProcessing}
            onClick={() => !isDataProcessing && handleModalButtonAction()}
          />
        }
        isOpened={isModalOpened}
        title={modalContext.value}>
        {modalContext.name === 'single'
          ? SingleBonusModalBodyHandler()
          : BatchBonusModalBodyHandler()}
      </WideModal>
      <Toaster />
    </>
  )
}
export default ManualBonus
