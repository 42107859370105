import {format, sub, getDate, getMonth, getYear, lastDayOfMonth} from 'date-fns'
import br from 'date-fns/locale/pt-BR'

export const transformDateNames = (date: Date, quantity?: number): string => {
  if (quantity) {
    return format(sub(new Date(date), {months: quantity}), 'MMMM', {locale: br})
  }

  return format(new Date(date), 'MMMM', {locale: br})
}

export type DateTabProps = {
  date: Date
  month: string
  id: string
}

export const datesTab = [
  {
    id: '73',
    month: transformDateNames(new Date(), 3),
    date: sub(new Date(), {months: 3}),
  },
  {
    id: '62',
    date: sub(new Date(), {months: 2}),
    month: transformDateNames(new Date(), 2),
  },
  {
    id: '51',
    month: transformDateNames(new Date(), 1),
    date: sub(new Date(), {months: 1}),
  },
  {
    id: '40',
    date: new Date(),
    month: transformDateNames(new Date()),
  },
]

type GetMonthDayYearProps = {
  month: string | number
  day: string | number
  year: number
  lastDay: number
}

export const getMonthDayYear = (date: Date): GetMonthDayYearProps => {
  const month =
    getMonth(date) + 1 < 10 ? `0${getMonth(date) + 1}` : getMonth(date) + 1
  const day = getDate(date) < 10 ? `${getDate(date)}` : getDate(date)
  const year = getYear(date)
  const lastDay = getDate(lastDayOfMonth(date))

  return {
    month,
    day,
    year,
    lastDay,
  }
}
