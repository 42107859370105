import {ReactElement} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {Container} from './style'
import {Location, LocationDescriptor, LocationState} from 'history'

interface DrawerItemProps {
  pathName?: string
  text: string
  navTo:
    | LocationDescriptor<LocationState>
    | ((location: Location<LocationState>) => LocationDescriptor<LocationState>)
}

function DrawerItem({
  pathName = '',
  text,
  navTo,
}: DrawerItemProps): ReactElement {
  const location = useLocation()

  return (
    <Link
      style={{
        textDecoration: 'none',
      }}
      to={navTo}>
      <Container routeActive={pathName === location.pathname}>
        <span>{text}</span>
      </Container>
    </Link>
  )
}

export default DrawerItem
