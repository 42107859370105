import {createGlobalStyle} from 'styled-components'
import {ThemeType} from './themes/default'

export default createGlobalStyle<{theme: ThemeType}>`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Segoe UI', sans-serif;
    color: ${({theme}) => theme.colors.font.main};

  }

  ._ITSBs {
    fill: #51a176;
  }

  body {
    background: ${({theme}) => theme.colors.background};
    font-size: 16px;
  }



  input {
    outline: none;
    border: none;
  }

  button  {
    outline: none;
    border: none;
    cursor: pointer;
  }
`
