import styled from 'styled-components'

export const Article = styled.article`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  padding: 10px;
`

export const WrapArticle = styled.div`
  padding: 0px 15px;
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.colors.border.main};
`

export const SectionArticle = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
`

export const Section = styled.section`
  display: flex;
  width: 20%;
  justify-content: space-between;
`

export const TextSection = styled.span`
  font-weight: 300;
  color: ${({theme}) => theme.colors.font.main};
  line-height: 16px;
  font-size: 14px;
`

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
`

export const WrapContentText = styled.div`
  display: flex;
  padding: 0px 15px;
  justify-content: space-between;
  align-items: center;
`

export const TitleContent = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${({theme}) => theme.colors.font.main};
  line-height: 16px;
`
