import {AdonisPagination} from '~/types'
import api from '~/config/apiSauce'
import {createJsonDateHandler} from '~/utils'
import {CustomerData, ManualScorePageItem} from '~/types/ScoreManagerTypes'

const prefix = ''
export async function getManualScorePagination({
  page,
  limit,
  search,
  date,
}: {
  page?: number
  limit?: number
  search?: string
  date?: Date
}): Promise<AdonisPagination<ManualScorePageItem>> {
  const response = await api.get<AdonisPagination<ManualScorePageItem>>(
    prefix + '/manualscore',
    {
      page,
      limit,
      search,
      date,
    },
    {
      transformResponse: createJsonDateHandler(['validUntil', 'createdAt']),
    }
  )
  if (!response.ok) {
    throw new Error('erro ao coletar página de pontuação manual')
  }
  return response.data
}

export async function getCustomersData(
  customers: string[]
): Promise<CustomerData[]> {
  const response = await api.get<CustomerData[]>(
    prefix + '/manualscore/customer',
    {customers}
  )
  if (!response.ok) {
    throw new Error('erro ao coletar dados do cliente')
  }
  return response.data
}

export async function postSingleCustomer(params: {
  customer: string
  description: string
  reason: string
  value: number
  validity: number
}): Promise<void> {
  const response = await api.post<{id: string}>(prefix + '/manualscore', params)
  if (!response.ok) {
    throw new Error('não foi possível inserir essa pontuação manual')
  }
}

export async function postBatchCustomer(params: {
  reason: string
  validity: number
  bonuses: Array<{
    customer: string
    value: number
    description: string
  }>
}): Promise<void> {
  const response = await api.post<{id: string}>(
    prefix + '/manualscore/batch',
    params
  )
  if (!response.ok) {
    throw new Error('não foi possível inserir lote de pontuação manual')
  }
}
