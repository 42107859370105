import styled from 'styled-components'

interface CardContainer {
  width?: string
}

export const Container = styled.div<CardContainer>`
  width: ${({width}) => width ?? '100%'};
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 15px;
  min-height: 75px;
  background-color: ${({theme}) => theme.colors.background.white};
`

export const Header = styled.div`
  background-color: ${({theme}) => theme.colors.background.white};
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  min-height: 32px;
  padding: 14px 24px;
  border-bottom: 1px solid ${({theme}) => theme.colors.border.main};
`

export const Body = styled.div`
  padding: 8px;
  background-color: ${({theme}) => theme.colors.background.white};
  margin-bottom: 15px;
  &.withoutHeader {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
`
