import {useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {queryKeys} from '~/utils/queryKeys'
import {CurrentGoal} from '~/types'
import api from '~/config/apiSauce'

interface UseGoalIndicationProps {
  handleGoal: (referralGoal: number) => void
  goalCurrent: CurrentGoal
  isLoading: boolean
  isFetch: boolean
}

export const useGoalIndication = (): UseGoalIndicationProps => {
  const client = useQueryClient()
  const {indication, goal} = queryKeys
  const [isFetch, setIsFetch] = useState(false)

  const updateGoal = async (referralGoal: number) => {
    try {
      setIsFetch(true)
      await api.put('/referral/goal/config', {referralGoal})
    } catch (error) {
      console.log(error)
    } finally {
      setIsFetch(false)
    }
  }

  const getGoal = async () => {
    const response = await api.get('/referral/goal')
    const {data} = response
    return data as CurrentGoal
  }

  const {mutate: handleGoal} = useMutation(
    async (referralGoal: number) => updateGoal(referralGoal),
    {
      onSuccess: () => {
        client.invalidateQueries(indication)
      },
    }
  )

  const {data: goalCurrent, isLoading} = useQuery([indication, goal], getGoal, {
    placeholderData: {
      currentGoal: 0,
    },
  })

  return {
    isLoading,
    handleGoal,
    goalCurrent,
    isFetch,
  }
}
