import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const Title = styled.span`
  font-weight: 400;
  color: ${({theme}) => theme.colors.font.main};
  font-size: 14px;
  margin-left: 0.5vw;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
