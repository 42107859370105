import react, {ReactElement, useRef} from 'react'
import FileInput from '~/components/FileInput'
import {
  Container,
  Header,
  Message,
  MessageContainer,
  MessageHighLight,
} from './styles'
import React from 'react'

interface BatchUploadFormProps {
  file?: File
  setSetValue: react.ChangeEventHandler<HTMLInputElement>
}
const BatchUploadForm: react.FC<BatchUploadFormProps> = ({
  file,
  setSetValue,
}): ReactElement => {
  return (
    <Container>
      <Header>Upload e análise</Header>
      <FileInput
        value={''}
        onChange={(e) => {
          setSetValue(e)
        }}
        accepts={'.csv'}
        message={
          <MessageContainer>
            <Message>Use o modelo de planilha (CSV) &nbsp; </Message>
            <Message>com no maximo 50 linhas</Message>
          </MessageContainer>
        }
        placeHolder={!file ? 'Selecione a planilha com ajustes' : file.name}
      />
      <span>{!file && 'Nenhum novo ajuste foi carregado'}</span>
    </Container>
  )
}
export default BatchUploadForm
