import React, {CSSProperties, ReactNode} from 'react'
import {Header, InfoContainer, PanelContainer, Title} from './styles'

interface ScreenHeaderProps {
  title: string
  info?: ReactNode
  children?: ReactNode
  tabs?: Array<{
    title: string
  }>
  style?: CSSProperties
}

const ScreenHeader: React.FC<ScreenHeaderProps> = ({
  title,
  info,
  children,
  ...rest
}) => {
  return (
    <Header {...rest}>
      <PanelContainer>
        <Title>{title}</Title>
        {children}
      </PanelContainer>
      {info && <InfoContainer>{info}</InfoContainer>}
    </Header>
  )
}
export default ScreenHeader
