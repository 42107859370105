import {ReactElement, useEffect} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {ThemeProvider} from 'styled-components'
import GlobalStyles from '~/assets/styles/global'
import App from '~/components/App'
import AuthRoutes from './auth.routes'
import defaultTheme from '~/assets/styles/themes/default'
import {useAuthProvider} from '~/hooks/useAuthProvider'
import {keyStorageTokenLogin} from '~/utils/keystorage'
import api from '~/config/apiSauce'

export default function Route(): ReactElement {
  const {token, setTokenCurrent} = useAuthProvider()
  const haveToken = sessionStorage.getItem(keyStorageTokenLogin)
  const tokenCurrent = haveToken ? JSON.parse(haveToken) : null

  useEffect(() => {
    if (tokenCurrent) {
      setTokenCurrent(tokenCurrent)
      api.setHeaders({'x-token-api': tokenCurrent})
    }
  }, [])

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      <BrowserRouter>
        {token.length > 0 ? <App /> : <AuthRoutes />}
      </BrowserRouter>
    </ThemeProvider>
  )
}
