import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  width: 100%;
  height: 100vh;
  background-image: url(https://ok11static.oktacdn.com/fs/bco/7/fs01s25l0tfyF4uQC4x7);
  background-size: cover;
  background-repeat: no-repeat;
`

export const Content = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  min-width: 25vw;
  border-radius: 3px;
  background-color: ${({theme}) => theme.colors.background.white};
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
`
export const WrapImg = styled.div`
  display: flex;
  margin-top: 5vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`
export const Underline = styled.div`
  margin-top: 3vh;
  width: 100%;
  height: 1px;
  background-color: ${({theme}) => theme.colors.background.gray};
`

export const Title = styled.h3`
  font-weight: 600px;
  font-size: 15px;
  line-height: 1.5;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0vh 25px 5vh;
  gap: 15px;
`

export const ButtonSubmit = styled.button`
  margin-top: 3vh;
  background-color: linear-gradient(#007dc1, #0073b2);
  background-color: ${({theme}) => theme.colors.font.dark};
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-attachment: scroll;
  background-image: linear-gradient(rgb(0, 125, 193), rgb(0, 115, 178));
  background-size: auto;
  background-origin: padding-box;
  background-clip: border-box;
  width: 100%;
  padding: 3px 0px;
  border-radius: 4px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15),
    inset 0 1px 0 0 hsla(0, 0%, 100%, 0.1);
  line-height: 36px;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  color: ${({theme}) => theme.colors.background.white};
  transition: all 0.33s ease-in-out;
  &:hover {
    opacity: 0.7;
  }
`
