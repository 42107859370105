import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 30px;
  border-bottom: solid 1px ${({theme}) => theme.colors.border.modal};
  width: 100%;
`

export const Content = styled.div`
  margin-left: 40px;
  display: flex;
  flex-direction: row;
  gap: 60px;
`

export const Tab = styled.div<{activated: boolean}>`
  height: 30px;
  cursor: pointer;
  margin-bottom: -2px;
  ${({activated, theme}) =>
    activated && `border-bottom: solid 3px ${theme.colors.secondary.dark};`}
  color: ${({theme}) => theme.colors.secondary.dark};
  ${({activated}) => activated && 'font-weight: bold;'}
`
