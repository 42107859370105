export const queryKeys = {
  indication: 'indication',
  indicationMonth: 'indicationMonth',
  indicationYear: 'indicationYear',
  indicationGoal: 'indicationGoal',
  movements: 'movements',
  consolidation: 'consolidation',
  goal: 'goal',
  breakageExpire: 'breakageExpire',
  breakagePerMonth: 'breakagePerMonth',
  breakageSum: 'breakageSum',
}
