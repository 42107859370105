import styled from 'styled-components'

interface ButtonProps {
  backgroundColor: string
  textColor: string
}

export const Overlay = styled.div`
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`

export const Container = styled.div`
  width: 70%;
  max-width: 90%;
  min-width: 1315px;
  height: 85%;
  background: #fff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
`
export const ModalHeader = styled.div`
  width: 100%;
  height: 40px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px 12px 0 0;
  border-bottom: 1px solid ${({theme}) => theme.colors.border.modal};
  background: ${({theme}) => theme.colors.background.main};

  span {
    font-size: 0.875rem;
  }
`
export const Title = styled.span`
  color: ${({theme}) => theme.colors.font.main};
`
export const HeaderButton = styled.button`
  border-radius: 0;
  background-color: transparent;
  transform: all() 0.5s;
  &:hover {
    opacity: 0.7;
  }
`
export const ModalBody = styled.div`
  width: 100%;
  height: 100% - 96px;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  span {
    font-size: 0.875rem;
  }
`

export const Button = styled.button<ButtonProps>`
  height: 32px;
  padding: 0 22px;
  background: ${({backgroundColor}) => backgroundColor};
  border-radius: 8px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);

  span {
    color: ${({textColor}) => textColor};
    font-size: 0.875rem;
    font-weight: 600;
  }
`

export const ModalFooter = styled.div`
  width: 100%;
  height: 56px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  gap: 16px;
  border-radius: 0 0 12px 12px;
  border-top: 1px solid ${({theme}) => theme.colors.border.modal};
  background: ${({theme}) => theme.colors.background.main};
  bottom: 0;
  left: 0;
  right: 0;
`
export const FooterRight = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  align-self: center;
  justify-content: flex-end;
  flex: 1;
`
export const FooterLeft = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  align-self: center;
  justify-content: flex-start;
  flex: 1;
`

export const ErrorMsg = styled.span`
  color: ${({theme}) => theme.colors.font.error};
  font-weight: 400;
  flex-direction: row;
  align-self: center;
  text-align: end;
  font-style: normal;
  font-size: 14px;
  flex: 2;
`
