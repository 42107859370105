import {QueryClient} from 'react-query'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      notifyOnChangeProps: 'tracked',
      staleTime: 600000, // 10 minutos
      cacheTime: 900000, // 15 minutos, cache sempre maior que staleTime
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
})
