import styled from 'styled-components'

export const TextLabel = styled.span`
  font-size: 14px;
  padding-right: 15px;
  width: 200px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
`
