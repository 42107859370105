import {ReactElement} from 'react'
import {ApexChartGauge} from '../ApexChartGauge'
import BarChart from '../BarChart'
import {Loading} from '../Load'
import {formatCurrency, getCategories, getMonthYearLong} from './function'
import DoubleCard from './DoubleCardsBreakage'
import SingleCard from './SingleCardBreakage'
import {Container, ContentHeader, Title, Body, BodyDouble} from './styles'
import {useBreakage} from './useBreakage'

export interface CustomSelectClusterProps {
  value: string
  label: string
  icon?: ReactElement
}

export default function Breakage(): ReactElement {
  const {
    isLoadingExpire,
    expire,
    perMonth,
    isLoadingSummary,
    summary,
    isLoadingPerMonth,
  } = useBreakage()

  if (isLoadingExpire || isLoadingPerMonth || isLoadingSummary)
    return <Loading />
  const categoriesExpire = getCategories(expire.axisX)
  const categoriesExpireLong = getMonthYearLong(expire.axisX)
  const dataExpire = expire.data[0].axisY.map((it) => it / 100)
  const categoriesPerMonth = getCategories(perMonth.axisX)
  const monthCurrent = summary.find((it) => it.name === 'Mês atual')
  const monthLast = summary.find((it) => it.name === 'Mês anterior')
  const lastThreeMonths = summary.find((it) => it.name === 'Últimos 3 Meses')
  const lastTwelveMonths = summary.find((it) => it.name === 'Últimos 12 Meses')

  const breakage = {
    options: {
      chart: {
        id: 'expired-credits',
        width: '100%',
        height: '100%',
        toolbar: {
          show: false,
        },
        fontFamily: `Segoe UI Normal, Segoe UI,sans-serif`,
      },
      grid: {
        borderColor: '#d7d7d7',
        strokeDashArray: 0,
      },
      xaxis: {
        categories: categoriesExpire.map((it) => it) as unknown as string[],
      },
      labels: {
        show: true,
        style: {
          fontSize: '11px',
        },
      },
      axisTicks: {
        show: false,
      },
      yaxis: {
        tickAmount: 5,
        axisBorder: {
          show: true,
          color: '#d7d7d7',
        },
        labels: {
          formatter: (val: number) => {
            return `R$ ${val}`
          },
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#d7d7d7',
          width: 2,
        },
      },
      tooltip: {
        enabled: true,
        onDatasetHover: {
          highlightDataSeries: false,
        },
        custom: ({series, seriesIndex, dataPointIndex}) => {
          return `
           <style>
             .container-tooltip-per-month-expired {
               display: flex;
               flex-direction: column;
               border-radius: 7px;
               padding: 7px 0px;
               background-color: #fff;
             }
             .custom-series-tooltip-per-month-expired {
               color: #6A6D70;
               opacity: 0.7;
               font-size: 11px;
               font-weight: 400;
               padding: 5px 15px;
               font-family: 'Segoe UI Normal','Segoe UI', sans-serif;
             }
             .custom-series-tooltip-per-month-expired:first-letter {
               text-transform: uppercase;
             }
             .wrap-body-tooltip-expired {
                display: flex;
                flex-direction: column;
                border-top: 1.5px solid #d7d7d7;
                justify-content: center;
                align-items:center;
                gap: 20px;
                padding: 10px 20px;
             }
              .content-tooltip-expired {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              }
             .content-value-per-expired {
               font-size: 14px;
               color: #32363B;
               font-weight: 400;
               margin-bottom: 5px;
             }
           </style>
           <div class="container-tooltip-per-month-expired">
             <span class="custom-series-tooltip-per-month-expired">  ${
               categoriesExpireLong[dataPointIndex].month
             } de  ${categoriesExpireLong[dataPointIndex].year}  </span>
             <div class="wrap-body-tooltip-expired">
                <div class="content-tooltip-expired">
                <span class="content-value-per-month-expired"> ${formatCurrency(
                  series[seriesIndex][dataPointIndex] * 100
                )} </span>
                <span class="content-credit-expired">
                  Créditos
                </span>
                 <span class="content-credit-expired">
                  Expirados
                 </span>
                </div>
             </div>
           </div>
          `
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        colors: ['rgba(53, 74, 95, 0.6)'],
      },
      plotOptions: {
        bar: {
          columnWidth: '80%',
          barHeight: '50%',
        },
      },
      states: {
        normal: {
          filter: {
            type: 'normal',
            value: 0.1,
          },
        },
        hover: {
          filter: {
            type: 'darken',
            value: 0.2,
          },
        },
      },
    },
    series: [
      {
        name: 'series-1',
        data: dataExpire,
      },
    ],
  }

  const period = {
    options: {
      chart: {
        id: 'period-breakage',
        width: '100%',
        toolbar: {
          show: false,
        },
        fontFamily: `Segoe UI Normal, Segoe UI,sans-serif`,
      },
      grid: {
        borderColor: '#d7d7d7',
        strokeDashArray: 0,
      },
      xaxis: {
        type: 'category',
        categories: categoriesPerMonth.map((it) => it) as unknown as string[],
        labels: {
          show: true,
          style: {
            fontSize: '12px',
          },
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        tickAmount: 7,
        axisBorder: {
          show: true,
          color: '#d7d7d7',
        },
        labels: {
          formatter: (val: number) => {
            return `${val}%`
          },
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#d7d7d7',
          width: 2,
        },
      },
      tooltip: {
        enabled: true,
        onDatasetHover: {
          highlightDataSeries: false,
        },
        custom: ({series, seriesIndex, dataPointIndex}) => {
          const findYear = perMonth.axisX[dataPointIndex]
          const lastYearCredits = perMonth.meta[findYear].lastYearCredits
          const monthExpirations = perMonth.meta[findYear].monthExpirations
          return `
            <style>
             .container-tooltip-per-month {
               display: flex;
               flex-direction: column;
               border-radius: 7px;
               padding: 7px 0px;
               min-width: 240px;
               background-color: #fff;
             }
             .custom-series-tooltip-per-month {
               color: #6A6D70;
               opacity: 0.7;
               font-size: 11px;
               font-weight: 400;
               padding: 5px 15px;
               font-family: 'Segoe UI Normal','Segoe UI', sans-serif;
             }
             .custom-series-tooltip-per-month:first-letter {
               text-transform: uppercase;
             }
             .wrap-body-tooltip {
                display: flex;
                flex-direction: row;
                border-top: 1.5px solid #d7d7d7;
                justify-content: space-between;
                align-items: flex-start;
                gap: 20px;
                padding: 10px 20px;
             }
             .content-value-per-month {
               font-size: 14px;
               color: #32363B;
               font-weight: 400;
               margin-bottom: 5px;
             }
             .content-tooltip {
               display: flex;
               flex-direction: column;
               justify-content: center;
               align-items: center;
             }
             .content-percentage-per-month {
                font-size: 14px;
                color: #32363B;
                font-weight: 400;
                margin-bottom: 5px;
             }
             .content-credit{
               font-size: 10px;
               text-transform: uppercase;
             }
            </style>
            <div class="container-tooltip-per-month">
             <span class="custom-series-tooltip-per-month">
              Breakage ${series[seriesIndex][dataPointIndex]}%
             </span>
             <div class="wrap-body-tooltip">
                <div class="content-tooltip">
                <span class="content-value-per-month">
                  ${formatCurrency(lastYearCredits)}
                </span>
                <span class="content-credit">
                  Créditos bonificados
                </span>
                <span class="content-credit">
                  (A-1)
                </span>
                </div>
                <div class="content-tooltip">
                 <span class="content-percentage-per-month"> ${formatCurrency(
                   monthExpirations
                 )} </span>
                  <span class="content-credit">
                    Créditos expirados
                 </span>
                </div>
             </div>
           </div>
          `
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        colors: ['#61017999'],
      },
      plotOptions: {
        bar: {
          columnWidth: '80%',
        },
      },
      states: {
        normal: {
          filter: {
            type: 'normal',
            value: 0.1,
          },
        },
        hover: {
          filter: {
            type: 'darken',
            value: 0.2,
          },
        },
      },
    },
    series: [
      {
        name: 'series-1',
        data: perMonth.data[0].axisY,
      },
    ],
  }

  return (
    <Container>
      <ContentHeader>
        <Title>Breakage</Title>
      </ContentHeader>
      <BodyDouble>
        <DoubleCard customWidth={30} title="Breakage">
          <ApexChartGauge
            monthCurrent={monthCurrent && monthCurrent.rate}
            monthLast={monthLast && monthLast.rate}
            lastThreeMonths={lastThreeMonths && lastThreeMonths.rate}
            lastTwelveMonths={lastTwelveMonths && lastTwelveMonths.rate}
          />
        </DoubleCard>
        <DoubleCard customWidth={67} title="Créditos a expirar">
          <BarChart
            options={breakage.options}
            series={breakage.series}
            width="700"
            height="200"
          />
        </DoubleCard>
      </BodyDouble>
      <Body>
        <SingleCard text="Breakage mensal">
          <BarChart
            options={period.options}
            series={period.series}
            width="1050"
            height="350"
          />
        </SingleCard>
      </Body>
    </Container>
  )
}
