import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: -30px;
`

export const ButtonContainer = styled.div`
  margin-top: 20px;
`

export const FilterTitle = styled.div`
  margin-top: 20px;
  border-bottom: 1px solid ${({theme}) => theme.colors.font.purpleMain};
  color: ${({theme}) => theme.colors.font.purpleMain};
  width: 300px;
  padding-left: 10px;
  padding-bottom: 10px;
`

export const FilterContainer = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
  height: 78px;
  width: 300px;
`

export const FilterItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`

export const BodyContainer = styled.div`
  display: flex;
  margin-top: 20px;
  margin-left: -40px;
  margin-right: -40px;
  width: calc(100% + 80px);
  padding: 20px;
  flex: 1;
  background-color: ${({theme}) => theme.colors.background.whiteGray};
`

export const FilterHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
