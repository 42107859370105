import react from 'react'
import {
  Container,
  ErrorHeader,
  ErrorMessage,
  Tip,
  TipsContainer,
} from './styles'

interface ModalBodyErrorProps {
  tips: string[]
  errorMessage: string
  errorTitle: string
}
const ModalBodyError: react.FC<ModalBodyErrorProps> = ({
  errorTitle,
  errorMessage,
  tips,
}) => {
  return (
    <Container>
      <ErrorHeader>{errorTitle}</ErrorHeader>
      <ErrorMessage>{errorMessage}</ErrorMessage>
      <TipsContainer>
        {tips.map((value) => (
          <Tip key={value}>{value}</Tip>
        ))}
      </TipsContainer>
    </Container>
  )
}
export default ModalBodyError
