import styled from 'styled-components'

export const PopoverContainer = styled.div<{
  anchor?: {x: number; y: number}
}>`
  position: absolute;
  z-index: 3;
  &.bottom {
    ${({anchor}) =>
      anchor !== undefined
        ? `top: ${anchor.y + 4}px;`
        : 'top: calc(100% + 4px);'}
  }
  &.top {
    ${({anchor}) =>
      anchor !== undefined
        ? `top: ${anchor.y - 4}px;`
        : 'bottom: calc(100% + 4px);'}
  }
  &.right {
    ${({anchor}) =>
      anchor !== undefined ? `left: ${anchor.x + 4}px;` : 'left: 0;'}
  }
  &.left {
    ${({anchor}) =>
      anchor !== undefined ? `left: ${anchor.x - 4}px;` : 'right: 0;'}
  }
  &.top.right {
    transform: ${({anchor}) =>
      anchor !== undefined ? 'translate(-100%, -100%)' : 'translate(0, -4px)'};
  }
  &.top.left {
    transform: ${({anchor}) =>
      anchor !== undefined ? 'translate(0, -100%)' : 'translate(0, -4px)'};
  }
  &.bottom.right {
    transform: ${({anchor}) =>
      anchor !== undefined ? 'translate(-100%, 0)' : 'translate(0, 4px)'};
  }
  &.bottom.left {
    transform: ${({anchor}) =>
      anchor !== undefined ? '0' : 'translate(0, 4px)'};
  }
`

export const PopoverWrapper = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
`

export const Header = styled.div`
  background-color: ${({theme}) => theme.colors.input.background};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 8px;
  border-bottom: 1px solid ${({theme}) => theme.colors.border.main};
`

export const Body = styled.div`
  padding: 8px;
  background-color: ${({theme}) => theme.colors.background.white};
`

export const Footer = styled.div`
  background-color: ${({theme}) => theme.colors.input.background};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 8px;
  border-top: 1px solid ${({theme}) => theme.colors.border.main};
`
