import React, {FC, ReactElement, useState} from 'react'
import PageContainer from '~/components/PageContainer'
import WhiteHeader from '~/components/WhiteHeader'
import Row from '~/components/Row'
import Column from '~/components/Column'
import {Panel} from '~/components/Panel'
import Button from '~/components/Button'
import {CsvPanel} from '~/pages/ManualScoreBatchCreation/Components/CsvPanel'
import {Divider, InputContainer, InputContent} from '~/components/Styled'
import Input from '~/components/Input'
import {InputNumber} from '~/components/InputNumber'
import * as yup from 'yup'
import {ValidationError} from 'yup'
import toast from 'react-hot-toast'
import {useQueryClient} from 'react-query'
import {useHistory} from 'react-router-dom'
import {postBatchCustomer} from '~/services/scoreManagerService'

export const ManualScoreBatchCreation: FC = (): ReactElement => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const [formErrors, setFormErrors] = useState<Record<string, boolean>>({})
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [formData, setFormData] = useState({
    bonuses: [],
    reason: '',
    validity: undefined,
  })

  const submit = async () => {
    for (const bonus of formData.bonuses) {
      if (bonus.valid === undefined) {
        return toast.error('Aguarde até todos os clientes serem válidados')
      }
      if (!bonus.valid) {
        return toast.error('Existem clientes inválidos')
      }
    }
    const schema = yup.object({
      bonuses: yup.array().min(1, 'É necessário adicionar ao menos um cliente'),
      reason: yup.string().required('O campo Motivo é obrigatório'),
      validity: yup
        .number()
        .required('O campo Validade é obrigatório')
        .min(1, 'A validade mínima é 1'),
    })
    try {
      schema.validateSync(formData, {abortEarly: false})
    } catch (e: any) {
      e.errors.map((error) => {
        toast.error(error)
      })
      return handleYupError(e)
    }
    try {
      await postBatchCustomer({
        ...formData,
        validity: Number(formData.validity),
      })
      await queryClient.refetchQueries({queryKey: ['manualScorePage']})
      history.push('/pontuacao-manual')
    } catch (e: any) {
      toast.error(e.message)
    }
  }
  const handleYupError = (error: ValidationError) => {
    setFormErrors(
      error.inner.reduce((obj, error) => ({...obj, [error.path]: true}), {})
    )
  }

  return (
    <PageContainer>
      <WhiteHeader backTo="/pontuacao-manual" />
      <Row style={{padding: '0 24px'}}>
        <Column desktop={12} mobile={12} tablet={12}>
          <Panel
            footer={
              <Button
                text="Processar"
                disabled={disableSubmit}
                onClick={async () => {
                  setDisableSubmit(true)
                  await submit()
                  setDisableSubmit(false)
                }}
              />
            }>
            <CsvPanel
              data={formData.bonuses}
              onChange={(bonuses) =>
                setFormData((prev) => ({...prev, bonuses}))
              }
            />
            <Row>
              <Column desktop={6} tablet={12} mobile={12}>
                <InputContainer>
                  <label htmlFor="name">Motivo</label>
                  <InputContent>
                    <Input
                      hasError={formErrors.reason}
                      onFocus={() =>
                        setFormErrors((errors) => ({
                          ...errors,
                          reason: false,
                        }))
                      }
                      id="name"
                      value={formData.reason}
                      onChange={(e) =>
                        setFormData((formData) => ({
                          ...formData,
                          reason: e.target.value,
                        }))
                      }
                    />
                  </InputContent>
                </InputContainer>
              </Column>
            </Row>
            <Row>
              <Column desktop={6} tablet={12} mobile={12}>
                <InputContainer>
                  <label htmlFor="validityDays">Validade (em dias)</label>
                  <InputContent style={{flex: 0.3}}>
                    <InputNumber
                      hasError={formErrors.validity}
                      onFocus={() =>
                        setFormErrors((errors) => ({
                          ...errors,
                          validityDays: false,
                        }))
                      }
                      id="validityDays"
                      value={formData.validity}
                      onChange={(e) =>
                        setFormData((formData) => ({
                          ...formData,
                          validity: e.target.value,
                        }))
                      }
                    />
                  </InputContent>
                </InputContainer>
              </Column>
            </Row>
          </Panel>
        </Column>
      </Row>
      <Divider />
    </PageContainer>
  )
}
