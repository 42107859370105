import styled from 'styled-components'
import {MdKeyboardArrowLeft} from 'react-icons/md'

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  background-color: ${({theme}) => theme.colors.background.white};
  min-height: 68px;
  span {
    font-weight: 400;
    font-size: 10px;
    text-transform: uppercase;
    color: ${({theme}) => theme.colors.font.main};
  }
  p {
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    color: ${({theme}) => theme.colors.font.main};
  }
`

export const SectionLeft = styled.section`
  display: flex;
  align-items: center;
  gap: 56px;
`

export const WrapBack = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  h3 {
    font-size: 18px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.font.main};
  }
`

export const Back = styled(MdKeyboardArrowLeft)`
  fill: ${({theme}) => theme.colors.font.purpleMain};
  &:hover {
    fill: ${({theme}) => theme.colors.font.purpleDark};
  }
`
