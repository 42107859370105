import {useInfiniteQuery, InfiniteData} from 'react-query'
import {Movement} from '~/types'
import {keyStorageQuantityData} from '~/utils/keystorage'
import api from '~/config/apiSauce'
import {queryKeys} from '~/utils/queryKeys'
import toast from 'react-hot-toast'
import {useMovementProvider} from '~/hooks/useMovementProvider'

interface MovementProps {
  dataMovement: InfiniteData<Movement>
  hasNextPage: boolean
  isFetching: boolean
  isLoading: boolean
  quantity: number
  fetchNextPage: () => void
  isError: boolean
  error: unknown
}

export const useMovement = (): MovementProps => {
  const {dayMin, dayMax, yearMonth, filterByEmail, filterByType} =
    useMovementProvider()
  let quantity = 0

  const fetchData = async (url: string): Promise<Movement> => {
    try {
      const {data} = (await api.get(url)) as {data: Movement}
      return data
    } catch (error) {
      toast.error('Something went wrong')
    }
  }

  const getUrl = () => {
    if (filterByType.value !== 'movement') {
      return `/movements/detailing?type=${filterByType.value}&startDate=${yearMonth}-${dayMin}&endDate=${yearMonth}-${dayMax}`
    }
    if (filterByEmail.length > 4) {
      return `/movements/detailing?email=${filterByEmail}&startDate=${yearMonth}-${dayMin}&endDate=${yearMonth}-${dayMax}`
    }
    return `/movements/detailing?limit=30&startDate=${yearMonth}-${dayMin}&endDate=${yearMonth}-${dayMax}`
  } //  detailing?page=1&limit=6&startDate=2020-01-01&endDate=2020-01-31

  const initialUrl = getUrl()

  const {
    data: dataMovement,
    hasNextPage,
    isLoading,
    isFetching,
    fetchNextPage,
    error,
    isError,
  } = useInfiniteQuery(
    [`${queryKeys.movements}`, `${yearMonth}`, `${dayMin}`, `${dayMax}`],
    ({pageParam = initialUrl}) => fetchData(pageParam),
    {
      getNextPageParam: (lastPageData) =>
        lastPageData.meta.pagination.next || undefined,
    }
  )

  if (error) {
    console.log('generate error', error && error.toString())
  }

  if (dataMovement && Object.keys(dataMovement).length > 0) {
    sessionStorage.setItem(
      keyStorageQuantityData,
      JSON.stringify(
        dataMovement.pages.map((value) => value.meta.pagination.count)[0]
      )
    )
    const quantityStorage = sessionStorage.getItem(keyStorageQuantityData)
    quantity = quantityStorage
      ? JSON.parse(quantityStorage)
      : dataMovement.pages.map((value) => value.meta.pagination.count)[0]
  }

  return {
    dataMovement,
    hasNextPage,
    isLoading,
    isFetching,
    quantity,
    fetchNextPage,
    isError,
    error,
  }
}
