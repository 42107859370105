import {ReactElement} from 'react'
import {Route, Switch} from 'react-router-dom'
import Indications from '~/pages/Indications'
import Financial from '~/pages/Financial'
import FinancialDetails from '~/pages/FinancialDetails'
import Home from '~/pages/Home'
import Breakage from '~/components/Breakage'
import BonusRules from '~/pages/BonusRules'
import ManualBonus from '~/pages/ManualBonus'
import BonusRulesDetails from '~/pages/BonusRulesDetails'
import BonusRulesCreation from '~/pages/BonusRulesCreation'
import {BonusRulesEdition} from '~/pages/BonusRulesEdition'
import ManualScore from '~/pages/ManualScore'
import {ManualScoreUnitCreation} from '~/pages/ManualScoreUnitCreation'
import {ManualScoreBatchCreation} from '~/pages/ManualScoreBatchCreation'
import {CustomerAnalysis} from '~/pages/CustomerAnalysis'
import {CustomerProfile} from '~/pages/CustomerProfile'

export default function AppRoutes(): ReactElement {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/breakage" exact component={Breakage} />
      <Route path="/indicacoes" exact component={Indications} />
      <Route path="/financeiro" exact component={Financial} />
      <Route path="/financeiro/detalhes" exact component={FinancialDetails} />
      <Route path="/regras-bonificacao" exact component={BonusRules} />
      <Route
        path="/regras-bonificacao/novo"
        exact
        component={BonusRulesCreation}
      />
      <Route
        path="/regras-bonificacao/editar/:id"
        component={BonusRulesEdition}
      />
      <Route path="/cliente/:email" component={CustomerProfile} />
      <Route path="/cliente" component={CustomerAnalysis} />
      <Route path="/cliente" component={CustomerAnalysis} />
      <Route path="/regras-bonificacao/:id" component={BonusRulesDetails} />
      <Route path="/bonificacao-manual" exact component={ManualBonus} />
      <Route path="/pontuacao-manual" exact component={ManualScore} />
      <Route
        path="/pontuacao-manual/novo"
        exact
        component={ManualScoreUnitCreation}
      />
      <Route
        path="/pontuacao-manual/lote"
        exact
        component={ManualScoreBatchCreation}
      />
    </Switch>
  )
}
