import React, {FC, useEffect, useState} from 'react'
import {PageHeader} from '~/components/PageHeader'
import {FaFilter} from 'react-icons/fa'
import {Button} from './style'
import {useTheme} from 'styled-components'
import {GoSearch} from 'react-icons/go'
import {CollapsedInput} from '~/components/CollapsedInput'

interface ReferralFilterBarProps {
  onClickCsvButton: () => any
  count: number
  onChange: (values: {referredEmail: string}) => void
}

export const ReferralFilterBar: FC<ReferralFilterBarProps> = ({
  onClickCsvButton,
  count,
  onChange,
}) => {
  const theme = useTheme()
  const [referredEmail, setReferredEmail] = useState('')
  useEffect(() => {
    const data = {
      referredEmail: referredEmail || undefined,
    }
    const timeout = setTimeout(() => {
      onChange(data)
    }, 500)
    return () => clearTimeout(timeout)
  }, [referredEmail])
  return (
    <PageHeader title={`${count} indicados localizados`}>
      <CollapsedInput
        icon={GoSearch}
        value={referredEmail}
        onChange={(e) => setReferredEmail(e.target.value)}
      />
      <Button
        onClick={onClickCsvButton}
        style={{
          color: theme.colors.font.purpleMain,
          width: 'auto',
          paddingLeft: 20,
          paddingRight: 20,
          fontWeight: 'bold',
        }}>
        Exportar lista de indicados em .csv
      </Button>
      <Button
        onClick={() => {
          setReferredEmail('')
        }}>
        <FaFilter />
      </Button>
    </PageHeader>
  )
}
