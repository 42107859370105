import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`

export const WrapContent = styled.div`
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

export const Title = styled.span`
  text-transform: uppercase;
  margin-left: 20px;
  color: ${({theme}) => theme.colors.font.main};
  font-size: 14px;
  font-weight: 300;
`

export const Hr = styled.hr`
  padding: 0px 0;
  border: 1px ridge ${({theme}) => theme.colors.background.disabled};
  margin-top: 16px;
`
