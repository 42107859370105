import React, {FC} from 'react'
import Button from '~/components/Button'
import {Modal} from '~/components/Modal'
import {useTheme} from 'styled-components'
import {ModalFooter} from './styles'
import {enableBonusRule} from '~/services/bonusRulesService'
import toast from 'react-hot-toast'
import {useQueryClient} from 'react-query'

interface EnableBonusRulesParams {
  onClose?: () => void
  isOpen: boolean
  bonusId: string
  name: string
}

export const EnableBonusRules: FC<EnableBonusRulesParams> = ({
  isOpen,
  bonusId,
  onClose,
  name,
}) => {
  const queryClient = useQueryClient()
  const theme = useTheme()

  const handleEnable = async () => {
    try {
      await enableBonusRule(bonusId)
      toast.success('A regra foi ativada com sucesso')
      await queryClient.refetchQueries({queryKey: ['bonusRules']})
      await queryClient.refetchQueries({queryKey: ['bonusRulesPage']})
      await queryClient.refetchQueries({queryKey: ['bonusRulesEdit']})
    } catch (e: any) {
      toast.error(e.message)
    }
    onClose()
  }

  return (
    <Modal
      width="350px"
      isOpen={isOpen}
      header="ATIVAÇÃO"
      footer={
        <ModalFooter>
          <Button
            onClick={handleEnable}
            text="Ativar"
            backgroundColor={theme.colors.font.purpleDark}
          />
          <Button
            text="Cancelar"
            onClick={() => onClose()}
            backgroundColor={theme.colors.font.lighGray}
            textColor={theme.colors.font.purpleDark}
            borderColor={theme.colors.border.modal}
          />
        </ModalFooter>
      }>
      <p>Tem certeza que gostaria de ativar a regra</p>
      <p>&quot;{name}&quot;</p>
    </Modal>
  )
}
