const defaultTheme = {
  colors: {
    background: {
      table: {
        evenRow: '#FFFFFF',
        oddRow: '#FAFAFA',
        evenErrorRow: '#faedf2',
        oddErrorRow: '#fae1ea',
      },
      lightPurple: '#FEF7FF',
      main: '#F7F7F7',
      disabled: '#FAFAFA',
      white: '#FFFFFF',
      gray: '#6A6D70',
      red: 'rgba(255, 228, 228,0.3)',
      whiteGray: 'rgba(247, 247, 247, 1)',
      whiteActive: 'rgba(249, 249, 249, 1)',
      darkGray: '#e6e6e6',
      greenTag: '#b5eab7',
      badge: {
        error: '#eab5c8',
        info: '#b5ddea',
        success: '#b5eab7',
        warning: '#eae2b5',
        disabled: '#e6e6e6',
      },
      step: {
        enabled: '#8600a7',
        disabled: '#969696',
        footerColor: '#f9f9f9',
      },
      calendar: {
        weekend: '#e6e6e6',
        days: '#f9f9f9',
        hover: '#b666ca',
        daySelected: '#8600a7',
        weekendSelected: '#500064',
        hoverSelected: '#9e33b9',
        today: '#e7cced',
        range: '#9e33b9',
      },
    },
    primary: {
      main: '#354A5F',
    },
    primaryYellow: {
      main: '#FFBE00',
      light: '#FCE7AB',
    },
    secondary: {
      main: '#8600a7',
      dark: '#610179',
      hover: '#1479B2',
      lighterHover: '#EDF7FF',
    },
    clusters: {
      brass: '#808080',
      bronze: '#f78800',
      silver: '#969696',
      gold: '#ffbe00',
      colab: '#8600a7',
    },
    button: {
      default: '#8600a7',
    },
    input: {
      background: '#F9F9F9',
      backgroundGradient: 'linear-gradient(90deg, #1479B2 50%, #168CCC 100%)',
      disabled: '#f2f2f2',
      error: '#fbecec',
    },
    border: {
      main: '#D7D7D7',
      input: {
        light: '#e6e6e6',
        dark: '#aaa',
        green: '#0D6733',
        red: '#A20000',
        whiteDark: '#dbdbdb',
      },
      modal: '#E7E7E7',
    },
    menu: {
      activated: '#f9f9f9',
      disabled: '#f2f2f2',
      hover: '#ffffff',
    },
    font: {
      dot: {
        error: '#cc4373',
        info: '#43accc',
        success: '#43cc48',
        warning: '#ccb743',
        disabled: '#AAAAAA',
      },
      badge: {
        error: '#755b64',
        info: '#5b6f75',
        success: '#5b755c',
        warning: '#75715b',
        disabled: '#737373',
      },
      menu: {
        disabled: '#aaaaaa',
        activated: '#6F7275',
      },
      disabled: '#cdcdcd',
      white: '#ffffff',
      black: '#000000',
      main: '#6F7275',
      dark: '#32363B',
      active: '#37833B',
      greenTag: '#32363b',
      purpleMain: '#8600a7',
      purpleDark: '#610178',
      purpleThird: 'rgba(97, 1, 120, 0.2)',
      acentoUltraLight: '#FEF7FF',
      placeHolder: '#AAA',
      purpleLight: '#fdf3ff',
      lighGray: '#fcfcfc',
      error: '#A20000',
    },
    shadow: {
      main: '1px 1px 4px rgba(0, 0, 0, 0.25)',
    },
  },
}

export type ThemeType = typeof defaultTheme

export default defaultTheme
