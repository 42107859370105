import React, {FC} from 'react'
import {PageHeader} from '~/components/PageHeader'
import {Button} from './style'
import {useTheme} from 'styled-components'

interface ListCustomerFilterBarProps {
  onClickCsvButton: () => any
  count: number
}

export const ListCustomerFilterBar: FC<ListCustomerFilterBarProps> = ({
  onClickCsvButton,
  count,
}) => {
  const theme = useTheme()
  return (
    <PageHeader title={`${count} clientes encontrados`}>
      <Button
        onClick={onClickCsvButton}
        style={{
          color: theme.colors.font.purpleMain,
          width: 'auto',
          paddingLeft: 20,
          paddingRight: 20,
          fontWeight: 'bold',
        }}>
        Exportar extrato em .csv
      </Button>
    </PageHeader>
  )
}
