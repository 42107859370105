import {ReactElement, Fragment, useRef, HTMLAttributes, ReactNode} from 'react'
import {IoClose} from 'react-icons/io5'
import {useTheme} from 'styled-components'
import {Background, Modal, Header, Title, Content, Footer} from './styles'
import {Button} from '~/components/Indications/styles'

interface ModalIndicationProps extends HTMLAttributes<HTMLDivElement> {
  opened: boolean
  closeModal: () => void
  childrenFooter: ReactNode
  childrenContent: ReactNode
  title: string
  haveIconClose?: boolean
}

type CustomStyles = {
  havePadding: boolean
  customHeight: string
  customWidth: string
  positionTop: number
  positionRight: string
  titleHeaderIsUppercase: boolean
}

type ModalCommonProps = ModalIndicationProps & Partial<CustomStyles>

export default function ModalCommon({
  opened,
  havePadding = true,
  childrenContent,
  childrenFooter,
  customHeight,
  customWidth,
  positionTop,
  positionRight,
  title,
  haveIconClose = true,
  closeModal,
  titleHeaderIsUppercase,
  ...rest
}: ModalCommonProps): ReactElement {
  const backgroundRef = useRef<HTMLDivElement>()
  const {colors} = useTheme()

  return (
    <Fragment>
      <Background
        {...rest}
        ref={backgroundRef}
        onClick={closeModal}
        opened={opened}
      />
      <Modal
        customHeight={customHeight}
        customWidth={customWidth}
        positionTop={positionTop}
        positionRight={positionRight}
        opened={opened}>
        <Header>
          <Title isUppercase={titleHeaderIsUppercase}>{title}</Title>
          {haveIconClose && (
            <Button onClick={closeModal}>
              <IoClose size={26} fill={colors.font.purpleDark} />
            </Button>
          )}
        </Header>
        <Content havePadding={havePadding}>{childrenContent}</Content>
        <Footer>{childrenFooter}</Footer>
      </Modal>
    </Fragment>
  )
}
