import {
  ChangeEvent,
  FC,
  FocusEvent,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react'
import Input from '~/components/Input'

interface InputNumberProps extends InputHTMLAttributes<HTMLInputElement> {
  inputWidth?: string
  hasError?: boolean
  value?: string
  max?: number
  min?: number
  digits?: number
}
export const InputNumber: FC<InputNumberProps> = (props) => {
  const [value, setValue] = useState('')

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value
    const numberReg = new RegExp(
      '^(\\d*' +
        (props.digits !== undefined ? `|\\d*,\\d{0,${props.digits}})$` : ')$')
    )
    if (!numberReg.test(currentValue)) {
      return setValue(value)
    }
    const numberValue = Number(currentValue.split(',').join('.'))
    if (props.max !== undefined && numberValue > props.max) {
      return setValue(value)
    }
    setValue(currentValue)
    return props.onChange?.(e)
  }

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    props.onBlur?.(e)
    if (value === '') {
      return
    }
    const numberReg = new RegExp(
      '^(\\d*' + (props.digits !== undefined ? `,\\d{${props.digits}})$` : ')$')
    )
    const currentValue = value.split(',')
    if (!numberReg.test(value)) {
      const min = props.min?.toString().split('.') ?? ['0', '00']
      currentValue[0] = currentValue[0] || min[0]
      currentValue[1] = currentValue[1]?.padEnd(props.digits, '0') ?? min[1]
      return setValue(
        props.digits !== undefined ? currentValue.join(',') : currentValue[0]
      )
    }
    const numberValue = Number(currentValue.join('.'))
    if (props.min !== undefined && numberValue < props.min) {
      setValue(props.min.toString())
    }
  }

  useEffect(() => {
    if (props.value !== undefined) {
      setValue(props.value)
    }
  }, [props.value])

  return (
    <Input
      {...props}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
    />
  )
}
