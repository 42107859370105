import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  margin-bottom: 3.5rem;
`

export const Title = styled.h3`
  font-size: 18px;
  height: 36px;
  padding-top: 20px;
  color: ${({theme}) => theme.colors.font.main};
  flex: 1;
  font-weight: 700;
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
`

export const PanelContainer = styled.div`
  display: flex;
  margin: 0 40px;
  justify-content: space-between;
  align-items: flex-end;
  gap: 5px;
`

export const InfoContainer = styled.div`
  margin: 18px 40px;
  line-height: 1.5rem;
  @media (min-width: 1200px) {
    width: 50%;
  }
`
