import {
  useContext,
  useState,
  createContext,
  ReactNode,
  ReactElement,
} from 'react'
import {DateTabProps} from '~/components/Financial/FinancialClose/function'

interface ContextProps {
  updateTab: (dateTab: DateTabProps) => void
  tab: DateTabProps
  tabMonthClicked: boolean
  updateTabMonthClicked: (isClicked: boolean) => void
}

export const FinancialContext = createContext<ContextProps>({} as ContextProps)

type FinancialProviderProps = {children: ReactNode}

const FinancialProvider = ({
  children,
}: FinancialProviderProps): ReactElement => {
  const [tab, setTab] = useState<DateTabProps>({
    id: '40',
    date: new Date(),
    month: 'Mês atual',
  })
  const [tabMonthClicked, setTabMonthClicked] = useState(false)

  const updateTabMonthClicked = (isClicked: boolean) =>
    setTabMonthClicked(isClicked)

  const updateTab = (tab: DateTabProps) => setTab(tab)

  return (
    <FinancialContext.Provider
      value={{
        tab,
        updateTabMonthClicked,
        updateTab,
        tabMonthClicked,
      }}>
      {children}
    </FinancialContext.Provider>
  )
}

const useFinancialProvider = (): ContextProps => {
  const context = useContext(FinancialContext)
  return context
}

export {FinancialProvider, useFinancialProvider}
