import {FC, useEffect, useState} from 'react'
import {PageHeader} from '~/components/PageHeader'
import {GoSearch} from 'react-icons/go'
import {FaFilter} from 'react-icons/fa'
import {Button} from './style'
import {CollapsedInput} from '~/components/CollapsedInput'
import {InputDatePicker} from '~/components/InputDatePicker'

interface BonusRuleFilterBarProps {
  count: number
  onChange: (values: {date?: Date; search?: string}) => void
}

export const ManualScoreFilterBar: FC<BonusRuleFilterBarProps> = ({
  count,
  onChange,
}) => {
  const [date, setDate] = useState<Date>(undefined)
  const [search, setSearch] = useState('')

  useEffect(() => {
    const data = {
      date,
      search: search !== '' ? search : undefined,
    }
    onChange(data)
  }, [date, search])

  return (
    <PageHeader title={`Pontuações manuais: ${count}`}>
      <CollapsedInput
        icon={GoSearch}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <InputDatePicker
        position="bottom right"
        value={date}
        onChange={(date) => setDate(date)}
        collapseInput
      />
      <Button
        onClick={() => {
          setDate(undefined)
          setSearch('')
        }}>
        <FaFilter />
      </Button>
    </PageHeader>
  )
}
