import styled, {css} from 'styled-components'

interface ContainerProps {
  monthSelect: boolean
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 115px;
  min-height: 45px;
  ${({monthSelect}) =>
    monthSelect &&
    css`
      border-bottom: 8px solid ${({theme}) => theme.colors.primaryYellow.main};
    `};
`

export const Button = styled.button`
  background-color: transparent;
  span {
    font-size: 14px;
    color: ${({theme}) => theme.colors.font.purpleMain};
  }
  &::first-letter {
    text-transform: uppercase;
  }
`
