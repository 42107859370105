import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
`

export const Title = styled.h3`
  text-align: center;
  font-weight: 400;
  font-size: 22px;
  color: ${({theme}) => theme.colors.font.main};
`

export const SubTitle = styled(Title)`
  font-size: 16px;
`

export const WrapContent = styled.div`
  display: flex;
  gap: 13px;
  align-items: center;
`

export const Button = styled.button`
  display: flex;
  background-color: ${({theme}) => theme.colors.font.purpleMain};
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 0.7;
  }
`

export const ButtonIcon = styled.a`
  outline: none;
  background-color: transparent;
`

export const TitleButton = styled.span`
  color: ${({theme}) => theme.colors.background.white};
  font-weight: 700;
  font-size: 14px;
  padding: 10px 13px;
`

export const Icon = styled.img`
  width: 36px;
  height: 36px;
`
