import React, {ReactElement, SVGProps, useEffect, useState} from 'react'
import {AiOutlineSearch} from 'react-icons/ai'
import {BiFilterAlt} from 'react-icons/bi'
import {BsCheckLg} from 'react-icons/bs'
import {registerLocale} from 'react-datepicker'
import br from 'date-fns/locale/pt-BR'
import './calendar-date.css'
import {
  Container,
  Title,
  Content,
  WrapInputText,
  ToggleInput,
  ButtonCheck,
  InputTypeText,
  WrapDate,
  WrapImageDate,
  ImgDate,
  InputTyDate,
  OptionLabel,
  SpanLabel,
} from './styles'
import calendarWhite from '~/assets/images/calendar-white.jpeg'
import calendarPink from '~/assets/images/calendar-pink.jpeg'
import {useTheme} from 'styled-components'
import SelectInput from '~/components/SelectInput'

registerLocale('br', br)

interface ValueLabelOptions {
  value: string
  label: string
  icon?: JSX.Element
}

interface CommonOptions {
  title: string
  value: string
  label: string
  icon?: JSX.Element
  optionsTypes: ValueLabelOptions[]
  optionsStatus: ValueLabelOptions[]
  clearFilter: () => void
  searchValue: string
  onSearchValueChange: (args: string) => void
  dateValue: string
  onChangeDateValue: (date: Date | [Date, Date]) => void
  handleOptionsType: (args: any) => void
  selectedOptionsType: ValueLabelOptions
  selectedOptionsStatus: ValueLabelOptions
  handleOptionsStatus: (args: any) => void
  startDate?: Date
  endDate?: Date
}

const HeaderInputFilter: React.FC<CommonOptions> = ({
  optionsTypes,
  optionsStatus,
  title,
  clearFilter,
  searchValue,
  onSearchValueChange,
  dateValue,
  onChangeDateValue,
  handleOptionsStatus,
  handleOptionsType,
  selectedOptionsStatus,
  selectedOptionsType,
  startDate,
  endDate,
}) => {
  const {colors} = useTheme()
  const [showFieldSearch, setShowFieldSearch] = useState(false)
  const [isInputDateClicked, setIsInputDateClicked] = useState(false)

  const toggleFiledSearch = () => setShowFieldSearch((previous) => !previous)

  const handleFocus = () => setIsInputDateClicked(true)

  const handleBlur = () => setIsInputDateClicked(false)

  const formatStatusOptionLabel = (data: unknown) => (
    <OptionLabel>
      <SpanLabel
        prop={selectedOptionsStatus.label === (data as CommonOptions).label}>
        {(data as CommonOptions).label}
      </SpanLabel>
      {(data as CommonOptions).icon && (data as CommonOptions).icon}
    </OptionLabel>
  )

  const formatTypeOptionLabel = (data: unknown) => (
    <OptionLabel>
      <SpanLabel
        prop={selectedOptionsType.label === (data as CommonOptions).label}>
        {(data as CommonOptions).label}
      </SpanLabel>
      {(data as CommonOptions).icon && (data as CommonOptions).icon}
    </OptionLabel>
  )

  const getValue = (option: Partial<ValueLabelOptions>) => option.value

  const getLabel = (option: Partial<ValueLabelOptions>) => option.label

  return (
    <Container>
      <Title>{title}</Title>
      <Content>
        {!showFieldSearch && (
          <ToggleInput type="button" onClick={toggleFiledSearch}>
            <AiOutlineSearch size={16} fill={colors.font.dark} />
          </ToggleInput>
        )}
        {showFieldSearch && (
          <WrapInputText>
            <InputTypeText
              value={searchValue}
              onChange={(e) => onSearchValueChange(e.target.value)}
              autoFocus
              type="email"
              placeholder="Pesquisar"
            />
            <ButtonCheck type="button" onClick={toggleFiledSearch}>
              <AiOutlineSearch size={16} />
            </ButtonCheck>
          </WrapInputText>
        )}
        <SelectInput
          onChange={handleOptionsType}
          defaultValue={optionsTypes[0]}
          options={optionsTypes}
          getOptionLabel={getLabel}
          value={selectedOptionsType}
          getOptionValue={getValue}
          formatOptionLabel={formatTypeOptionLabel}
        />
        <WrapDate isClickedInput={isInputDateClicked}>
          <WrapImageDate>
            {isInputDateClicked ? (
              <ImgDate src={calendarPink} alt="icon calendar white" />
            ) : (
              <ImgDate src={calendarWhite} alt="icon calendar pink" />
            )}
          </WrapImageDate>
          <InputTyDate
            isClickedInput={isInputDateClicked}
            dateFormat="dd/MM/yyyy"
            selectsRange
            locale="br"
            onFocus={handleFocus}
            onBlur={handleBlur}
            showDisabledMonthNavigation
            value={dateValue}
            onChange={(e) => onChangeDateValue(e)}
            startDate={startDate}
            endDate={endDate}
          />
        </WrapDate>
        <SelectInput
          onChange={handleOptionsStatus}
          getOptionLabel={getLabel}
          getOptionValue={getValue}
          options={optionsStatus}
          defaultValue={optionsStatus[0]}
          value={selectedOptionsStatus}
          formatOptionLabel={formatStatusOptionLabel}
        />
        <ToggleInput>
          <BiFilterAlt size={20} onClick={clearFilter} />
        </ToggleInput>
      </Content>
    </Container>
  )
}
export default HeaderInputFilter
