import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  padding: 15px 10px;
  flex-direction: column;
  min-height: 130vh;
  overflow-x: hidden;
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 65%;
  height: 100%;
  margin-bottom: 50px;
`
export const WrapHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  max-width: 66%;
  @media screen and (max-width: 1500px) {
    max-width: 100%;
    width: 83%;
  }
`

export const WrapIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 7px;
  background-color: ${({theme}) => theme.colors.font.purpleMain};
`

export const Button = styled.button`
  border-radius: none;
  background-color: transparent;
  transform: all 0.5s;
  &:hover {
    opacity: 0.7;
  }
`

export const Title = styled.h1`
  gap: 45px;
  font-size: 18px;
  color: ${({theme}) => theme.colors.font.dark};
  font-weight: 400;
  margin-top: 2rem;
  margin-bottom: 3rem;
`

export const Block = styled.div`
  height: 400px;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
`
