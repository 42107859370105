import {FC, InputHTMLAttributes, useLayoutEffect, useRef, useState} from 'react'
import {Container} from './styles'
import Input from '~/components/Input'
import {IconType} from 'react-icons'

interface InputDefaultProps extends InputHTMLAttributes<HTMLInputElement> {
  icon: IconType
  collapse?: boolean
  inputWidth?: string
  hasError?: boolean
}
export const CollapsedInput: FC<InputDefaultProps> = (props) => {
  const [mayCollapse, setMayCollapse] = useState(true)
  const inputRef = useRef<HTMLInputElement>(null)
  useLayoutEffect(() => {
    if (inputRef.current !== null && !mayCollapse) {
      inputRef.current.focus()
    }
  }, [mayCollapse])
  return (
    <Container
      className={
        (props.collapse ?? true) && mayCollapse && props.value === ''
          ? 'collapsed'
          : ''
      }
      onClick={() => {
        inputRef.current.click()
        setMayCollapse(false)
      }}>
      <Input
        forwardedRef={inputRef}
        {...{...props}}
        onBlur={(el) => {
          if (props.value === '') {
            setMayCollapse(true)
          }
          props.onBlur?.(el)
        }}
        onChange={(el) => {
          props.onChange?.(el)
        }}
      />
      <props.icon />
    </Container>
  )
}
