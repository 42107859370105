import React, {FC, useEffect, useState} from 'react'
import {PageHeader} from '~/components/PageHeader'
import SelectInput from '~/components/SelectInput'
import {FaFilter} from 'react-icons/fa'
import {useTheme} from 'styled-components'
import {Button} from './style'

interface ScoreFilterBarProps {
  onClickCsvButton: () => any
  count: number
  onChange: (values: {movementType?: string}) => void
}

export const ScoreFilterBar: FC<ScoreFilterBarProps> = ({
  onClickCsvButton,
  count,
  onChange,
}) => {
  const movementTypes = [
    {
      label: 'Todas as Movimentações',
      value: '',
    },
    {
      label: 'Somente créditos',
      value: 'credit',
    },
    {
      label: 'Somente débitos',
      value: 'debit',
    },
    {
      label: 'Somente Expirados',
      value: 'expiration',
    },
  ]
  const theme = useTheme()
  const [movementTypeOption, setMovementTypeOption] = useState(movementTypes[0])
  useEffect(() => {
    const data = {
      movementType:
        movementTypeOption.value !== '' ? movementTypeOption.value : undefined,
    }
    onChange(data)
  }, [movementTypeOption])
  return (
    <PageHeader title={`${count} movimentações encontradas`}>
      <SelectInput
        options={movementTypes}
        value={movementTypeOption}
        onChange={(val) => setMovementTypeOption(val)}
      />
      <Button
        onClick={onClickCsvButton}
        style={{
          color: theme.colors.font.purpleMain,
          width: 'auto',
          paddingLeft: 20,
          paddingRight: 20,
          fontWeight: 'bold',
        }}>
        Exportar extrato em .csv
      </Button>
      <Button
        onClick={() => {
          setMovementTypeOption(movementTypes[0])
        }}>
        <FaFilter />
      </Button>
    </PageHeader>
  )
}
