import React, {FC, ReactElement, useEffect, useState} from 'react'
import PageContainer from '~/components/PageContainer'
import WhiteHeader from '~/components/WhiteHeader'
import Row from '~/components/Row'
import Column from '~/components/Column'
import {StepPanel} from '~/components/StepPanel'
import {FaGift, FaInfo, FaPercentage, FaShoppingCart} from 'react-icons/fa'
import {
  CheckbocContent,
  CheckboxContainer,
  InputIcon,
  Section,
  StepFooterPanel,
} from './styles'
import Button from '~/components/Button'
import {useQuery, useQueryClient} from 'react-query'
import {
  getBonusRuleCreationData,
  storeBonusRule,
} from '~/services/bonusRulesService'
import {Loading} from '~/components/Load'
import Input from '~/components/Input'
import SelectInput from '~/components/SelectInput'
import {InputDateRangePicker} from '~/components/InputDateRangePicker'
import {SkuPanel} from '~/components/SkuPanel'
import {CheckBox} from '~/components/CheckBox'
import {InputNumber} from '~/components/InputNumber'
import * as yup from 'yup'
import toast from 'react-hot-toast'
import {useHistory} from 'react-router-dom'
import {
  Divider,
  InputContainer,
  InputContent,
  SubTitle,
} from '~/components/Styled'

const BonusRulesCreation: FC = (): ReactElement => {
  const queryClient = useQueryClient()
  const {data: createData, isLoading} = useQuery(['bonusRulesCreate'], () =>
    getBonusRuleCreationData()
  )
  const history = useHistory()
  const [currentStep, setStep] = useState(0)
  const [formErrors, setFormErrors] = useState<Record<string, boolean>>({})
  const [formData, setFormData] = useState({
    name: '',
    sellerType: undefined,
    dates: undefined,
    skus: [],
    clusters: {},
    additions: {},
    validityDays: '',
  })

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      clusters: createData?.clusters.reduce(
        (obj, cluster) => ({...obj, [cluster.id]: ''}),
        {}
      ),
      additions: createData?.additions.reduce(
        (obj, addition) => ({...obj, [addition.slug]: false}),
        {}
      ),
    }))
  }, [createData])

  const sellerTypeOptions =
    createData?.sellerTypes.map((sellerType) => ({
      label: sellerType.name,
      value: sellerType.slug,
    })) ?? []

  const validateStep1 = () => {
    const schema = yup.object({
      name: yup.string().required(),
      sellerType: yup
        .string()
        .oneOf(createData?.sellerTypes.map((sellerType) => sellerType.slug))
        .required(),
      dates: yup.array().of(yup.date()).length(2).required(),
    })
    try {
      schema.validateSync(formData, {abortEarly: false})
    } catch (e: any) {
      toast.error('É necessário o preenchimento de todos os campos')
      setFormErrors(
        e.inner.reduce((obj, error) => ({...obj, [error.path]: true}), {})
      )
      throw e
    }
  }

  const validateStep2 = () => {
    const schema = yup.object({
      skus: yup
        .array(
          yup.object({
            name: yup.string().required(),
            status: yup.string().oneOf(['valid']).required(),
          })
        )
        .min(1),
    })
    try {
      schema.validateSync(formData, {abortEarly: false})
    } catch (e: any) {
      if (e.inner[0].type === 'min') {
        toast.error('É obrigatória a escolha de pelo menos um SKU')
      } else {
        toast.error('Existem SKUs inválidos')
      }
      throw e
    }
  }

  const validateStep3 = () => {
    const schema = yup.object({
      clusters: yup.object(
        createData.clusters.reduce(
          (obj, cluster) => ({
            ...obj,
            [cluster.id]: yup
              .number()
              .required()
              .min(0)
              .max(100)
              .transform((_, value) => {
                if (value === '') {
                  return
                }
                return Number(value.replace(',', '.'))
              }),
          }),
          {}
        )
      ),
      validityDays: yup.number().required().min(1).integer(),
      additions: yup.object(
        createData.additions.reduce(
          (obj, addition) => ({
            ...obj,
            [addition.slug]: yup.boolean().required(),
          }),
          {}
        )
      ),
    })
    try {
      schema.validateSync(formData, {abortEarly: false})
    } catch (e: any) {
      toast.error('É necessário o preenchimento de todos os campos')
      setFormErrors(
        e.inner.reduce((obj, error) => ({...obj, [error.path]: true}), {})
      )
      throw e
    }
  }

  const handleStep = () => {
    try {
      switch (currentStep) {
        case 0:
          validateStep1()
          break
        case 1:
          validateStep2()
          break
      }
    } catch (e) {
      return
    }
    setStep((actual) => actual + 1)
  }

  const handleSubmit = async () => {
    try {
      validateStep3()
    } catch (e) {
      return
    }
    try {
      await storeBonusRule({
        name: formData.name,
        sellerType: formData.sellerType,
        validityDays: Number(formData.validityDays.replace(',', '.')),
        startedAt: formData.dates[0],
        endedAt: formData.dates[1],
        additions: Object.entries<boolean>(formData.additions)
          .filter(([, val]) => {
            return val
          })
          .map(([key]) => key),
        skus: formData.skus.map((sku) => sku.name),
        clusters: Object.entries<string>(formData.clusters).map(
          ([id, val]) => ({
            id,
            value: Number(val.replace(',', '.')),
          })
        ),
      })
      await queryClient.refetchQueries({queryKey: ['bonusRulesPage']})
      history.push('/regras-bonificacao')
    } catch (e: any) {
      toast.error(e.message)
    }
  }

  const steps = [
    {
      icon: FaInfo,
      label: 'Infos & Vigência',
    },
    {
      icon: FaShoppingCart,
      label: 'Produtos',
    },
    {
      icon: FaGift,
      label: 'Bonificações',
    },
  ]
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <PageContainer>
          <WhiteHeader backTo="/regras-bonificacao" />
          <Row style={{padding: '0 24px'}}>
            <Column desktop={12} mobile={12} tablet={12}>
              <StepPanel
                currentStep={currentStep}
                steps={steps}
                footer={
                  <StepFooterPanel>
                    <Button
                      text="Anterior"
                      disabled={currentStep === 0}
                      onClick={() => setStep((actual) => actual - 1)}
                    />
                    {currentStep === steps.length - 1 ? (
                      <Button text="Salvar regra" onClick={handleSubmit} />
                    ) : (
                      <Button
                        disabled={false}
                        text="Próximo"
                        onClick={handleStep}
                      />
                    )}
                  </StepFooterPanel>
                }>
                <Section value={0} actual={currentStep}>
                  <SubTitle>Informações essenciais</SubTitle>
                  <Row>
                    <Column desktop={6} tablet={12} mobile={12}>
                      <InputContainer>
                        <label htmlFor="name">Nome</label>
                        <InputContent>
                          <Input
                            hasError={formErrors.name}
                            onFocus={() =>
                              setFormErrors((errors) => ({
                                ...errors,
                                name: false,
                              }))
                            }
                            id="name"
                            value={formData.name}
                            onChange={(e) =>
                              setFormData((formData) => ({
                                ...formData,
                                name: e.target.value,
                              }))
                            }
                          />
                        </InputContent>
                      </InputContainer>
                    </Column>
                  </Row>
                  <Row>
                    <Column desktop={6} tablet={12} mobile={12}>
                      <InputContainer>
                        <label htmlFor="sellerType">Tipo de Venda</label>
                        <InputContent>
                          <SelectInput
                            hasError={formErrors.sellerType}
                            id="sellerType"
                            onFocus={() =>
                              setFormErrors((errors) => ({
                                ...errors,
                                sellerType: false,
                              }))
                            }
                            onChange={(result) =>
                              setFormData((formData) => ({
                                ...formData,
                                sellerType: result.value,
                              }))
                            }
                            value={sellerTypeOptions.find(
                              (options) => options.value === formData.sellerType
                            )}
                            options={sellerTypeOptions}
                          />
                        </InputContent>
                      </InputContainer>
                    </Column>
                  </Row>
                  <SubTitle>Vigência</SubTitle>
                  <Row>
                    <Column desktop={6} tablet={12} mobile={12}>
                      <InputContainer>
                        <label>Período de vigência</label>
                        <InputContent>
                          <InputDateRangePicker
                            hasError={formErrors.dates}
                            onFocus={() => {
                              setFormErrors((errors) => ({
                                ...errors,
                                dates: false,
                              }))
                            }}
                            position="top left"
                            placeholder="Selecione"
                            withTimePicker
                            value={formData.dates}
                            onChange={(dates) => {
                              setFormData((formData) => ({
                                ...formData,
                                dates,
                              }))
                            }}
                          />
                        </InputContent>
                      </InputContainer>
                    </Column>
                  </Row>
                </Section>
                <Section value={1} actual={currentStep}>
                  <SubTitle>Seleção de produtos</SubTitle>
                  <SkuPanel
                    onChange={(skus) =>
                      setFormData((formData) => ({...formData, skus}))
                    }
                  />
                </Section>
                <Section value={2} actual={currentStep}>
                  <SubTitle>Bonificações</SubTitle>
                  {createData?.clusters.map((cluster, pos) => {
                    return (
                      <Row key={pos}>
                        <Column desktop={4} tablet={12} mobile={12}>
                          <InputContainer>
                            <label htmlFor={`cluster.${cluster.slug}`}>
                              {cluster.name}
                            </label>
                            <InputIcon>
                              <InputNumber
                                hasError={formErrors[`clusters.${cluster.id}`]}
                                value={formData?.clusters?.[cluster.id]}
                                max={100}
                                digits={2}
                                id={`cluster.${cluster.slug}`}
                                onFocus={() =>
                                  setFormErrors((errors) => ({
                                    ...errors,
                                    [`clusters.${cluster.id}`]: false,
                                  }))
                                }
                                onChange={(e) =>
                                  setFormData((formData) => ({
                                    ...formData,
                                    clusters: {
                                      ...formData.clusters,
                                      [cluster.id]: e.target.value,
                                    },
                                  }))
                                }
                              />
                              <FaPercentage />
                            </InputIcon>
                          </InputContainer>
                        </Column>
                      </Row>
                    )
                  })}
                  <Row>
                    <Column desktop={4} tablet={12} mobile={12}>
                      <Divider />
                      <InputContainer>
                        <label htmlFor="validityDays">Validade (em dias)</label>
                        <InputContent>
                          <InputNumber
                            hasError={formErrors.validityDays}
                            onFocus={() =>
                              setFormErrors((errors) => ({
                                ...errors,
                                validityDays: false,
                              }))
                            }
                            id="validityDays"
                            value={formData.validityDays}
                            min={1}
                            onChange={(e) =>
                              setFormData((formData) => ({
                                ...formData,
                                validityDays: e.target.value,
                              }))
                            }
                          />
                        </InputContent>
                      </InputContainer>
                    </Column>
                  </Row>
                  <SubTitle>Limites & Restrições</SubTitle>
                  <Row>
                    <Column desktop={12} tablet={12} mobile={12}>
                      <InputContainer>
                        <label>Considerar p/ bonificação</label>
                        <CheckboxContainer>
                          {createData.additions.map((addition) => (
                            <CheckbocContent key={addition.slug}>
                              <CheckBox
                                id={`addition.${addition.slug}`}
                                name="teste"
                                value={formData?.additions?.[addition.slug]}
                                onChange={(value) =>
                                  setFormData((formData) => ({
                                    ...formData,
                                    additions: {
                                      ...formData.additions,
                                      [addition.slug]: value,
                                    },
                                  }))
                                }
                              />
                              <label htmlFor={`addition.${addition.slug}`}>
                                {addition.name}
                              </label>
                            </CheckbocContent>
                          ))}
                        </CheckboxContainer>
                      </InputContainer>
                    </Column>
                  </Row>
                </Section>
              </StepPanel>
            </Column>
          </Row>
        </PageContainer>
      )}
    </>
  )
}

export default BonusRulesCreation
