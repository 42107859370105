import api from '~/config/apiSauce'
import {
  AdonisPagination,
  BonusRule,
  bonusRuleAnalysis,
  BonusRulesPage,
  BonusRulesPagnationFilter,
  BonusRulesStoreData,
  BonusRulesUpdateDate,
  CreateBonusRule,
  EditBonusRule,
  skuValidation,
} from '~/types'
import {createJsonDateHandler} from '~/utils'

const prefix = 'mg'
export async function getBonusRulesPagination({
  page,
  limit,
  endDate,
  startDate,
  active,
  sellerType,
  searchField,
}: BonusRulesPagnationFilter): Promise<AdonisPagination<BonusRulesPage>> {
  const response = await api.get<AdonisPagination<BonusRulesPage>>(
    prefix + '/bonusRules',
    {
      page,
      limit,
      endDate,
      startDate,
      active,
      sellerType,
      searchField,
    },
    {
      transformResponse: createJsonDateHandler([
        'startedAt',
        'endedAt',
        'createdAt',
        'disabledAt',
      ]),
    }
  )
  if (!response.ok) {
    throw new Error('erro ao coletar página de regras de bonificação')
  }
  return response.data
}

export async function getBonusRule(id: string): Promise<BonusRule> {
  const response = await api.get<BonusRule>(
    `${prefix}/bonusRules/${id}`,
    {},
    {
      transformResponse: createJsonDateHandler([
        'startedAt',
        'endedAt',
        'createdAt',
      ]),
    }
  )
  if (!response.ok) {
    throw new Error('erro ao coletar regra de bonificação')
  }
  return response.data
}

export async function getBonusRuleCreationData(): Promise<CreateBonusRule> {
  const response = await api.get<CreateBonusRule>(`${prefix}/bonusRules/create`)
  if (!response.ok) {
    throw new Error(
      'erro ao coletar informações de criação de regra de bonificação'
    )
  }
  return response.data
}

export async function getBonusRuleEditionData(
  id: string
): Promise<EditBonusRule> {
  const response = await api.get<EditBonusRule>(
    `${prefix}/bonusRules/${id}/edit`,
    {},
    {
      transformResponse: createJsonDateHandler(['startedAt', 'endedAt']),
    }
  )
  if (!response.ok) {
    throw new Error(
      'erro ao coletar informações de edição de regra de bonificação'
    )
  }
  return response.data
}

export async function validateSku(skus: string[]): Promise<skuValidation[]> {
  const response = await api.post<skuValidation[]>(
    `${prefix}/bonusRules/validateSkus`,
    skus
  )
  if (!response.ok) {
    throw new Error('erro ao tentar validar o sku')
  }
  return response.data
}

export async function storeBonusRule(
  params: BonusRulesStoreData
): Promise<{id: string}> {
  const response = await api.post<{id: string}>(`${prefix}/bonusRules`, params)
  if (!response.ok) {
    throw new Error('Não foi possível salvar a regra, verifique os campos')
  }
  return response.data
}

export async function updateBonusRule(
  id: string,
  params: BonusRulesUpdateDate
): Promise<void> {
  const response = await api.put(`${prefix}/bonusRules/${id}`, params)
  if (!response.ok) {
    throw new Error('Não foi possível salvar a regra, verifique os campos')
  }
}

export async function disableBonusRule(id: string): Promise<void> {
  const response = await api.put(`${prefix}/bonusRules/disable/${id}`)
  if (!response.ok) {
    throw new Error('Não foi possível desativar essa regra')
  }
}

export async function enableBonusRule(id: string): Promise<void> {
  const response = await api.put(`${prefix}/bonusRules/enable/${id}`)
  if (!response.ok) {
    throw new Error('Não foi possível ativar essa regra')
  }
}

export async function getBonusRuleAnalysis(): Promise<bonusRuleAnalysis[]> {
  const response = await api.get<bonusRuleAnalysis[]>(
    `${prefix}/bonusRules/analysis`
  )
  if (!response.ok) {
    throw new Error('Não foi possível recuperar a análise de regras')
  }
  return response.data
}
