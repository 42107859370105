import React, {ReactElement} from 'react'
import ChartLineAnual from '~/components/ApexChartLineAnual'
import {Container, Title, Hr} from './styles'

interface ConsolidaAnualChartProps {
  text: string
}

function ConsolidaAnualChart({text}: ConsolidaAnualChartProps): ReactElement {
  return (
    <Container>
      <Title> {text} </Title>
      <Hr />
      <ChartLineAnual />
    </Container>
  )
}

export default ConsolidaAnualChart
