import React, {ReactElement} from 'react'
import ApexChartRadialBar from '~/components/ApexChartRadialBar'
import {Container, WrapContent, Title, Hr} from './styles'

interface MetaChartProps {
  text: string
}

function MetaChart({text}: MetaChartProps): ReactElement {
  return (
    <Container>
      <Title> {text} </Title>
      <Hr />
      <WrapContent>
        <ApexChartRadialBar />
      </WrapContent>
    </Container>
  )
}

export default MetaChart
