import {Fragment, useId, useEffect, ReactElement} from 'react'
import {Redirect} from 'react-router-dom'
import {CreditsCard} from '~/components/ButtonCreditsCard'
import {
  Article,
  WrapArticle,
  SectionArticle,
  Section,
  TextSection,
  ContainerContent,
  Content,
  TitleContent,
  WrapContentText,
} from './styles'
import {formatDate} from '~/components/DetailsMovement/functions'
import {formatMoney} from '~/components/ButtonCreditsCard/functions'
import {useFinancialProvider} from '~/hooks/useFinancialProvider'
import {useMovement} from '~/components/Movement/useMovement'
import {
  keyStorageTotalPositive,
  keyStorageTotalNegative,
} from '~/utils/keystorage'
import {Loading} from '~/components/Load'
import {useMovementProvider} from '~/hooks/useMovementProvider'

interface ContentMovementProps {
  setTotalMovement: (quantity: number) => void
}

export default function ContentMovement({
  setTotalMovement,
}: ContentMovementProps): ReactElement {
  const id = useId()
  const {isFinishedScroll, updateScroll} = useMovementProvider()

  const {
    dataMovement,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    quantity,
    isError,
    error,
  } = useMovement()
  const {tabMonthClicked} = useFinancialProvider()
  const getMoreData = isFinishedScroll && hasNextPage

  let totalNegative = 0
  let totalPositive = 0

  useEffect(() => {
    setTotalMovement(quantity)
    const fetchMoreData = () => fetchNextPage()

    if (getMoreData) {
      fetchMoreData()
    }
    return () => {
      setTotalMovement(quantity)
      updateScroll(false)
    }
  }),
    [quantity, getMoreData, error, dataMovement]

  if (isLoading) return <Loading />
  if (isError) return <div>Error</div>

  return (
    <Article>
      <WrapArticle>
        <SectionArticle>
          <TextSection>Data/hora</TextSection>
          <Section>
            <TextSection>Tipo de movimentação</TextSection>
          </Section>
          <Section>
            <TextSection>Cliente</TextSection>
          </Section>
          <Section>
            <TextSection>Nº do pedido</TextSection>
          </Section>
          <TextSection>Saldo</TextSection>
        </SectionArticle>
      </WrapArticle>
      <ContainerContent>
        {dataMovement.pages.map((movement) => {
          return movement.data.map((it) => (
            <div style={{marginBottom: 17}} key={`${it.total} ${id}`}>
              <WrapContentText>
                <TitleContent>{formatDate(it.date)}</TitleContent>
                <TitleContent>{formatMoney(it.total)}</TitleContent>
              </WrapContentText>
              <Content>
                {it.transactions.map((data) => {
                  if (data.amount < 0) {
                    totalNegative -= data.amount
                  } else {
                    totalPositive += data.amount
                  }
                  sessionStorage.setItem(
                    keyStorageTotalNegative,
                    JSON.stringify(totalNegative)
                  )

                  sessionStorage.setItem(
                    keyStorageTotalPositive,
                    JSON.stringify(totalPositive)
                  )

                  return (
                    <Fragment key={`${id}${data.id}`}>
                      <CreditsCard
                        title={data.type}
                        amount={data.amount}
                        email={data.email}
                        hour={data.datetime}
                        requestNumber={data.orderId}
                      />
                    </Fragment>
                  )
                })}
              </Content>
            </div>
          ))
        })}
        {isFetching && <Loading />}
        {tabMonthClicked && <Redirect to="/financeiro" />}
      </ContainerContent>
    </Article>
  )
}
