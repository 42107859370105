import React, {FC, ReactElement, useRef, useState} from 'react'
import {Container, Header, IconContainer, MessageContainer} from './style'
import {useQuery} from 'react-query'
import {getReferrals} from '~/services/apiManagerService'
import {ReferralFilterBar} from '~/pages/CustomerProfile/ReferralTab/ReferralFilterBar'
import {FaUser} from 'react-icons/fa'
import {dateTimeToFormat} from '~/utils'
import Button from '~/components/Button'
import {TableHeader} from '~/components/NewTable/TableHeader'
import {
  Paginator,
  Table,
  TableCol,
  TableHeaderCol,
  TableRow,
} from '~/components/NewTable'
import {useHistory} from 'react-router-dom'
import {CSVGenerator, CSVRef} from '~/components/CSVGenerator'
import {Referral} from '~/types/ApiManagerTypes'

type ReferralTabParams = {
  email: string
}

export const ReferralTab: FC<ReferralTabParams> = ({email}): ReactElement => {
  const history = useHistory()
  const csvRef = useRef<CSVRef>()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(15)
  const [filters, setFilters] = useState({})
  const {data, isLoading, isPreviousData} = useQuery(
    [
      'customerAnalysisReferrals',
      email,
      {
        page,
        perPage,
        ...filters,
      },
    ],
    async () => getReferrals(email, page, perPage, filters),
    {
      keepPreviousData: true,
    }
  )

  return (
    <Container>
      <Header>
        <ReferralFilterBar
          onClickCsvButton={() => {
            if (csvRef.current) {
              void csvRef.current.generateCSV()
            }
          }}
          onChange={(filter) => {
            setPage(1)
            setFilters(filter)
          }}
          count={data?.meta.pagination.count ?? 0}
        />
        <CSVGenerator
          csvRef={csvRef}
          request={(csvPage) => getReferrals(email, csvPage, 1000, filters)}
          csvName={`${email}_referrals`}
          map={(referral: Referral) => {
            return {
              indicado: referral.email,
              cadastradoEm: dateTimeToFormat(referral.createdAt, {}),
            }
          }}
        />
      </Header>
      {isLoading ? (
        <MessageContainer>Carregando dados de indicações</MessageContainer>
      ) : data?.data.length === 0 ? (
        <MessageContainer>Sem histórico de indicações.</MessageContainer>
      ) : (
        <>
          <Table isLoading={isPreviousData}>
            <TableHeader>
              <IconContainer></IconContainer>
              <TableHeaderCol>indicado</TableHeaderCol>
              <TableHeaderCol>cadastrado em</TableHeaderCol>
              <TableHeaderCol></TableHeaderCol>
            </TableHeader>
            {data.data.map((row) => (
              <TableRow key={row.id}>
                <IconContainer>
                  <FaUser />
                </IconContainer>
                <TableCol>{row.email}</TableCol>
                <TableCol>{dateTimeToFormat(row.createdAt, {})}</TableCol>
                <TableCol style={{justifyContent: 'end'}}>
                  <Button
                    text="Ver Perfil de Cliente"
                    onClick={() => history.push(`/cliente/${row.email}`)}
                  />
                </TableCol>
              </TableRow>
            ))}
          </Table>
          <Paginator
            isLoading={isPreviousData}
            currentPage={page}
            perPage={perPage}
            totalItems={data?.meta.pagination.count}
            onChange={(page, perPage) => {
              setPage(page)
              setPerPage(perPage)
            }}
            perPageOptions={[15, 30, 45, 60]}
          />
        </>
      )}
    </Container>
  )
}
