import styled from 'styled-components'

interface ContainerProps {
  backgroundColor: string
  drawerOpened: boolean
}

export const Container = styled.div<ContainerProps>`
  width: ${({drawerOpened}) => (drawerOpened ? '230px' : '0')};
  box-shadow: 5px 0 8px rgb(0 0 0 / 20%);
  z-index: 1;
  gap: 15px;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.2s linear 0s;
  white-space: nowrap;
  overflow-x: hidden;
`

export const Footer = styled.div`
  display: flex;
  padding: 15px;
  width: 100%;
  height: 45px;
  justify-content: left;
  align-items: center;
  border-top: solid 1px ${({theme}) => theme.colors.border.main};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`
