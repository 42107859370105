import react, {ChangeEvent, useEffect, useState} from 'react'
import {Container, FieldContainer, FieldLabel, FieldsWrapper} from './styles'

import Input from '~/components/Input'
import SelectInput from '~/components/SelectInput'
import {Content, OptionLabel, SpanLabel} from '~/components/HeaderInput/styles'
import React from 'react'
import {BonusReason, FormSelectorType} from '~/types'
import InputMoneyWithSelector from '~/components/InputMoneyWithSelector'

interface ManualBonusFormProps {
  description: string
  setDescription: (arg: string) => void
  daysToExpire: string
  setDaysToExpire: (arg: string) => void
  reasons: FormSelectorType[]
  reasonValue: FormSelectorType
  onChangeReasonValue: (arg: FormSelectorType) => void
  moneyValue: string
  onChangeMoneyValue: (arg: string) => void
  transactionType: FormSelectorType
  onChangeTransactionType: (arg: FormSelectorType) => void
  errorFields: string[]
}

const ManualBonusForm: react.FC<ManualBonusFormProps> = ({
  description,
  setDescription,
  daysToExpire,
  setDaysToExpire,
  reasons,
  reasonValue,
  onChangeReasonValue,
  moneyValue,
  onChangeMoneyValue,
  onChangeTransactionType,
  transactionType,
  errorFields,
}) => {
  const moneyTypes: FormSelectorType[] = [
    {key: '1', label: 'Crédito', value: 'Credit'},
    {key: '2', label: 'Débito', value: 'Debit'},
  ]
  const getValue = (option: Partial<FormSelectorType>) => option.value

  const getLabel = (option: Partial<FormSelectorType>) => option.label

  const formatTypeOptionLabel = (data: unknown) => (
    <OptionLabel>
      <SpanLabel prop={reasons[0].label === (data as FormSelectorType).label}>
        {(data as FormSelectorType).label}
      </SpanLabel>
    </OptionLabel>
  )
  const [error, setError] = useState<{
    description: boolean
    reason: boolean
    value: boolean
    expireAt: boolean
    transactionType: boolean
  }>({
    description: false,
    reason: false,
    value: false,
    expireAt: false,
    transactionType: false,
  })
  useEffect(() => {
    errorFields.forEach((field) => {
      setError({...error, [field]: true})
    })
  }, [errorFields])

  return (
    <Container>
      <FieldsWrapper>
        <FieldContainer>
          <FieldLabel>Descrição</FieldLabel>
          <Input
            inputMode="text"
            hasError={error.description}
            value={description}
            onChange={(e) => {
              setError({...error, description: false})
              setDescription(e.target.value)
            }}></Input>
        </FieldContainer>
        <FieldContainer>
          <FieldLabel>Motivo</FieldLabel>
          <SelectInput
            width="320px"
            onChange={(e) => {
              setError({...error, reason: false})
              onChangeReasonValue(e as FormSelectorType)
            }}
            hasError={error.reason}
            placeholder="Selecione o motivo"
            options={reasons}
            getOptionLabel={getLabel}
            value={reasonValue}
            getOptionValue={getValue}
            formatOptionLabel={formatTypeOptionLabel}
          />
        </FieldContainer>
        <FieldContainer>
          <FieldLabel>Valor</FieldLabel>
          <InputMoneyWithSelector
            hasError={error.value || error.transactionType}
            inputMode="decimal"
            type="number"
            selectOptions={moneyTypes}
            value={moneyValue}
            onChange={(e) => {
              setError({...error, value: false})
              onChangeMoneyValue(e.target.value)
            }}
            selectedValue={transactionType}
            onSelectChange={onChangeTransactionType}
          />
        </FieldContainer>
        <FieldContainer>
          <FieldLabel>Validade (em dias)</FieldLabel>
          <Input
            hasError={error.expireAt}
            type="number"
            inputWidth="70px"
            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
            value={daysToExpire}
            onChange={(e) => {
              setError({...error, expireAt: false})
              setDaysToExpire(e.target.value)
            }}
            min={1}
          />
        </FieldContainer>
      </FieldsWrapper>
    </Container>
  )
}
export default ManualBonusForm
