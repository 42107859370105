import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 101%;
  justify-content: space-between;
`

export const WrapContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 25px;
  margin-left: -10px;
  background-color: ${({theme}) => theme.colors.background.main};
  width: 101%;
  height: 100%;
`

export const Button = styled.button`
  border: none;
  background-color: transparent;
  outline: none;
  transition: ease-in-out 0.3s;
  &:hover {
    opacity: 0.7;
  }
`

export const Footer = styled.footer`
  display: flex;
  width: 101%;
  margin-left: -12px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${({theme}) => theme.colors.primaryYellow.main};
  padding: 25px 17px;
  height: 100%;
`

export const TitleFooter = styled.span`
  font-family: '72', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: ${({theme}) => theme.colors.font.main};
`

export const MiddleFooter = styled.div`
  display: flex;
  align-items: center;
`

export const TextMiddleFooter = styled(TitleFooter)``

export const FinalFooter = styled.div`
  display: flex;
  align-items: center;
`

export const TextBalanceFooter = styled(TextMiddleFooter)``

export const PriceTextFooter = styled(TextMiddleFooter)`
  color: ${({theme}) => theme.colors.border.input.green};
`
