import {ReactElement, ReactNode} from 'react'
import {
  Body,
  Container,
  Filler,
  Footer,
  Header,
  IconContainer,
  Item,
  ItemContent,
  ItemLabel,
} from './styles'
import {IconType} from 'react-icons'

interface StepParams {
  steps: Array<{
    icon: IconType
    label: string
  }>
  currentStep: number
  footer?: ReactNode
  children?: ReactNode
}

export function StepPanel({
  steps,
  footer,
  children,
  currentStep,
}: StepParams): ReactElement {
  return (
    <Container>
      <Header>
        <Filler />
        {steps.map((item, key) => (
          <Item key={key} activated={currentStep > key}>
            <ItemContent>
              <IconContainer activated={currentStep >= key}>
                <item.icon />
              </IconContainer>
              <ItemLabel
                activated={currentStep >= key}
                actual={currentStep === key}>
                {item.label}
              </ItemLabel>
            </ItemContent>
          </Item>
        ))}
      </Header>
      <Body hasFooter={footer !== undefined}>{children}</Body>
      {footer && <Footer>{footer}</Footer>}
    </Container>
  )
}
