import {
  HTMLAttributes,
  useEffect,
  useState,
  useCallback,
  ReactElement,
} from 'react'
import {getDate, lastDayOfMonth} from 'date-fns'
import CSV from '~/assets/images/csv.svg'
import PDF from '~/assets/images/pdf.svg'
import {
  Container,
  Title,
  SubTitle,
  WrapContent,
  Button,
  ButtonIcon,
  TitleButton,
  Icon,
} from './styles'
import {useFinancialProvider} from '~/hooks/useFinancialProvider'
import {keyStorageTabDate} from '~/utils/keystorage'
import {useMovementProvider} from '~/hooks/useMovementProvider'
import {useFinancial} from '~/components/Financial/useFinancial'
import {Loading} from '~/components/Load'

type ContentHomeProps = HTMLAttributes<HTMLButtonElement>

export default function ContentHome({...rest}: ContentHomeProps): ReactElement {
  const {yearMonth} = useMovementProvider()
  const {data, isLoading} = useFinancial()
  const {tabMonthClicked} = useFinancialProvider()
  const tabDateStorage = sessionStorage.getItem(keyStorageTabDate)
  const tabDate = tabDateStorage && JSON.parse(tabDateStorage)
  const date = new Date()
  const dayCurrent = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  const monthCurrent = date.getMonth()
  const lastDay = getDate(lastDayOfMonth(new Date(tabDate)))
  const [dateParamIconDayInitial, setDateParamIconDayInitial] = useState('')
  const [dateParamIconDayFinal, setDateParamIconDayFinal] = useState('')

  const updateMonth = useCallback(() => {
    if (monthCurrent + 1 === Number(yearMonth.split('-')[1])) {
      setDateParamIconDayInitial(`${yearMonth}-0${1}`)
      setDateParamIconDayFinal(`${yearMonth}-${dayCurrent}`)
    } else {
      setDateParamIconDayInitial(`${yearMonth}-0${1}`)
      setDateParamIconDayFinal(`${yearMonth}-${lastDay}`)
    }
  }, [])
  useEffect(() => {
    updateMonth()
  }, [yearMonth, tabMonthClicked])

  return (
    <Container>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div>
            <Title> {data.totalMovements > 0 ? data.totalMovements : 0}</Title>
            <SubTitle>movimentações</SubTitle>
          </div>
          <WrapContent>
            <Button disabled={data.totalMovements === 0} {...rest}>
              <TitleButton>Exibir todas</TitleButton>
            </Button>
            <ButtonIcon
              href={`${process.env.REACT_APP_BASE_URL}/movements/detailing/pdf?startDate=${dateParamIconDayInitial}&endDate=${dateParamIconDayFinal}`}
              download
              target="blank">
              <Icon src={PDF} width={36} height={36} alt="icon pdf" />
            </ButtonIcon>
            <ButtonIcon
              href={`${process.env.REACT_APP_BASE_URL}/movements/detailing/csv?startDate=${dateParamIconDayInitial}&endDate=${dateParamIconDayFinal}`}
              download
              target="blank">
              <Icon src={CSV} width={36} height={36} alt="icon csv" />
            </ButtonIcon>
          </WrapContent>
        </>
      )}
    </Container>
  )
}
