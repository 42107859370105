import styled from 'styled-components'

export const Container = styled.button`
  display: grid;
  grid-template-columns: 0.5fr 1fr 3fr 3fr 1fr 1fr 0.8fr 0.5fr;
  width: 100%;
  padding: 14px 27px;
  background-color: transparent;
  border-top: 0.5px solid ${({theme}) => theme.colors.border.main};
  border-bottom: 0.5px solid ${({theme}) => theme.colors.border.main};
  &:hover {
    background-color: ${({theme}) => theme.colors.font.acentoUltraLight};
  }
`

export const Text = styled('span')<{isGray?: boolean}>`
  text-align: start;
  font-weight: 300;
  font-size: 13px;
  color: ${(props) =>
    !props.isGray
      ? props.theme.colors.font.greenTag
      : props.theme.colors.font.placeHolder};
`
