import {ReactElement, useRef} from 'react'
import Financial from '~/components/Financial'
import DetailsMovement from '~/components/DetailsMovement'
import {Container} from './styles'
import {useMovementProvider} from '~/hooks/useMovementProvider'

function FinancialDetails(): ReactElement {
  const {updateScroll} = useMovementProvider()
  const refListener = useRef<HTMLDivElement>(null)

  const onScroll = () => {
    if (refListener.current) {
      const {scrollHeight, clientHeight, scrollTop} = refListener.current
      if (
        Math.abs(scrollHeight - clientHeight - scrollTop) < 1 ||
        scrollHeight - Math.abs(scrollTop) === clientHeight
      ) {
        return updateScroll(true)
      }
    }
  }

  return (
    <Container onScroll={onScroll} ref={refListener}>
      <Financial />
      <DetailsMovement />
    </Container>
  )
}

export default FinancialDetails
