import {FC, ReactElement, ReactNode} from 'react'
import {
  TableContainer,
  TableContent,
  TableOverlay,
} from '~/components/NewTable/Table/style'

type TableProps = {
  isLoading?: boolean
  children?: ReactNode
}

export const Table: FC<TableProps> = ({isLoading, children}): ReactElement => {
  return (
    <TableContainer>
      <TableContent>{children}</TableContent>
      {isLoading && <TableOverlay />}
    </TableContainer>
  )
}
