import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 15px;
  position: relative;
  width: 100%;
  background-color: #fff;

  span.filter {
    display: flex;
    margin-left: 25px;
    margin-top: 20px;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
  }

  .top {
    &:hover {
      color: red;
    }
    &:focus {
      background-color: black;
    }
  }
`

export const Title = styled.span`
  margin-left: 24px;
  color: blue;
  text-transform: uppercase;
  color: ${({theme}) => theme.colors.font.main};
  font-size: 14px;
  font-weight: 300;
  position: absolute;
  top: 15px;
`
