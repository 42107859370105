import styled from 'styled-components'

type BigNumberProps = {
  color: string
}

export const Container = styled.div`
  width: 50%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Block = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 20px 0 10px;
`

export const Legend = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-left: 10px;
`

export const Text = styled.span`
  font-family: '72 Light', '72 Regular', '72', sans-serif;
  font-weight: 300;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-size: 10px;
  color: ${({theme}) => theme.colors.font.main};
  width: 90px;
`

export const BigNumber = styled.span<BigNumberProps>`
  font-weight: bold;
  color: ${({color}) => color};
  font-size: 20px;
`
