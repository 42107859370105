export const getCategories = (categories: string[]): string[][] => {
  return categories.map((date) => {
    const dateCurrent = new Date(+date.split('-')[0], +date.split('-')[1] - 1)
    const year = new Intl.DateTimeFormat('pt-Br', {
      year: '2-digit',
    }).format(dateCurrent)
    const month = new Intl.DateTimeFormat('pt-Br', {
      month: 'short',
    }).format(dateCurrent)
    const monthUpperCase =
      month[0].toUpperCase() + month.substring(1).replace('.', '')
    return [monthUpperCase, year]
  })
}

export const getMonthYearLong = (
  categories: string[]
): {year: string; month: string}[] => {
  return categories.map((date) => {
    const dateCurrent = new Date(+date.split('-')[0], +date.split('-')[1] - 1)
    const year = new Intl.DateTimeFormat('pt-Br', {
      year: 'numeric',
    }).format(dateCurrent)
    const month = new Intl.DateTimeFormat('pt-Br', {
      month: 'long',
    }).format(dateCurrent)
    return {year, month}
  })
}

export const formatCurrency = (currency: number): string => {
  return new Intl.NumberFormat(`pt-BR`, {
    style: 'currency',
    currency: 'BRL',
  }).format(currency / 100)
}
