import React, {
  FocusEventHandler,
  ReactElement,
  useEffect,
  useState,
} from 'react'
import {GoCalendar} from 'react-icons/go'
import {
  CalendarContainer,
  Container,
  DateContainer,
  PopoverBody,
  PopoverFooter,
  TimeContainer,
  TimeContent,
  TimeLabel,
} from './styles'
import Popover from '~/components/Popover'
import Button from '~/components/Button'
import {useTheme} from 'styled-components'
import {Calendar} from '~/components/Calendar'
import {DateTime} from 'luxon'
import {TimePicker} from '~/components/TimePicker'
import {dateTimeToFormat} from '~/utils'
import {CollapsedInput} from '~/components/CollapsedInput'

interface InputDatePickerProps {
  value?: [Date, Date] | undefined
  onChange?: (value: [Date, Date]) => void
  position?: 'bottom right' | 'bottom left' | 'top right' | 'top left'
  title?: string
  collapseInput?: boolean
  withTimePicker?: boolean
  placeholder?: string
  hasError?: boolean
  onFocus?: FocusEventHandler<HTMLInputElement>
}

export const InputDateRangePicker: React.FC<InputDatePickerProps> = ({
  value,
  onChange,
  position,
  title,
  collapseInput,
  withTimePicker,
  placeholder,
  hasError,
  onFocus,
}): ReactElement => {
  const {colors} = useTheme()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [dates, setDates] = useState<[Date, Date]>([undefined, undefined])
  const [times, setTimes] = useState([
    {
      hour: 0,
      minute: 0,
    },
    {
      hour: 23,
      minute: 59,
    },
  ])
  const [label, setLabel] = useState<string>('')
  const setInputDate = (
    start?: Date,
    end?: Date,
    startTime?: {hour: number; minute: number},
    endTime?: {hour: number; minute: number}
  ): [Date, Date] => {
    const startDateTime = DateTime.fromJSDate(start).set({
      hour: withTimePicker ? startTime.hour : 0,
      minute: withTimePicker ? startTime.minute : 0,
      second: 0,
      millisecond: 0,
    })
    const endDateTime = DateTime.fromJSDate(end).set({
      hour: withTimePicker ? endTime.hour : 23,
      minute: withTimePicker ? endTime.minute : 59,
      second: 59,
      millisecond: 999,
    })
    if (start !== undefined && end !== undefined) {
      const label = [
        dateTimeToFormat(startDateTime, {withTime: withTimePicker}),
        dateTimeToFormat(endDateTime, {withTime: withTimePicker}),
      ]
      setLabel(label.join(' até '))
    }
    return [startDateTime.toJSDate(), endDateTime.toJSDate()]
  }

  const onDone = () => {
    onChange?.(setInputDate(dates[0], dates[1], times[0], times[1]))
    setIsPopoverOpen(false)
  }
  useEffect(() => {
    if (value !== undefined) {
      setDates(value)
      const valueTime = [
        {
          hour: value[0]?.getHours() ?? 0,
          minute: value[0]?.getMinutes() ?? 0,
        },
        {
          hour: value[1]?.getHours() ?? 23,
          minute: value[1]?.getMinutes() ?? 59,
        },
      ]
      setTimes(valueTime)
      setInputDate(value[0], value[1], valueTime[0], valueTime[1])
    } else {
      setDates([undefined, undefined])
      setTimes([
        {
          hour: 0,
          minute: 0,
        },
        {
          hour: 23,
          minute: 59,
        },
      ])
      setLabel('')
      setIsPopoverOpen(false)
    }
  }, [value])
  return (
    <Container>
      <CollapsedInput
        onClick={() => setIsPopoverOpen(true)}
        onFocus={onFocus}
        icon={GoCalendar}
        collapse={(collapseInput ?? false) && !isPopoverOpen}
        hasError={hasError}
        value={label}
        placeholder={placeholder}
        readOnly
      />
      <Popover
        position={position}
        isOpen={isPopoverOpen}
        header={title}
        footer={
          <PopoverFooter>
            <Button
              text="Cancelar"
              onClick={() => setIsPopoverOpen(false)}
              backgroundColor={colors.font.lighGray}
              textColor={colors.font.purpleDark}
              borderColor={colors.border.modal}
            />
            <Button
              disabled={dates[0] === undefined}
              text="Pronto"
              onClick={onDone}
              backgroundColor={colors.font.purpleDark}
            />
          </PopoverFooter>
        }>
        <PopoverBody>
          <CalendarContainer>
            <Calendar
              value={dates}
              onChange={(value: [Date, Date]) => setDates(value)}
              showDoubleView={true}
              selectRange={true}
            />
          </CalendarContainer>
          {withTimePicker && (
            <TimeContainer>
              <TimeContent>
                <TimeLabel>início</TimeLabel>
                <DateContainer>
                  {dates[0] !== undefined
                    ? DateTime.fromJSDate(dates[0]).toLocaleString(
                        DateTime.DATE_FULL
                      )
                    : '-'}
                </DateContainer>
                <TimePicker
                  value={times[0]}
                  onChange={(time) => {
                    setTimes((current) => [time, current[1]])
                  }}
                />
              </TimeContent>
              <TimeContent>
                <TimeLabel>final</TimeLabel>
                <DateContainer>
                  {dates[0] !== undefined
                    ? DateTime.fromJSDate(dates[1]).toLocaleString(
                        DateTime.DATE_FULL
                      )
                    : '-'}
                </DateContainer>
                <TimePicker
                  value={times[1]}
                  onChange={(time) => {
                    setTimes((current) => [current[0], time])
                  }}
                />
              </TimeContent>
            </TimeContainer>
          )}
        </PopoverBody>
      </Popover>
    </Container>
  )
}
