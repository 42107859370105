import React, {FC, ReactElement, useEffect, useRef, useState} from 'react'
import {BodyContainer, Container, FilterHeader} from './style'
import Button from '~/components/Button'
import {ListCustomerFilterBar} from '~/pages/CustomerAnalysis/ListCustomer/ListCustomerFilterBar'
import {
  Paginator,
  Table,
  TableCol,
  TableHeader,
  TableHeaderCol,
  TableRow,
} from '~/components/NewTable'
import {useQuery} from 'react-query'
import {CSVGenerator, CSVRef} from '~/components/CSVGenerator'
import {getCustomers} from '~/services/apiManagerService'
import {numberFormat} from '~/utils'
import {useHistory} from 'react-router-dom'
import {CustomerListItem} from '~/types/ApiManagerTypes'
import {ListCustomerFilters} from '~/pages/CustomerAnalysis/ListCustomer/ListCustomerFilters'

export const ListCustomer: FC = (): ReactElement => {
  const history = useHistory()
  const csvRef = useRef<CSVRef>()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(15)
  const [filters, setFilters] = useState({})
  const [enabled, setEnabled] = useState(false)
  const {data, isLoading, isPreviousData} = useQuery(
    [
      'customerAnalysisList',
      {
        page,
        perPage,
        ...filters,
      },
    ],
    async () => getCustomers(page, perPage, filters),
    {
      keepPreviousData: true,
      enabled: enabled,
    }
  )
  const getClusterName = (slug: string) => {
    switch (slug) {
      case 'brass':
        return 'Latão'
      case 'bronze':
        return 'Bronze'
      case 'silver':
        return 'Prata'
      case 'gold':
        return 'Ouro'
    }
  }
  useEffect(() => {
    if (enabled) {
      setEnabled(false)
    }
  }, [enabled])
  return (
    <Container>
      <ListCustomerFilters
        onSubmit={(clusters) => {
          setFilters({clusters})
          setEnabled(true)
        }}
      />
      <CSVGenerator
        csvRef={csvRef}
        request={(csvPage) => getCustomers(csvPage, 1000, filters)}
        csvName={`customers`}
        map={(customer: CustomerListItem) => {
          return {
            email: customer.email,
            pontos: numberFormat(customer.score, 0),
            nivel: getClusterName(customer.cluster),
          }
        }}
      />
      {!isLoading && data?.data.length > 0 && (
        <BodyContainer>
          <Container>
            <FilterHeader>
              <ListCustomerFilterBar
                onClickCsvButton={() => {
                  if (csvRef.current) {
                    void csvRef.current.generateCSV()
                  }
                }}
                count={data?.meta.pagination.count ?? 0}
              />
            </FilterHeader>
            <>
              <Table isLoading={isPreviousData}>
                <TableHeader>
                  <TableHeaderCol>e-mail</TableHeaderCol>
                  <TableHeaderCol flex={0.3}>Pontos</TableHeaderCol>
                  <TableHeaderCol flex={0.3}>Nível</TableHeaderCol>
                  <TableHeaderCol></TableHeaderCol>
                </TableHeader>
                {data.data.map((row) => (
                  <TableRow key={row.id}>
                    <TableCol>{row.email}</TableCol>
                    <TableCol flex={0.3}>{numberFormat(row.score, 0)}</TableCol>
                    <TableCol flex={0.3}>
                      {getClusterName(row.cluster)}
                    </TableCol>
                    <TableCol style={{justifyContent: 'end'}}>
                      <Button
                        text="Ver Perfil de Cliente"
                        onClick={() => history.push(`/cliente/${row.email}`)}
                      />
                    </TableCol>
                  </TableRow>
                ))}
              </Table>
              <Paginator
                isLoading={isPreviousData}
                currentPage={page}
                perPage={perPage}
                totalItems={data?.meta.pagination.count}
                onChange={(page, perPage) => {
                  setPage(page)
                  setPerPage(perPage)
                  setEnabled(true)
                }}
                perPageOptions={[15, 30, 45, 60]}
              />
            </>
          </Container>
        </BodyContainer>
      )}
    </Container>
  )
}
