import {create} from 'apisauce'
import {keyStorageTokenLogin} from '~/utils/keystorage'

const api = create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'x-token-api': JSON.parse(sessionStorage.getItem(keyStorageTokenLogin)),
  },
  timeout: 300000,
})

export const devApi = create({
  baseURL: 'http://localhost:5000',
})

export default api
