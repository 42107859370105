import api from '~/config/apiSauce'
import {
  ApiManagerPagination,
  Cashback,
  Customer,
  CustomerListItem,
  Referral,
  Score,
} from '~/types/ApiManagerTypes'
import {createJsonDateHandler} from '~/utils'
import {DateTime} from 'luxon'

const prefix = ''

export async function getCustomer(email: string): Promise<Customer> {
  const response = await api.get<Customer>(
    `${prefix}/customer/${email}`,
    undefined,
    {
      transformResponse: createJsonDateHandler(['createdAt']),
    }
  )
  if (!response.ok) {
    throw new Error()
  }
  return response.data
}

export async function getCashback(
  email: string,
  page: number,
  limit: number,
  filter: {
    movementType?: string
    createdAt?: DateTime
  }
): Promise<ApiManagerPagination<Cashback>> {
  const response = await api.get<ApiManagerPagination<Cashback>>(
    `${prefix}/customer/${email}/cashback`,
    {
      page,
      limit,
      ...filter,
    },
    {
      transformResponse: createJsonDateHandler(['createdAt']),
    }
  )
  if (!response.ok) {
    throw new Error()
  }
  return response.data
}

export async function getReferrals(
  email: string,
  page: number,
  limit: number,
  filter: {
    referredEmail?: string
  }
): Promise<ApiManagerPagination<Referral>> {
  const response = await api.get<ApiManagerPagination<Referral>>(
    `${prefix}/customer/${email}/referrals`,
    {
      page,
      limit,
      ...filter,
    },
    {
      transformResponse: createJsonDateHandler(['createdAt']),
    }
  )
  if (!response.ok) {
    throw new Error()
  }
  return response.data
}

export async function getScores(
  email: string,
  page: number,
  limit: number,
  filter: {
    movementType?: string
  }
): Promise<ApiManagerPagination<Score>> {
  const response = await api.get<ApiManagerPagination<Score>>(
    `${prefix}/customer/${email}/scores`,
    {
      page,
      limit,
      ...filter,
    },
    {
      transformResponse: createJsonDateHandler(['createdAt']),
    }
  )
  if (!response.ok) {
    throw new Error()
  }
  return response.data
}

export async function getCustomers(
  page: number,
  limit: number,
  filter: {
    clusters?: string[]
  }
): Promise<ApiManagerPagination<CustomerListItem>> {
  const response = await api.get<ApiManagerPagination<CustomerListItem>>(
    `${prefix}/customer`,
    {
      page,
      limit,
      ...filter,
    },
    {
      transformResponse: createJsonDateHandler(['createdAt']),
    }
  )
  if (!response.ok) {
    throw new Error()
  }
  return response.data
}
