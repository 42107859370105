import {useRef, KeyboardEvent, useState, useEffect, ReactElement} from 'react'
import {useTheme} from 'styled-components'
import Select from 'react-select'
import {useQueryClient} from 'react-query'
import {AiOutlineSearch} from 'react-icons/ai'
import {BiFilterAlt} from 'react-icons/bi'
import br from 'date-fns/locale/pt-BR'
import ReactDatePicker, {registerLocale} from 'react-datepicker'
import {getDate, lastDayOfMonth, getMonth, getYear} from 'date-fns'
import './calendar-date.css'
import {
  Container,
  WrapInputText,
  ToggleInput,
  ButtonCheck,
  InputTypeText,
  WrapDate,
  WrapImageDate,
  ImgDate,
  InputTyDate,
} from './styles'
import calendar from '~/assets/images/calendar.svg'
registerLocale('br', br)
import {
  keyStorageTabDate,
  keyStorageTotalPositive,
  keyStorageTotalNegative,
} from '~/utils/keystorage'
import {useMovementProvider} from '~/hooks/useMovementProvider'
import {useFinancialProvider} from '~/hooks/useFinancialProvider'
import {SelectOptionsProps} from '~/types'

export default function HeaderInputFilter(): ReactElement {
  const {colors} = useTheme()
  const {
    updateDayMax,
    minDate,
    maxDate,
    updateMinDate,
    updateMaxDate,
    updateFilterByEmail,
    updateFilterByType,
    updateDayMin,
    updateYearMonth,
    filterByType,
  } = useMovementProvider()
  const clientQuery = useQueryClient()
  const {tab, tabMonthClicked} = useFinancialProvider()
  const storageTabDate = sessionStorage.getItem(keyStorageTabDate)
  const tabDateCurrent = storageTabDate
    ? new Date(JSON.parse(storageTabDate))
    : tab.date
  const dateCurrent = new Date()
  const monthCurrent = getMonth(dateCurrent)
  const maxDayCurrent =
    tab.date.getMonth() === monthCurrent
      ? getDate(dateCurrent)
      : getDate(lastDayOfMonth(new Date(tab.date)))
  const refInputTypeText = useRef<HTMLInputElement>(null)
  const refInputTypeDate = useRef<ReactDatePicker>(null)

  const [showFieldSearch, setShowFieldSearch] = useState(false)
  const [dateRange, setDateRange] = useState<[Date, Date]>([null, null])
  const [initialRange, endRange] = dateRange
  const [isInputDateClicked, setIsInputDateClicked] = useState(false)
  const [emailFilter, setEmailFilter] = useState('')

  const options = [
    {value: 'movement', label: 'Todas movimentações'},
    {value: 'credit', label: 'Valores  creditados'},
    {value: 'debit', label: 'Créditos utilizados'},
    {value: 'cancellation', label: 'Créditos cancelados'},
    {value: 'expiration', label: 'Créditos expirados'},
  ]

  const startDate = initialRange ? initialRange : 1
  let endDate = endRange
    ? endRange
    : getDate(lastDayOfMonth(new Date(tab.date)))

  if (tab.date.getMonth() === monthCurrent) {
    endDate = tab.date.getDate()
  }

  useEffect(() => {
    if (tabMonthClicked) {
      setDateRange([null, null])
      setIsInputDateClicked(false)
      sessionStorage.removeItem(keyStorageTotalPositive)
      sessionStorage.removeItem(keyStorageTotalNegative)
      updateMaxDate(
        new Date(new Date(getYear(tab.date), getMonth(tab.date), maxDayCurrent))
      )
      updateMinDate(new Date(getYear(tab.date), getMonth(tab.date), 1))
      updateFilterByType({
        value: 'movement',
        label: 'Todas movimentações',
      })
      updateFilterByEmail('')
      setEmailFilter('')
    }
    if (initialRange && endRange) {
      const year = getYear(initialRange)
      const month =
        getMonth(initialRange) + 1 < 10
          ? `0${getMonth(initialRange) + 1}`
          : getMonth(initialRange) + 1
      const dayMin =
        getDate(initialRange) < 10
          ? `0${getDate(initialRange)}`
          : `${getDate(initialRange)}`
      const dayMax =
        getDate(endRange) < 10
          ? `0${getDate(endRange)}`
          : `${getDate(endRange)}`
      updateDayMax(`${dayMax}`)
      updateDayMin(`${dayMin}`)
      updateYearMonth(`${year}-${month}`)
    }
    return () => {
      const year = getYear(tab.date)
      const month =
        getMonth(tab.date) + 1 < 10
          ? `0${getMonth(tab.date) + 1}`
          : getMonth(tab.date) + 1

      if (tab.date.getMonth() === monthCurrent) {
        const today =
          new Date().getDate() < 10
            ? `0${new Date().getDate()}`
            : `${new Date().getDate()}`
        updateYearMonth(`${year}-${month}`)
        updateDayMax(today)
        updateDayMin('01')
      } else {
        updateYearMonth(`${year}-${month}`)
        updateDayMax('01')
        updateDayMax(`${getDate(lastDayOfMonth(tabDateCurrent))}`)
      }
    }
  }, [
    dateRange,
    initialRange,
    endRange,
    tabMonthClicked,
    endRange,
    startDate,
    endDate,
  ])

  function handleInputCheck() {
    refInputTypeText.current?.focus()
    setShowFieldSearch((previous) => !previous)
  }

  function handleWeekDayClassName(date: Date) {
    const [start, end] = dateRange
    if (start !== null || end !== null) return 'class-day-not-min-max'
    const calendarDay = getDate(date)
    const calendarMonth = getMonth(date)
    const currentMonth = getMonth(tabDateCurrent)
    if (startDate === calendarDay && currentMonth === calendarMonth) {
      return 'class-day-min-max'
    }
    if (endDate === calendarDay && currentMonth === calendarMonth) {
      return 'class-day-min-max'
    }
    if (
      calendarDay > startDate &&
      calendarDay < endDate &&
      calendarMonth === currentMonth
    ) {
      return 'class-day-range'
    }

    return 'class-day-not-min-max'
  }

  const handleClearFilter = (): void => {
    setDateRange([null, null])
    updateFilterByEmail('')
    setEmailFilter('')
    setShowFieldSearch(false)
    setIsInputDateClicked(false)
    sessionStorage.removeItem(keyStorageTotalPositive)
    sessionStorage.removeItem(keyStorageTotalNegative)
    updateMaxDate(
      new Date(new Date(getYear(tab.date), getMonth(tab.date), maxDayCurrent))
    )
    updateMinDate(new Date(getYear(tab.date), getMonth(tab.date), 1))
    updateFilterByType({
      value: 'movement',
      label: 'Todas movimentações',
    })
    clientQuery.clear()
  }

  const handleChangeDate = (update) => setDateRange(update)

  const handleFocus = () => setIsInputDateClicked(true)

  const handleBlur = () => setIsInputDateClicked(false)

  const handleSelect = async (it) => {
    if (it.value === 'movement') {
      updateFilterByType(it)
      clientQuery.clear()
      return
    }
    updateFilterByType(it)
    clientQuery.clear()
  }

  const getValue = (option: Partial<SelectOptionsProps>) => option.value

  const getLabel = (option: Partial<SelectOptionsProps>) => option.label

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      updateFilterByEmail(emailFilter)
      clientQuery.clear()
    }
  }
  const customStyles = {
    indicatorSeparator: () => ({
      display: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      fontWeight: 400,
      fontSize: '13px',
      color: state.isSelected && colors.primaryYellow.main,
      backgroundColor: state.isFocused
        ? colors.primaryYellow.light
        : colors.input.background,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: colors.background.disabled,
        color: colors.primaryYellow.main,
      },
      '&:first-of-type': {
        borderBottomColor: colors.primaryYellow.main,
      },
    }),
    control: (_, state) => ({
      display: 'flex',
      alignItems: 'center',
      padding: '2px 2px 2px 10px',
      cursor: 'pointer',
      border: '1px',
      borderRadius: '3px',
      borderStyle: 'solid',
      height: '26px',
      minWidth: '200px',
      borderWidth: '1px',
      borderColor: state.isFocused
        ? colors.primaryYellow.main
        : colors.border.main,
    }),
  }

  return (
    <Container>
      {!showFieldSearch && (
        <ToggleInput type="button" onClick={handleInputCheck}>
          <AiOutlineSearch size={20} />
        </ToggleInput>
      )}
      {showFieldSearch && (
        <WrapInputText>
          <InputTypeText
            autoFocus
            ref={refInputTypeText}
            type="email"
            placeholder="Pesquisar"
            value={emailFilter}
            onKeyDown={(event) => handleKeyPress(event)}
            onChange={(text) => setEmailFilter(text.target.value)}
          />

          <ButtonCheck type="button" onClick={handleInputCheck}>
            <AiOutlineSearch size={20} />
          </ButtonCheck>
        </WrapInputText>
      )}
      <Select
        defaultValue={options[0]}
        getOptionLabel={getLabel}
        getOptionValue={getValue}
        value={filterByType}
        options={options}
        onChange={handleSelect}
        styles={customStyles}
      />
      <WrapDate isClickedInput={isInputDateClicked}>
        <WrapImageDate isClickedInput={isInputDateClicked}>
          <ImgDate src={calendar} />
        </WrapImageDate>
        <InputTyDate
          isClickedInput={isInputDateClicked}
          dateFormat="dd/MM"
          ref={refInputTypeDate}
          selectsRange
          startDate={initialRange}
          onBlur={handleBlur}
          endDate={endRange}
          onFocus={handleFocus}
          maxDate={maxDate}
          minDate={minDate}
          onChange={(update) => handleChangeDate(update)}
          locale="br"
          dayClassName={(date) => handleWeekDayClassName(date)}
          showDisabledMonthNavigation
        />
      </WrapDate>
      <ToggleInput onClick={handleClearFilter}>
        <BiFilterAlt size={20} />
      </ToggleInput>
    </Container>
  )
}
