import React, {ReactElement} from 'react'
import ReactCalendar, {CalendarProps as CalendarLibProps} from 'react-calendar'
import {CalendarContainer} from './styles'

interface CalendarProps<T = boolean>
  extends Omit<CalendarLibProps, 'onChange' | 'selectRange'> {
  selectRange?: T
  onChange: T extends true
    ? (value: [Date, Date]) => void
    : (value: Date) => void
}

export const Calendar: React.FC<CalendarProps> = (props): ReactElement => {
  return (
    <CalendarContainer>
      <ReactCalendar
        calendarType="US"
        prev2Label={null}
        next2Label={null}
        {...props}
        onChange={props.onChange as any}
      />
    </CalendarContainer>
  )
}
