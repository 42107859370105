import styled from 'styled-components'

export const PopoverContainer = styled.div`
  position: absolute;
  z-index: 3;
  &.bottom {
    top: calc(100% + 4px);
  }
  &.top {
    bottom: calc(100% + 4px);
  }
  &.right {
    right: 0;
  }
  &.left {
    left: 0;
  }
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
`

export const PopoverWrapper = styled.div<{width?: string}>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${({width}) => width ?? '80%'};
  max-width: 600px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  z-index: 9999;
`

export const Header = styled.div`
  background-color: ${({theme}) => theme.colors.input.background};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 8px;
  border-bottom: 1px solid ${({theme}) => theme.colors.border.main};
`

export const Body = styled.div`
  padding: 20px;
  background-color: ${({theme}) => theme.colors.background.white};
`

export const Footer = styled.div`
  background-color: ${({theme}) => theme.colors.input.background};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 8px;
  border-top: 1px solid ${({theme}) => theme.colors.border.main};
`
