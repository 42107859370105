import styled from 'styled-components'

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  padding-left: 30px;
  padding-right: 30px;

  & > label {
    font-size: 14px;
    padding-right: 15px;
    width: 200px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }

  & > input {
    flex-grow: 1;
  }
`
