import React, {
  FocusEventHandler,
  ReactElement,
  useEffect,
  useState,
} from 'react'
import {GoCalendar} from 'react-icons/go'
import {
  CalendarContainer,
  Container,
  DateContainer,
  PopoverBody,
  PopoverFooter,
  TimeContainer,
  TimeContent,
} from './styles'
import Popover from '~/components/Popover'
import Button from '~/components/Button'
import {useTheme} from 'styled-components'
import {Calendar} from '~/components/Calendar'
import {DateTime} from 'luxon'
import {TimePicker} from '~/components/TimePicker'
import {dateTimeToFormat} from '~/utils'
import {CollapsedInput} from '~/components/CollapsedInput'

interface InputDatePickerProps {
  value?: Date | undefined
  onChange?: (value: Date) => void
  position?: 'bottom right' | 'bottom left' | 'top right' | 'top left'
  title?: string
  collapseInput?: boolean
  withTimePicker?: boolean
  placeholder?: string
  hasError?: boolean
  onFocus?: FocusEventHandler<HTMLInputElement>
}

export const InputDatePicker: React.FC<InputDatePickerProps> = ({
  value,
  onChange,
  position,
  title,
  collapseInput,
  withTimePicker,
  placeholder,
  hasError,
  onFocus,
}): ReactElement => {
  const {colors} = useTheme()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [date, setDate] = useState<Date>(undefined)
  const [time, setTime] = useState({
    hour: 0,
    minute: 0,
  })
  const [label, setLabel] = useState<string>('')
  const setInputDate = (
    date?: Date,
    time?: {hour: number; minute: number}
  ): Date => {
    const dateTime = DateTime.fromJSDate(date).set({
      hour: withTimePicker ? time.hour : 0,
      minute: withTimePicker ? time.minute : 0,
      second: 0,
      millisecond: 0,
    })
    if (date !== undefined) {
      setLabel(dateTimeToFormat(dateTime, {withTime: withTimePicker}))
    } else {
      setLabel('')
    }
    return dateTime.toJSDate()
  }

  const onDone = () => {
    onChange?.(setInputDate(date, time))
    setIsPopoverOpen(false)
  }
  useEffect(() => {
    if (value !== undefined) {
      setDate(value)
      const valueTime = {
        hour: value?.getHours() ?? 0,
        minute: value?.getMinutes() ?? 0,
      }

      setTime(valueTime)
      setInputDate(value, valueTime)
    } else {
      setDate(undefined)
      setTime({
        hour: 0,
        minute: 0,
      })
      setLabel('')
      setIsPopoverOpen(false)
    }
  }, [value])
  return (
    <Container>
      <CollapsedInput
        onClick={() => setIsPopoverOpen(true)}
        onFocus={onFocus}
        icon={GoCalendar}
        collapse={(collapseInput ?? false) && !isPopoverOpen}
        hasError={hasError}
        value={label}
        placeholder={placeholder}
        readOnly
      />
      <Popover
        position={position}
        isOpen={isPopoverOpen}
        header={title}
        footer={
          <PopoverFooter>
            <Button
              text="Cancelar"
              onClick={() => setIsPopoverOpen(false)}
              backgroundColor={colors.font.lighGray}
              textColor={colors.font.purpleDark}
              borderColor={colors.border.modal}
            />
            <Button
              disabled={date === undefined}
              text="Pronto"
              onClick={onDone}
              backgroundColor={colors.font.purpleDark}
            />
          </PopoverFooter>
        }>
        <PopoverBody>
          <CalendarContainer>
            <Calendar value={date} onChange={(value: Date) => setDate(value)} />
          </CalendarContainer>
          {withTimePicker && (
            <TimeContainer>
              <TimeContent>
                <DateContainer>
                  {date !== undefined
                    ? DateTime.fromJSDate(date).toLocaleString(
                        DateTime.DATE_FULL
                      )
                    : '-'}
                </DateContainer>
                <TimePicker
                  value={time}
                  onChange={() => {
                    setTime((current) => current)
                  }}
                />
              </TimeContent>
            </TimeContainer>
          )}
        </PopoverBody>
      </Popover>
    </Container>
  )
}
