import styled from 'styled-components'

type PropsBalance = {
  type: boolean
}

export const ContainerButton = styled.button`
  transition: all 0.2s ease;
  &:hover {
    opacity: 0.7;
  }
`

export const Container = styled.section<PropsBalance>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 13px 15px;
  background-color: ${({theme}) => theme.colors.background.white};
  border-left: 10px solid
    ${({type, theme}) =>
      type ? theme.colors.border.input.green : theme.colors.border.input.red};
`

export const WrapMovement = styled.div`
  display: flex;
  gap: 90px;
  justify-content: space-between;
`

export const WrapIconMovement = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;
`

export const Hour = styled.span`
  font-size: 14px;
  line-height: 16px;
  font-weight: 300;
  color: ${({theme}) => theme.colors.font.placeHolder};
`

export const Icon = styled.img`
  width: 20px;
  height: 20px;
`

export const TitleMovement = styled(Hour)`
  color: ${({theme}) => theme.colors.font.main};
`

export const TitleClient = styled(Hour)`
  color: ${({theme}) => theme.colors.font.main};
`

export const NumberDemand = styled(Hour)`
  color: ${({theme}) => theme.colors.font.main};
`

export const Balance = styled.span<PropsBalance>`
  color: ${({type, theme}) =>
    type ? theme.colors.border.input.green : theme.colors.border.input.red};
  text-transform: uppercase;
`
