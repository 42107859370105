import React, {ReactElement, ReactNode} from 'react'
import {Container} from './styles'

interface DrawerDivisionProps {
  title: string
  titleColor: string
  children: ReactNode
}

function DrawerDivision({
  title,
  titleColor,
  children,
}: DrawerDivisionProps): ReactElement {
  return (
    <Container titleColor={titleColor}>
      <div className="title-container">
        <span>{title}</span>
      </div>
      {children}
    </Container>
  )
}

export default DrawerDivision
