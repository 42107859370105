import react, {InputHTMLAttributes, ReactElement} from 'react'
import {Input, InputWrapper, Label} from './styles'

interface InputMoneyProps extends InputHTMLAttributes<HTMLInputElement> {
  inputWidth?: string
  currency: string
  customBorderRadius?: string
  hasError?: boolean
}

const InputMoney: react.FC<InputMoneyProps> = ({
  currency,
  customBorderRadius,
  hasError,
  ...rest
}): ReactElement => {
  return (
    <InputWrapper hasError={hasError} customBorderRadius={customBorderRadius}>
      <Label>
        {currency}
        <Input name={currency} {...rest}></Input>
      </Label>
    </InputWrapper>
  )
}
export default InputMoney
