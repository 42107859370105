import styled from 'styled-components'

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const TopHeader = styled.div`
  border-bottom: 1px solid ${({theme}) => theme.colors.border.main};
  width: 100%;
`

export const WrapTopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 0px;
`

export const Title = styled.span`
  font-weight: 300px;
  font-size: 18px;
  color: ${({theme}) => theme.colors.font.main};
`
