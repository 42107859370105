import React, {FC, PropsWithChildren, ReactElement, ReactNode} from 'react'
import {
  Body,
  Footer,
  Header,
  Overlay,
  PopoverContainer,
  PopoverWrapper,
} from './styles'

interface ModalProps {
  isOpen: boolean
  onClose?: () => void
  header?: ReactNode
  footer?: ReactNode
  width?: string
}

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  onClose,
  header,
  children,
  footer,
  isOpen,
  width,
}) => {
  return (
    <>
      {isOpen && (
        <>
          <Overlay onClick={onClose} />
          <PopoverWrapper width={width}>
            {header && <Header>{header}</Header>}
            <Body>{children}</Body>
            {footer && <Footer>{footer}</Footer>}
          </PopoverWrapper>
        </>
      )}
    </>
  )
}
