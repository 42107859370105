import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  top: 0;
  margin: 24px;
  width: 96%;
  align-items: flex-start;
`
export const Header = styled.h3`
  font-size: 12px;
`
export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15%;
  margin-top: 20px;
  align-items: flex-start;
  flex: 1;
  width: 100%;
`
export const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 36px;
  width: 60%;
`

export const FieldLabel = styled.h3`
  white-space: nowrap;
  width: 120px;
  min-width: 120px;
  font-size: 16px;
  margin-right: 24px;
  color: ${({theme}) => theme.colors.font.main};
  font-weight: lighter;
  text-align: end;
`
