import React, {FC, useEffect, useState} from 'react'
import {PageHeader} from '~/components/PageHeader'
import SelectInput from '~/components/SelectInput'
import {FaFilter} from 'react-icons/fa'
import {Button} from './style'
import {InputDatePicker} from '~/components/InputDatePicker'
import {useTheme} from 'styled-components'

interface CashbackFilterBarProps {
  onClickCsvButton: () => any
  count: number
  onChange: (values: {date?: Date; movementType?: string}) => void
}

export const CashbackFilterBar: FC<CashbackFilterBarProps> = ({
  onClickCsvButton,
  count,
  onChange,
}) => {
  const movementTypes = [
    {
      label: 'Todas as Movimentações',
      value: '',
    },
    {
      label: 'Somente créditos',
      value: 'credit',
    },
    {
      label: 'Somente débitos',
      value: 'debit',
    },
    {
      label: 'Somente cancelados',
      value: 'cancellation',
    },
    {
      label: 'Somente Expirados',
      value: 'expiration',
    },
  ]
  const theme = useTheme()
  const [createdAt, setCreatedAt] = useState<Date>(undefined)
  const [movementTypeOption, setMovementTypeOption] = useState(movementTypes[0])
  useEffect(() => {
    const data = {
      createdAt,
      movementType:
        movementTypeOption.value !== '' ? movementTypeOption.value : undefined,
    }
    onChange(data)
  }, [createdAt, movementTypeOption])
  return (
    <PageHeader title={`${count} movimentações encontradas`}>
      <InputDatePicker
        title="Selecione a data"
        position="bottom right"
        value={createdAt}
        onChange={(date) => setCreatedAt(date)}
        collapseInput
      />
      <SelectInput
        options={movementTypes}
        value={movementTypeOption}
        onChange={(val) => setMovementTypeOption(val)}
      />
      <Button
        onClick={onClickCsvButton}
        style={{
          color: theme.colors.font.purpleMain,
          width: 'auto',
          paddingLeft: 20,
          paddingRight: 20,
          fontWeight: 'bold',
        }}>
        Exportar extrato em .csv
      </Button>
      <Button
        onClick={() => {
          setCreatedAt(undefined)
          setMovementTypeOption(movementTypes[0])
        }}>
        <FaFilter />
      </Button>
    </PageHeader>
  )
}
