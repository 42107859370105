import styled from 'styled-components'

export const Table = styled.div`
  border-left: 1px solid ${({theme}) => theme.colors.border.main};
  border-right: 1px solid ${({theme}) => theme.colors.border.main};
  display: flex;
  gap: 50px;
  width: 100%;
  justify-content: space-around;
  padding: 0 50px;
`

export const TableBody = styled.div`
  align-items: center;
  width: 230px;
  display: flex;
  gap: 20px;
  background-color: #fff;
  padding: 16px 0;
  &.secondaryTable {
    align-items: flex-end;
  }
`

export const TableItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 230px;
  text-align: right;
  .text {
    color: ${({theme}) => theme.colors.font.dark};
    &:last-child {
      position: relative;
      margin-top: 4px;
      &::before {
        content: '';
        position: absolute;
        width: 280px;
        height: 1px;
        background-color: #ffbe00;
        left: -18px;
        background-color: ${({theme}) => theme.colors.primaryYellow.main};
      }
    }
  }
  .value {
    text-align: right;
    position: relative;
    &:last-child {
      position: relative;
      margin-top: 4px;
    }
  }

  .value.signalMinus::after {
    content: '-';
    position: absolute;
    right: -20px;
  }
  .value.signalMore::after {
    content: '+';
    position: absolute;
    right: -20px;
  }
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  transform: all 0.5s;
  text-align: right;
  &:hover {
    opacity: 0.7;
  }
  .title {
    line-height: 19px;
    font-size: 17px;
    color: ${({theme}) => theme.colors.font.purpleMain};
    text-align: right;
    &:hover {
      color: ${({theme}) => theme.colors.font.purpleDark};
      cursor: pointer;
    }
  }
`
