import styled, {css} from 'styled-components'

export const InputContainer = styled.div`
  display: flex;
  border-radius: 8px;
  width: 100%;
  box-shadow: 1px 1px 4px rgb(0 0 0 / 25%);
  & button {
    height: 32px;
  }
  & > div:last-child button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  & > div:first-child button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`

interface InputProps {
  haveFocus: boolean
  inputWidth?: string
  hasError?: boolean
}

export const InputText = styled.input<InputProps>`
  font-size: 13px;
  font-weight: 300;
  flex: 1;
  float: left;
  width: ${({inputWidth}) => (inputWidth ? inputWidth : '100%')};
  box-sizing: border-box;
  padding: 6px 12px;
  height: 32px;
  border-radius: 8px;
  border-color: ${({theme}) => theme.colors.background.gray};
  background: ${({theme, hasError}) =>
    hasError ? theme.colors.input.error : theme.colors.input.background};
  ${({haveFocus}) =>
    haveFocus &&
    css`
      background-color: ${({theme}) => theme.colors.background.white};
    `}
`

export const AdornmentContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`
