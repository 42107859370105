import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  top: 0;
  margin: 24px;
  border-bottom: 1px solid ${({theme}) => theme.colors.border.modal};
  width: 96%;
  align-items: flex-start;
`

export const Header = styled.h3`
  font-size: 12px;
`
export const InformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20%;
  margin-top: 20px;
  align-items: flex-start;
`

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`

export const InformationLabel = styled.h3`
  white-space: nowrap;
  font-size: 16px;
  margin-right: 24px;
  color: ${({theme}) => theme.colors.font.main};
  font-weight: lighter;
`
export const Information = styled.span``
