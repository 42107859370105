import {ReactElement} from 'react'
import {useIndicationYear} from '~/components/ApexChartLineAnual/useIndicationYear'
import {useIndicationMonth} from '~/components/ApexChartLineMonthly/useIndicationMonth'
import {useBarIndication} from '~/components/ApexChartRadialBar/useBarIndication'
import Indication from '~/components/Indications'
import {Loading} from '~/components/Load'
import PageContainer from '~/components/PageContainer'

function Indications(): ReactElement {
  const {isLoading: isLoadingYear} = useIndicationYear()
  const {isLoading: isLoadingMonth} = useIndicationMonth()
  const {isLoading: isLoadingBar} = useBarIndication()
  return (
    <PageContainer>
      {isLoadingYear || isLoadingMonth || isLoadingBar ? (
        <Loading />
      ) : (
        <Indication />
      )}
    </PageContainer>
  )
}

export default Indications
