import IconCartNegative from '~/assets/images/car_negative.png'
import IconWalletNegative from '~/assets/images/balance_negative.png'
import IconWalletPositive from '~/assets/images/positive.png'
import IconCreditExpired from '~/assets/images/creditExpired.png'

export const getHours = (date: string): string => {
  const datetime = new Date(date)
  const hours = datetime.getUTCHours()
  const minutes = datetime.getUTCMinutes()
  return hours < 10 ? `0${hours}:0${minutes}` : `${hours}:${minutes}`
}

export const formatMoney = (amount: number, shouldDivide = true): string => {
  const valueCurrent = amount / 100
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(shouldDivide ? valueCurrent : amount)
  return formatter
}

export const getIcon = (type: string): string => {
  const typeObject = {
    credit: IconWalletPositive,
    debit: IconCartNegative,
    cancellation: IconWalletNegative,
    expiration: IconCreditExpired,
  }
  return typeObject[type]
}

export const formatTitle = (title: string): string => {
  const titleObject = {
    credit: 'Valores creditados',
    debit: 'Créditos utilizados',
    cancellation: 'Créditos cancelados',
    expiration: 'Créditos expirados',
  }
  return titleObject[title]
}
