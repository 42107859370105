import {useState, Fragment, ReactElement} from 'react'
import {format, getMonth} from 'date-fns'
import {
  ContainerMain,
  Container,
  Tabs,
  Title,
  TableHeader,
  TableBody,
  FinancialCloseBlock,
  FinancialCloseText,
  FinancialCloseTextDark,
  ButtonRelatory,
} from './styles'
import RenderData from './RenderData/renderData'
import TabsItem from './TabsItem/TabsItem'
import PDF from '~/assets/images/pdf.svg'
import {keyStorageTabDate} from '~/utils/keystorage'
import {useFinancial} from '~/components/Financial/useFinancial'
import {useFinancialProvider} from '~/hooks/useFinancialProvider'
import {
  datesTab,
  DateTabProps,
  getMonthDayYear,
  transformDateNames,
} from './function'
import {ButtonIcon} from '~/components/Movement/ContentHome/styles'
import {useMovementProvider} from '~/hooks/useMovementProvider'

export default function FinancialClose(): ReactElement {
  const {yearMonth} = useMovementProvider()
  const {data, monthClose} = useFinancial()
  const dateCurrentStorage = sessionStorage.getItem(keyStorageTabDate)
  const dateCurrent = dateCurrentStorage
    ? new Date(JSON.parse(dateCurrentStorage))
    : new Date()
  const objectDate = getMonthDayYear(dateCurrent)
  const {updateTab, updateTabMonthClicked} = useFinancialProvider()
  const dataClosed =
    data.consolidationDate &&
    `${
      new Date(data.consolidationDate).getUTCDate() < 10
        ? `0${new Date(data.consolidationDate).getUTCDate()}`
        : new Date(data.consolidationDate).getUTCDate()
    }/${
      new Date(data.consolidationDate).getUTCMonth() + 1 < 10
        ? `0${new Date(data.consolidationDate).getUTCMonth() + 1}`
        : `${new Date(data.consolidationDate).getUTCMonth() + 1}`
    }/${new Date(data.consolidationDate).getUTCFullYear()}`
  const day =
    getMonth(new Date()) + 1 === Number(objectDate.month)
      ? objectDate.day
      : objectDate.lastDay
  const [periodDate, setPeriodDate] = useState(
    `01/${objectDate.month}/${objectDate.year} - ${day}/${objectDate.month}/${objectDate.year}`
  )
  const isMonthCurrent = getMonth(new Date()) + 1 === Number(objectDate.month)

  const changeBoard = (it: DateTabProps) => {
    const objectDate = getMonthDayYear(it.date)
    setPeriodDate(
      `01/${objectDate.month}/${objectDate.year} - ${objectDate.lastDay}/${objectDate.month}/${objectDate.year}`
    )
    format(it.date, 'yyyy-MM')
    sessionStorage.setItem(keyStorageTabDate, JSON.stringify(it.date))
    updateTab(it)
    updateTabMonthClicked(true)
  }

  return (
    <ContainerMain>
      <Title>
        <h1>Fechamento</h1>
      </Title>
      <Container>
        <TableHeader>
          <h2>FECHAMENTO MENSAL</h2>
          <Tabs>
            {datesTab.map((it) => {
              if (it.month === transformDateNames(new Date())) {
                it.month = 'Mês atual'
              }
              return (
                <Fragment key={it.id}>
                  <TabsItem onClick={() => changeBoard(it)} text={it.month} />
                </Fragment>
              )
            })}
          </Tabs>
        </TableHeader>
        <TableBody>
          <FinancialCloseBlock>
            <FinancialCloseText>
              <p>PERÍODO</p>
              {periodDate}
            </FinancialCloseText>
            <FinancialCloseTextDark>
              {data.totalMovements > 0 ? data.totalMovements : 0}
              <p>movimentações</p>
            </FinancialCloseTextDark>
          </FinancialCloseBlock>
          <RenderData />
          <FinancialCloseBlock>
            {isMonthCurrent ||
              (!data.isClosed && (
                <ButtonRelatory onClick={() => monthClose(yearMonth)}>
                  Consolidar relatório
                </ButtonRelatory>
              ))}
            {data.consolidationType === 'automatic' && (
              <FinancialCloseText className="text">
                <span> Disparo automático em {dataClosed} </span>
              </FinancialCloseText>
            )}

            {data.consolidationType === 'manual' && (
              <FinancialCloseText className="text">
                <span>Relatório disparado {dataClosed}</span>
              </FinancialCloseText>
            )}
            <FinancialCloseText className="relatory">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <p>RELATÓRIO RESUMIDO</p>
                <ButtonIcon
                  href={`${process.env.REACT_APP_BASE_URL}/movements/month/end-closing/pdf?month=${yearMonth}`}
                  download
                  target="blank">
                  <img
                    alt="icon pdf"
                    style={{
                      marginLeft: 5,
                    }}
                    width={28}
                    height={28}
                    src={PDF}
                  />
                </ButtonIcon>
              </div>
            </FinancialCloseText>
          </FinancialCloseBlock>
        </TableBody>
      </Container>
    </ContainerMain>
  )
}
