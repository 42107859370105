import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
`

export const MessageContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  margin-top: 15px;
`

export const IconContainer = styled.div`
  width: 30px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  & svg {
    fill: ${({theme}) => theme.colors.font.purpleMain};
  }
`
