import {ReactElement, useState} from 'react'
import {Redirect} from 'react-router-dom'
import {QueryClientProvider} from 'react-query'
import {queryClient} from '~/utils/clientQuery'
import {useAppSelector} from '~/app/hooks'
import defaultTheme from '~/assets/styles/themes/default'
import {
  ButtonsContainer,
  Container,
  Content,
  UserContainer,
  UserContent,
} from './styles'
import Header from '../Header'
import Drawer from '../Drawer'
import DrawerDivision from '~/components/Drawer/DrawerDivision'
import DrawerItem from '~/components/Drawer/DrawerItem'
import toast, {Toaster} from 'react-hot-toast'
import {keyStorageTokenLogin} from '~/utils/keystorage'
import AppRoutes from '~/routes/app.routes'

function App(): ReactElement {
  const [redirect, setRedirect] = useState(false)
  const drawerOpened = useAppSelector((state) => state.drawer.opened)
  let revokeSession: NodeJS.Timeout
  if (process.env.NODE_ENV !== 'development') {
    revokeSession = setTimeout(clearSession, 900000)
  }
  function clearSession() {
    toast('See you later', {icon: '👏'})
    sessionStorage.removeItem(keyStorageTokenLogin)
    setRedirect(true)
    clearTimeout(revokeSession)
    setTimeout(() => {
      location.reload()
    }, 700)
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Container>
        <Header
          backgroundColor=" linear-gradient(270deg, rgba(134, 0, 167, 1) -0%, rgba(97, 1, 121, 1) 100%)"
          menuColor="#fff"
          titleAlign="center">
          <ButtonsContainer>
            <UserContainer>
              <UserContent
                backgroundColor="#f9f9f9"
                textColor={defaultTheme.colors.secondary.main}>
                <span>T</span>
              </UserContent>
            </UserContainer>
          </ButtonsContainer>
        </Header>
        <Content drawerOpened={drawerOpened}>
          <Drawer backgroundColor={defaultTheme.colors.background.white}>
            <DrawerItem text="Home" navTo="/" />
            <DrawerDivision
              title="ANÁLISE DE CLIENTE"
              titleColor={defaultTheme.colors.font.dark}>
              <DrawerItem
                text="Página do cliente"
                navTo={{pathname: '/cliente', state: {tab: 'search'}}}
              />
              <DrawerItem
                text="Lista de clientes"
                navTo={{pathname: '/cliente', state: {tab: 'list'}}}
              />
            </DrawerDivision>
            <DrawerDivision
              title="GESTÃO DE CASHBACK"
              titleColor={defaultTheme.colors.font.dark}>
              <DrawerItem
                text="Bonificação manual"
                navTo="/bonificacao-manual"
              />
              <DrawerItem
                text="Regras de bonificação"
                navTo="/regras-bonificacao"
                pathName="/regras-bonificacao"
              />
            </DrawerDivision>
            <DrawerDivision
              title="GESTÃO DE CLUSTER"
              titleColor={defaultTheme.colors.font.dark}>
              <DrawerItem text="Pontuação manual" navTo="/pontuacao-manual" />
              <DrawerItem text="Missões" navTo="/" />
            </DrawerDivision>
            <DrawerDivision
              title="ANÁLISE DE RESULTADOS"
              titleColor={defaultTheme.colors.font.dark}>
              <DrawerItem
                text="Breakage"
                navTo="/breakage"
                pathName="/breakage"
              />
              <DrawerItem
                text="Indicações"
                navTo="/indicacoes"
                pathName="/indicacoes"
              />
              <DrawerItem
                text="Fechamento financeiro"
                navTo="/financeiro"
                pathName="/financeiro"
              />
            </DrawerDivision>
          </Drawer>
          <div className="main-content">
            <AppRoutes />
          </div>
        </Content>
        <Toaster />
        {redirect && <Redirect to="/" />}
      </Container>
    </QueryClientProvider>
  )
}

export default App
