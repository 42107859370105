import React, {ReactElement} from 'react'
import ChartLineMonthly from '~/components/ApexChartLineMonthly'
import {Container, Title, Hr} from './styles'

interface ConsolidaMensalChartProps {
  text: string
}

function ConsolidaMensalChart({text}: ConsolidaMensalChartProps): ReactElement {
  return (
    <Container>
      <div>
        <Title> {text} </Title>
        <Hr />
        <ChartLineMonthly />
      </div>
    </Container>
  )
}

export default ConsolidaMensalChart
