import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  margin-top: 15px;
  background-color: ${({theme}) => theme.colors.background.white};
  border-top: 1px solid ${({theme}) => theme.colors.border.input.light};
  padding: 10px;
  gap: 15px;
`

export const Actions = styled.div`
  display: flex;
  gap: 5px;
`

export const Display = styled.div`
  display: flex;
  flex: 1;
  align-content: space-between;
`

export const DisplayItems = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`

export const DisplayPage = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  flex: 1;
`

export const Options = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`

export const Select = styled.select`
  background-color: transparent;
  border: none;
  font-size: 16px;
  width: 5ch;
`

export const Input = styled.input`
  font-size: 16px;
  width: 2ch;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`

export const Button = styled.button`
  background-color: transparent;
  height: 36px;
  width: 36px;
  border-radius: 18px;
  padding: 6px;
  &:hover:not([disabled]) {
    background-color: ${({theme}) => theme.colors.font.purpleThird};
  }
  & svg {
    fill: ${({theme}) => theme.colors.font.purpleMain};
    height: 24px;
    width: 24px;
  }
  &:disabled {
    & svg {
      fill: ${({theme}) => theme.colors.font.disabled};
    }
  }
`
