import React, {FC, ReactElement, useEffect, useState} from 'react'
import ScreenHeader from '~/components/ScreenHeader'
import {ComponentPanel} from '~/components/Styled'
import PageContainer from '~/components/PageContainer'
import {ContentTab} from '~/components/ContentTab'
import {useLocation} from 'react-router-dom'
import {SearchCustomer} from '~/pages/CustomerAnalysis/SearchCustomer'
import {ListCustomer} from '~/pages/CustomerAnalysis/ListCustomer'

export const CustomerAnalysis: FC = (): ReactElement => {
  const location = useLocation<{tab?: string}>()
  const [currentTab, setCurrentTab] = useState('list')
  useEffect(() => {
    if (location?.state?.tab !== undefined) {
      setCurrentTab(location.state.tab)
    }
  }, [location?.state?.tab])
  return (
    <PageContainer>
      <ScreenHeader
        style={{
          marginBottom: 0,
        }}
        title="Análise de cliente"
        tabs={[
          {
            title: 'Busca por cliente',
          },
          {
            title: 'Buscar lista de clientes',
          },
        ]}
        info={
          <p>
            Encontre relatórios detalhados de transações de usuários de acordo
            com saldo de pontos, nível, créditos e indicações, Busque por&nbsp;
            <b>perfis de clientes únicos</b> ou por <b>busca personalizada</b>
          </p>
        }></ScreenHeader>
      <ContentTab
        onChange={(currentTab) => setCurrentTab(currentTab)}
        currentValue={currentTab}
        tabs={[
          {title: 'Busca por Cliente', value: 'search'},
          {title: 'Buscar lista de clientes', value: 'list'},
        ]}
      />
      <ComponentPanel.Content>
        {currentTab === 'search' && <SearchCustomer />}
        {currentTab === 'list' && <ListCustomer />}
      </ComponentPanel.Content>
    </PageContainer>
  )
}
