import {ReactElement, ReactNode} from 'react'
import {useAppSelector} from '~/app/hooks'
import whirlpoolLogo from '~/assets/images/logoWhirlpool.svg'

import {Container, Content, Footer} from './styles'

interface DrawerProps {
  backgroundColor: string
  children: ReactNode
}

function Drawer({backgroundColor, children}: DrawerProps): ReactElement {
  const drawerOpened = useAppSelector((state) => state.drawer.opened)

  return (
    <Container backgroundColor={backgroundColor} drawerOpened={drawerOpened}>
      <Content>{children}</Content>
      <Footer>
        <img
          src={whirlpoolLogo}
          width="auto"
          height={30}
          alt="Whirlpool Logo"
        />
      </Footer>
    </Container>
  )
}

export default Drawer
