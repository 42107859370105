import React, {FC, ReactElement, useRef, useState} from 'react'
import {Container, Header, IconContainer, MessageContainer} from './style'
import {useQuery} from 'react-query'
import {getCashback} from '~/services/apiManagerService'
import {CashbackFilterBar} from '~/pages/CustomerProfile/CashbackTab/CashbackFilterBar'
import {dateTimeToFormat} from '~/utils'
import {MdAddShoppingCart} from 'react-icons/md'
import {
  Paginator,
  Table,
  TableCol,
  TableHeader,
  TableHeaderCol,
  TableRow,
} from '~/components/NewTable'
import {CSVGenerator, CSVRef} from '~/components/CSVGenerator'
import {Cashback} from '~/types/ApiManagerTypes'

type CashbackTabParams = {
  email: string
}

export const CashbackTab: FC<CashbackTabParams> = ({email}): ReactElement => {
  const csvRef = useRef<CSVRef>()
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(15)
  const [filters, setFilters] = useState({})
  const {data, isLoading, isPreviousData} = useQuery(
    [
      'customerAnalysisCashback',
      email,
      {
        page,
        perPage,
        ...filters,
      },
    ],
    async () => getCashback(email, page, perPage, filters),
    {
      keepPreviousData: true,
    }
  )
  const genValueString = (value: number, type: string): string => {
    const signal = type === 'credit' ? '+ ' : '- '
    return (
      signal +
      Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(value)
    )
  }
  return (
    <Container>
      <Header>
        <CashbackFilterBar
          onClickCsvButton={() => {
            if (csvRef.current) {
              csvRef.current.generateCSV()
            }
          }}
          onChange={(filter) => {
            setFilters(filter)
          }}
          count={data?.meta?.pagination?.count ?? 0}
        />
        <CSVGenerator
          csvRef={csvRef}
          request={(csvPage) => getCashback(email, csvPage, 1000, filters)}
          csvName={`${email}_referrals`}
          map={(cashback: Cashback) => {
            return {
              data: dateTimeToFormat(cashback.createdAt, {}),
              pedido: cashback.orderId,
              descricao: cashback.description,
              creditos: genValueString(cashback.value, cashback.movementType),
            }
          }}
        />
      </Header>
      {isLoading ? (
        <MessageContainer>Carregando dados de indicações</MessageContainer>
      ) : data?.data?.length === 0 ? (
        <MessageContainer>Sem histórico de movimentações.</MessageContainer>
      ) : (
        data?.data && (
          <>
            <Table isLoading={isPreviousData}>
              <TableHeader>
                <IconContainer></IconContainer>
                <TableHeaderCol flex={0.3}>data</TableHeaderCol>
                <TableHeaderCol flex={0.3}>pedido</TableHeaderCol>
                <TableHeaderCol>descrição</TableHeaderCol>
                <TableHeaderCol>créditos</TableHeaderCol>
              </TableHeader>
              {data?.data.map((row) => (
                <TableRow key={row.id}>
                  <IconContainer>
                    <MdAddShoppingCart />
                  </IconContainer>
                  <TableCol flex={0.3}>
                    {dateTimeToFormat(row.createdAt, {})}
                  </TableCol>
                  <TableCol flex={0.3}>
                    {row.orderId === '' ? '-' : row.orderId}
                  </TableCol>
                  <TableCol>{row.description}</TableCol>
                  <TableCol>
                    {genValueString(row.value, row.movementType)}
                  </TableCol>
                </TableRow>
              ))}
            </Table>
          </>
        )
      )}
      {data?.meta?.pagination?.count && (
        <Paginator
          isLoading={isPreviousData}
          currentPage={page}
          perPage={perPage}
          totalItems={data?.meta?.pagination?.count}
          onChange={(page, perPage) => {
            setPage(page)
            setPerPage(perPage)
          }}
          perPageOptions={[15, 30, 45, 60]}
        />
      )}
    </Container>
  )
}
