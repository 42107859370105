import styled from 'styled-components'

interface ContainerProps {
  backgroundColor?: string
}

interface TitleContainerProps {
  align: string
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 56px;
  padding: 24px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({backgroundColor}) => backgroundColor};
  box-shadow: 0px 1px 8px rgb(0 0 0 / 15%);
`

export const TitleContainer = styled.div<TitleContainerProps>`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: ${({align}) => align};
  column-gap: 6px;
`
