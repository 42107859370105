import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {ReactElement} from 'react'

export function ConfiguredToastContainer(): ReactElement {
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      closeOnClick={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  )
}
