import styled from 'styled-components'

export const Container = styled.div``

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15%;
  margin-top: 20px;
  align-items: flex-start;
`
export const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 36px;
  width: 60%;
`

export const FieldLabel = styled.h3`
  white-space: nowrap;
  width: 120px;
  min-width: 120px;
  font-size: 16px;
  margin-right: 24px;
  color: ${({theme}) => theme.colors.font.main};
  font-weight: lighter;
  text-align: end;
`
