import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  height: 12px;
  background-color: ${({theme}) => theme.colors.background.darkGray};
  border-radius: 6px;
`

export const Bar = styled.div<{width: number}>`
  height: 100%;
  width: ${({width}) => width}%;
  background-color: ${({theme}) => theme.colors.secondary.main};
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  transition: all 700ms ease-out;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      #ffffff00 0%,
      #ffffff20 30%,
      #ffffff40 50%,
      #ffffff20 70%,
      #ffffff00 100%
    );
    animation: shimmer 5s infinite;
    content: '';
  }
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
`
