import {configureStore} from '@reduxjs/toolkit'

import drawerReducer from '~/features/drawer/drawer-slice'

import filtersReducer from '~/features/filters/filters-slice'

export const store = configureStore({
  reducer: {
    drawer: drawerReducer,
    filters: filtersReducer,
  },
})

export type AppDispatch = typeof store.dispatch

export type RootState = ReturnType<typeof store.getState>
