import styled from 'styled-components'

export const TableCol = styled.div<{flex?: number}>`
  display: flex;
  align-items: center;
  align-content: center;
  flex: ${({flex}) => flex ?? 1};
  padding: 20px;
  font-weight: 400;
  font-size: 13px;
  color: ${({theme}) => theme.colors.font.dark};
`
