import styled from 'styled-components'

export const Content = styled.article`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 3vh 3vw;
  background-color: ${({theme}) => theme.colors.background.white};
`

export const Loader = styled.div`
  position: absolute;
  top: 50%;
  transform: translateX(-50%);
  left: 53%;
`

export const WrapRules = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 3fr 2fr 2fr 0.9fr 0.9fr;
  width: 100%;
  padding: 14px 27px;
  margin-top: 3vh;
  span {
    text-align: left;
    font-size: 12px;
    font-weight: 300;
    text-transform: uppercase;
    color: ${({theme}) => theme.colors.background.gray};
  }
`
