import {useState, useEffect, ReactElement} from 'react'
import {Redirect} from 'react-router-dom'
import ContentHome from './ContentHome'
import {Container, WrapContainer, Content} from './styles'
import {useFinancialProvider} from '~/hooks/useFinancialProvider'
import HeaderMovement from '~/components/Movement/Header'
import {useMovement} from './useMovement'
import {useMovementProvider} from '~/hooks/useMovementProvider'

export default function Movement(): ReactElement {
  const {yearMonth} = useMovementProvider()
  const {updateTabMonthClicked} = useFinancialProvider()
  const {quantity} = useMovement()
  const [showAllMovement, setShowAllMovement] = useState(false)

  useEffect(() => {
    updateTabMonthClicked(false)
  }, [yearMonth])

  const handleShowAllMovement = () => setShowAllMovement(true)

  return (
    <Container>
      <WrapContainer>
        <HeaderMovement totalMovements={quantity} />
        <Content>
          <ContentHome onClick={handleShowAllMovement} />
        </Content>
      </WrapContainer>
      {showAllMovement && (
        <Redirect
          to={{
            pathname: '/financeiro/detalhes',
          }}
        />
      )}
    </Container>
  )
}
