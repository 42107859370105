import {ReactElement, useState, useRef, FormEvent, useEffect} from 'react'
import {lastDayOfMonth, getDate} from 'date-fns'
import {
  Content,
  TitleContent,
  WrapInputMeta,
  TitleMeta,
  Input,
  Information,
  AboutMonth,
} from './styles'
import {useGoalIndication} from '~/components/ModalGoalIndication/useGoalIndication'
import ModalCommon from '~/components/ModalCommon'
import ButtonModalFooterCommon from '../ButtonModalFooterCommon'
import {useTheme} from 'styled-components'

interface ModalIndicationProps {
  opened: boolean
  closeModal: () => void
  openModal: (status: boolean) => void
}

export default function ModalMetaIndication({
  opened,
  closeModal,
  openModal,
}: ModalIndicationProps): ReactElement {
  const {colors} = useTheme()
  const {isLoading, handleGoal, goalCurrent} = useGoalIndication()
  const inputRef = useRef<HTMLInputElement>()
  const [isFocus, setIsFocus] = useState(false)
  const [goalInput, setGoalInput] = useState('')
  const [averageMonth, setAverageMonth] = useState(0)
  const dayTotal = getDate(lastDayOfMonth(new Date()))

  const handleFocus = () => setIsFocus(true)

  const handleBlur = () => setIsFocus(false)

  const handleSubmit = () => {
    handleGoal(Number(goalInput))
    openModal(false)
  }

  useEffect(() => {
    if (!isLoading) {
      const average = goalCurrent.currentGoal / dayTotal
      setAverageMonth(Math.round(average))
      setGoalInput(`${goalCurrent.currentGoal}`)
    }
  }, [isLoading, goalCurrent])

  const handleInputSubmit = (e: FormEvent<HTMLInputElement>) => {
    const letter = (e.target as HTMLInputElement).value
    setGoalInput(`${Number(Math.round(+letter))}`)
    const average = +inputRef.current?.value / dayTotal
    setAverageMonth(Math.round(average))
  }

  const handleCancel = () => openModal(false)

  return (
    <ModalCommon
      title="CONFIGURAÇÕES"
      opened={opened}
      closeModal={closeModal}
      childrenFooter={
        <>
          <ButtonModalFooterCommon onClick={handleSubmit} text="Aplicar" />
          <ButtonModalFooterCommon
            newBackgroundColor={colors.background.white}
            newColor={colors.font.purpleMain}
            newOpacity={0.6}
            newBackgroundColorHover={colors.background.white}
            newOpacityHover={1}
            text="Cancelar"
            onClick={handleCancel}
          />
        </>
      }
      childrenContent={
        <Content>
          <TitleContent>Meta de indicações</TitleContent>
          <WrapInputMeta>
            <TitleMeta>Meta mensal</TitleMeta>
            <Input
              haveFocus={isFocus}
              onBlur={handleBlur}
              onFocus={handleFocus}
              ref={inputRef}
              type="number"
              value={goalInput}
              onChange={(e) => handleInputSubmit(e)}
              autoFocus
            />
            <Information>
              {averageMonth} indicações por dia no mês atual
            </Information>
          </WrapInputMeta>
          <AboutMonth>
            <Information>
              Uma meta ajustada no mês corrente será aplicada retroativamente
              nos dias passados desse mês{' '}
            </Information>
          </AboutMonth>
        </Content>
      }
    />
  )
}
