import {FC, useEffect, useRef, useState} from 'react'
import {Container, InputCheckBox} from '~/components/CheckBox/style'
import {FaCheck} from 'react-icons/fa'

interface CheckBoxParams {
  value?: boolean
  onChange?: (value: boolean) => void
  name?: string
  id?: string
}
export const CheckBox: FC<CheckBoxParams> = ({
  value = false,
  onChange,
  id,
  name,
}) => {
  const didMount = useRef(false)
  const [checked, setChecked] = useState(false)
  useEffect(() => {
    if (didMount) {
      onChange?.(checked)
    }
  }, [checked])

  useEffect(() => {
    if (value !== undefined) {
      setChecked(value)
    }
  }, [value])
  return (
    <Container
      checked={checked}
      onClick={() => setChecked((checked) => !checked)}>
      <InputCheckBox
        {...{id, name, checked}}
        type="checkbox"
        onChange={(e) => setChecked(e.target.checked)}
      />
      <FaCheck />
    </Container>
  )
}
