import styled from 'styled-components'

export const TableRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 45px;
  background-color: ${({theme}) => theme.colors.background.white};
  border-bottom: 1px solid ${({theme}) => theme.colors.border.input.light};
  &:hover {
    background-color: ${({theme}) => theme.colors.background.lightPurple};
  }
`
