import {ReactElement, useState} from 'react'
import {
  PopoverContent,
  PopoverItem,
  PopoverTrigger,
  TruncatedText,
} from './styles'

export function StringListTruncated({
  items,
  limit,
}: {
  items: string[]
  limit: number
}): ReactElement {
  const [showPopover, setShowPopover] = useState(false)
  const [popoverPosition, setPopoverPosition] = useState({x: 0, y: 0})

  const truncatedItems = items.slice(0, limit)
  const remainingItems = items.length - limit

  const popoverContent = (
    <PopoverContent
      style={{
        left: `${popoverPosition.x}px`,
        top: `${popoverPosition.y}px`,
      }}>
      {items.map((item, index) => (
        <PopoverItem key={index}>{item}</PopoverItem>
      ))}
    </PopoverContent>
  )

  const handlePopoverEnter = (event) => {
    const {offsetLeft, offsetTop, offsetHeight, offsetWidth} = event.target
    setPopoverPosition({
      x: offsetLeft + offsetWidth,
      y: offsetTop + offsetHeight,
    })
    setShowPopover(true)
  }

  const handlePopoverLeave = () => {
    setShowPopover(false)
  }

  return (
    <>
      <TruncatedText>{truncatedItems.join(', ')}</TruncatedText>
      {remainingItems > 0 && (
        <>
          ...&nbsp;
          <PopoverTrigger
            onMouseEnter={handlePopoverEnter}
            onMouseLeave={handlePopoverLeave}>
            +{remainingItems}
          </PopoverTrigger>
        </>
      )}
      {showPopover && popoverContent}
    </>
  )
}
