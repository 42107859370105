import styled, {css} from 'styled-components'

interface ChevronButtonProps {
  disabled: boolean
  hoverColor: string
}

interface PageContainerProps {
  pageWidth: string
  visiblePages: number
}

interface NumberContainerProps {
  pageWidth: string
  hoverColor: string
  selectedPageColor: string
  isSelectedPage: boolean
}

export const Container = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ChevronButton = styled.div<ChevronButtonProps>`
  svg {
    &:hover {
      fill: ${({hoverColor}) => hoverColor};
    }
  }

  ${({disabled}) =>
    disabled &&
    css`
      filter: opacity(0.5);
      cursor: default;

      svg {
        &:hover {
          fill: #6f7275;
        }
      }
    `}
`

export const PageContainer = styled.div<PageContainerProps>`
  max-width: ${({pageWidth, visiblePages}) =>
    `calc(${pageWidth} * ${visiblePages})`};
  display: flex;
  overflow: hidden;
`

export const PageList = styled.div`
  width: 100%;
  display: flex;
  transition: 0.2s ease-in;
`

export const NumberContainer = styled.div<NumberContainerProps>`
  min-width: ${({pageWidth}) => pageWidth};
  min-height: ${({pageWidth}) => pageWidth};
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 0.875rem;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      color: ${({hoverColor}) => hoverColor};
    }
  }

  ${({selectedPageColor, isSelectedPage}) =>
    isSelectedPage &&
    css`
      background: ${selectedPageColor};
      border-radius: 100%;

      span {
        color: #fff;

        &:hover {
          color: #fff;
        }
      }
    `}
`
