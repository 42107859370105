import styled from 'styled-components'

type ButtonProps = {
  customBackgroundColor: string
  customColor: string
  customBackgroundColorHover: string
  customOpacity: number
  customOpacityHover: number
}

export const Button = styled.button<Partial<ButtonProps>>`
  border-radius: 8px;
  opacity: ${({customOpacity}) => customOpacity ?? 1};
  background-color: ${({theme, customBackgroundColor}) =>
    customBackgroundColor ?? theme.colors.font.purpleDark};
  padding: 8px 16px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  color: ${({theme, customColor}) =>
    customColor ?? theme.colors.background.white};
  transition: all ease-in-out 0.3s;
  font-size: 14px;
  font-weight: 500;
  &:hover {
    background-color: ${({theme, customBackgroundColorHover}) =>
      customBackgroundColorHover ?? theme.colors.font.purpleMain};
    opacity: ${({customOpacityHover}) => customOpacityHover ?? 1};
  }
  &:disabled {
    background-color: ${({theme}) => theme.colors.font.placeHolder};
    color: ${({theme}) => theme.colors.border.input.light};
  }
`
